<template>
    <div>
        <b-modal ref="modal-add" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('file-upload',3)">
            <ValidationObserver ref="form" >

                <app-row-left-label :label="trans('id-type',3)" v-if="args.id_document_const_type == 21">
                    <app-radio-group v-model="document_type" :options="doc_type" validatorName="Identificatiebewijs type" validatorRules="required" :validatorCustomMessage="{ required: trans('id-type-required',180) }" />
                </app-row-left-label>

                <b-form @submit.prevent> 
                    <b-row class="mb-4" v-if="documents.length > 0">
                        <b-col>
                            <b-row v-for="document in documents" :key="document.file_path">
                                <b-col md="9" xl="9">
                                    <span class="app-documents-item"><div class="w-100 text-truncate">{{ document.file_name }}</div></span>
                                    <span class="app-documents-item" v-if="document.id_document_const_type == 5 || document.id_document_const_type == 6"> | {{setFormatDate(document.date_to)}}</span>
                                    <span class="app-documents-item" v-else-if="document.id_document_const_type == 4 || document.id_document_const_type == 9"> | {{setFormatDate(document.date_from)}}</span>
                                </b-col>
                                <b-col md="3" xl="3" class="text-right">
                                    <span class="app-documents-item font-weight-bolder"> {{ document.file_size_mb }} MB</span>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row v-if="args.id_document_const_type != 21">
                        <b-col>
                            <div class="image-upload-wrap" v-if="(documents.length < 1 || args.id_document_const_type == 3 || args.id_document_const_type == 10)">
                                <input class="file-upload-input" type="file" v-on:change="addFile" v-if="args.id_document_const_type == 7" accept=".docx, .doc, application/pdf"/>
                                <input class="file-upload-input" type="file" v-on:change="addFile" v-else-if="args.id_document_const_type == 49" accept="image/jpeg, image/jpg, application/pdf, application/msword, .docx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"/>
                                <input class="file-upload-input" type="file" v-on:change="addFile" v-else accept="image/png, image/jpeg, image/jpg, application/pdf"/>
                                <div class="drag-text">
                                    <img src="@/assets/images/icons/download-cloud.svg" height="40px" v-if="!f_uploading" />
                                    <b-spinner v-if="f_uploading" class="spinner-margin" />
                                    <div>
                                        <small class="text-muted" v-if="args.id_document_const_type == 7">{{trans('drag-and-drop-files-doc-pdf',8)}}</small>
                                        <small class="text-muted" v-else-if="args.id_document_const_type == 49">{{trans('drag-and-drop-doc-docx-xls-jpg-pdf',180)}}</small>
                                        <small class="text-muted" v-else>{{trans('drag-and-drop-files-jpg-png-pdf',180)}}</small>
                                    </div>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row v-if="args.id_document_const_type == 21 && file_front == null" class="mb-2">
                        <b-col>
                            <div class="image-upload-wrap" v-if="(documents.length < 2 || args.id_document_const_type == 3 || args.id_document_const_type == 10)">
                                <input class="file-upload-input" type="file" v-on:change="addFile($event, 1)" accept="image/png, image/jpeg, image/jpg, application/pdf"/>
                                <div class="drag-text">
                                    <img src="@/assets/images/icons/download-cloud.svg" height="40px" v-if="!f_uploading_front" />
                                    <b-spinner v-if="f_uploading_front" class="spinner-margin" />
                                    <div>
                                        <small class="text-muted"> {{trans('drag-or-select',8)}} </small>
                                     <small class="font-weight-bolder"> {{trans('id-front',8)}} </small>
                                     <small class="text-muted"> {{trans('id-front-info',179)}}</small>
                                    </div>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row v-if="args.id_document_const_type == 21 && file_back == null">
                        <b-col>
                            <div class="image-upload-wrap" v-if="(documents.length < 2 || args.id_document_const_type == 3 || args.id_document_const_type == 10)">
                                <input class="file-upload-input" type="file" v-on:change="addFile($event, 2)" accept="image/png, image/jpeg, image/jpg, application/pdf"/>
                                <div class="drag-text">
                                    <img src="@/assets/images/icons/download-cloud.svg" height="40px" v-if="!f_uploading_back" />
                                    <b-spinner v-if="f_uploading_back" class="spinner-margin" />
                                    <div>
                                        <small class="text-muted"> {{trans('drag-or-select',8)}} </small>
                                        <small class="font-weight-bolder"> {{trans('id-back',8)}} </small>
                                        <small class="text-muted"> {{trans('id-front-info',179)}}</small>
                                    </div>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </b-form>
            </ValidationObserver>
            <template #modal-footer>
                <app-button type="cancel" @click="cancelFiles"><b-spinner class="mr-1" small v-if="f_cancel === 1" />{{trans('cancel',175)}}</app-button>
                <app-button type="primary" :disabled="(documents.length === 0 && args.id_document_const_type != 21) || (documents.length != 2 && args.id_document_const_type == 21)" @click="confirmFiles" :loading="loading">
                    {{trans('add',175)}}
                </app-button>
            </template>
        </b-modal>        

        <b-modal ref="datepicker-modal" centered okVariant="primary" okTitle="OK" :title="datepicker_modal_title" size="sm" ok-only @ok="handleDatepickerOk">
            <b-row>
                <b-col>
                    <datepicker v-model="selected_date" :inline="true" :language="languages[language]" :disabled-dates="disabled_dates"></datepicker>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
import axios from "@axios";
import * as lang from "vuejs-datepicker/src/locale";
import { ValidationObserver } from "vee-validate";
import { date } from "@validations";
import moment from 'moment'
import Datepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js";

export default {
    components: {
        ValidationObserver,
        Datepicker
    },

    props: ["show", "result", "args", "return"],

    computed: {
        computedShow: {
        get() { return this.show },
        set() { this.$emit('update:show', false) }, 
        },
        
        cReturn: {
        get() { return this.return },
        set() { this.$emit('update:return', false) }
        }
    },

    data() {
        return {
            loading: false,
            f_cancel: 0,
            f_uploading: false,
            f_uploading_front: false,
            f_uploading_back: false,
            file_front:null,
            file_back:null,
            form_data: new FormData(),
            document_type:null,
            documents: [],
            date_from: "",
            date_to: "",
            datepicker_modal_title: "",
            selected_date: "",
            language: "nl",
            languages: lang,
            disabled_dates: {
                to: moment()
                    .subtract(1, "days")
                    .toDate(),
            },
            doc_type: [
                { value: 1, text: this.trans('passport',8) },
                { value: 2, text: this.trans('id-card',8) },
                { value: 3, text: this.trans('residence-document',8) },
            ]
        };
    },

    methods: {
        openDatepickerModal(){
            this.$refs["datepicker-modal"].show();
        },
        handleDatepickerOk(){
            
            if(this.args.id_document_const_type == 5 || this.args.id_document_const_type == 6){                
                if(moment(this.selected_date).format("yyyy-MM-DD") == "Invalid date"){
                    this.form_data.append("date_to", moment().format("yyyy-MM-DD"));
                }else{
                    this.form_data.append("date_to", moment(this.selected_date).format("yyyy-MM-DD"));
                }
            }
            else if(this.args.id_document_const_type == 4 || this.args.id_document_const_type == 9){
                if(moment(this.selected_date).format("yyyy-MM-DD") == "Invalid date"){
                    this.form_data.append("date_from", moment().format("yyyy-MM-DD"));
                }else{
                this.form_data.append("date_from", moment(this.selected_date).format("yyyy-MM-DD"));
                }
            }

            this.sendFile();
        },
        addFile(e, id_type) {

            var file = e.target.files[0];
            
            if(this.args.id_document_const_type == 7){
                  if(!file.type.match('application/msword.*') && !file.type.match('application/vnd.openxmlformats-officedocument.wordprocessingml.document.*') && !file.type.match('application/pdf.*')) {
                     alert(this.trans('only-doc-pdf',177));
                     file = "";
                     return;
                 }
            }else if(this.args.id_document_const_type == 49){
                if(!file.type.match('image/jp.*') && !file.type.match('application/msword.*') && !file.type.match('application/pdf.*') && !file.type.match('application/vnd.openxmlformats-officedocument.wordprocessingml.document') && !file.type.match('application/vnd.openxmlformats-officedocument.spreadsheetml.*')){
                    alert(this.trans('only-doc-pdf-xls-jpg',180));
                    file = "";
                    return;
                }
            }else{
                if(!file.type.match('image/jp.*') && !file.type.match('image/png.*') && !file.type.match('application/pdf.*')) {
                    alert(this.trans('only-jpg-png',180));
                    file = "";
                    return;
                }
            }
            
            this.form_data.append("id_person_data_main", this.args.id_person_data_main);
            this.form_data.append("file", file);
            this.form_data.append("file_name", file.name);
            if(this.args.id_document_const_type == 21){
                this.form_data.append("id_document_const_type", id_type);
            }
            else{
                this.form_data.append("id_document_const_type", this.args.id_document_const_type);
            }
            this.form_data.append("file_size", file.size);
            this.form_data.append("file_size_mb", (file.size / 1024 / 1024).toFixed(2));
            
            if(this.args.id_document_const_type == 5 || this.args.id_document_const_type == 6){
                this.disabled_dates = {to: moment().subtract(1, "days").toDate()}
                this.datepicker_modal_title = this.trans('set-expiration-date',8);
                this.openDatepickerModal();
            }else if(this.args.id_document_const_type == 4){
                this.disabled_dates = {from: moment().subtract(0, "days").toDate()}
                this.datepicker_modal_title = this.trans('set-creation-date',8);
                this.openDatepickerModal();
            }else if(this.args.id_document_const_type == 9){
                this.disabled_dates = {to: moment().subtract(90, "days").toDate(), from: moment().subtract(0, "days").toDate()}
                this.datepicker_modal_title = this.trans('set-creation-date',8);
                this.openDatepickerModal();
            }
            else{
                this.sendFile(id_type);
            }

        },
        sendFile(id_type) {
            const config = {
                headers: { "content-type": "multipart/form-data" },
            };
            if(id_type == 1){
                this.f_uploading_front = true;
            }else{
                this.f_uploading_back = true;
            }
            this.f_uploading = true;
            axios
                .post("core/addTempFile", this.form_data, config)                
                .then((response) => {
                    this.form_data.append("temp_path", response.data);

                    var object = {};
                    this.form_data.forEach((value, key) => object[key] = value);                    
                    this.documents.push(object);
                    
                    this.form_data = new FormData();
                    this.f_uploading = false;
                    if(id_type == 1){
                        this.f_uploading_front = false;
                        this.file_front = 1
                    }else{
                        this.f_uploading_back = false;
                        this.file_back = 1
                    }
                })
                .catch(function(error) {
                    this.f_uploading = false;
                    if(id_type == 1){
                        this.f_uploading_front = false;
                        this.file_front = 1
                    }else{
                        this.f_uploading_back = false;
                        this.file_back = 1
                    }

                    console.log(error);
                });
        },
        confirmFiles() {
            this.$refs.form.validate().then((result) => {
                if (!result) {            
                    return false;
                }
                this.loading = true;
                axios
                    .post("people/profile/documents/add",  {
                                documents: JSON.stringify(this.documents),
                                id_person_data_main: this.args.id_person_data_main,
                                return_type: this.args.return_type,
                                filters: JSON.stringify(this.args.filters),
                                document_type: this.document_type,
                            })
                    .then((response) => {

                        if (this.args.return_type === "documents") {
                            this.cReturn.documents.items = response.data.items;
                            this.cReturn.compliance_check.refresh ++;
                        } else if(this.args.return_type === "compliance_check") {
                            this.cReturn.compliance_check.items = response.data.items;
                            this.cReturn.compliance_check.correct = response.data.correct;
                            this.cReturn.compliance_check.show = response.data.show;
                            this.cReturn.documents.refresh ++;
                        }

                        this.loading = false;
                        this.closeModal();
                    })
                    .catch(function(error) {
                        this.loading = false;

                        console.log(error);
                    });
            })
        },
        cancelFiles() {            
            if(this.documents.length > 0) {
                this.f_cancel = 1;
                axios
                .post("people/profile/documents/removeTemp",  {
                            documents: JSON.stringify(this.documents),
                        })
                .then((response) => {
                    this.f_cancel = 0;
                    this.closeModal();
                })
                .catch(function(error) {
                    this.f_cancel = 0;
                    console.log(error);
                });
            }
            else {
                this.closeModal();
            }
        },        
        closeModal() {
            this.documents = [];
            this.$emit("update:show", false);
        },

        setFormatDate(dateValue) {
           return moment(dateValue).format('DD-MM-YYYY');
        },
    },
};
</script>

<style scoped>
.image-upload-wrap {
    border: 1px dashed #BBE7FA;
    border-radius: 5px;
    position: relative;
    transition: 1s;
}

.image-dropping,
.image-upload-wrap:hover {
    background-color: #f8fcfe;
}

.drag-text {
    text-align: center;
    padding: 20px 10px;
}

.drag-text p {
    color: #000000;
    font-size: 12px;
}

.file-upload-input {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;
}
.spinner-margin{
    margin-top: 4px; 
    margin-bottom:6px
}
</style>
