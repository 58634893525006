<template>
    <b-modal ref="modal-change" size="xl" v-model="computedShow" no-close-on-backdrop :title="trans('fp-change-plan-pref',172)">
        <b-row>
            <b-col cols="6">
                <ValidationObserver ref="form" >
                    <app-row-left-label :label="trans('hours-per-week',184)">
                        <app-input v-model="cArgs.plan_preferences.hour_per_week" validatorName="Uren per week" validatorRules="integer|min_value:0|max_value:50" :validatorCustomMessage="{ 'min_value': trans('hours-per-week-error',177), 'max_value':trans('hours-per-week-error',177) }" type="integer_02" />
                    </app-row-left-label>

                    <app-row-left-label :label="trans('day-part',172)" :slots="[4,4,4]" :isMarginBetween="false">
                        <template v-slot:col-1>
                            <app-check-box v-model="day" :rightLabel="trans('day-part-day',182)" @change="workingTimesChange($event, 1)"/>
                        </template>
                        <template v-slot:col-2>
                            <app-check-box v-model="evening" :rightLabel="trans('day-part-evening',182)" @change="workingTimesChange($event, 2)"/>
                        </template>
                        <template v-slot:col-3>
                            <app-check-box v-model="night" :rightLabel="trans('day-part-night',182)" @change="workingTimesChange($event, 3)"/>
                        </template>
                    </app-row-left-label>

                    <app-row-left-label>
                        <!-- <app-input v-model="cArgs.plan_preferences.sector" :maxLength="40" mask="lettersE6-40"/> -->
                        <app-multi-select v-model="cArgs.plan_preferences.ids_sector_const_type" :label="trans('fp-sector',172)" type="getSectors"  :addButtonLabel="trans('add-sector',174)" :key="refresh"/>
                    </app-row-left-label>

                    <!-- <app-row-left-label :label="trans('radius',184)" class="mb-4">
                        <vue-slider ref="slider" class="px-1" v-model="cArgs.plan_preferences.working_area.radius" :marks="marks" v-bind="options" :tooltipStyle="tooltip_style"></vue-slider>
                    </app-row-left-label> -->

                    <app-row-left-label :label="trans('radius',184)" class="mb-4">
                        <!-- <vue-slider ref="slider" class="px-1" v-model="cArgs.plan_preferences.working_area.radius" :marks="marks" v-bind="options" :tooltipStyle="tooltip_style"></vue-slider> -->
                        <app-slider v-model="cArgs.plan_preferences.working_area.radius" min="10" max="150" step="10" markLabel showValue/>
                    </app-row-left-label>

                    <app-row-left-label :label="trans('period-assignment',184)">
                        <app-radio-group v-model="cArgs.plan_preferences.assigments_allow" :options="period_options" />
                    </app-row-left-label>
                </ValidationObserver>
            </b-col>
            <b-col cols="6">
                <b-card class="app-local-padding" style="height: 100%">
                    <GmapMap
                        :center="center"
                        :zoom="cZoom"
                        map-type-id="terrain"
                        style="height: 100%"
                        :options="{
                        zoomControl: true,
                        mapTypeControl: false,
                        scaleControl: false,
                        streetViewControl: false,
                        rotateControl: true,
                        fullscreenControl: false,
                        disableDefaultUI: true,
                        styles:styles
                        }"
                        @click="addMarker"
                    >
                        <GmapMarker
                            v-if="marker != null"
                            :position="marker"
                            :clickable="false"
                            :draggable="true"
                            :icon="{url: require('assets/images/icons/marker.png'), anchor: point}"
                            @dragend="updateMarker"
                            @dragstart="moveMarker"
                        />

                        <GmapCircle
                            v-if="cArgs.plan_preferences.working_area.radius && marker != null && is_radius"
                            :center="marker"
                            :radius="cArgs.plan_preferences.working_area.radius*1000"
                            :visible="true"
                            :options="{
                                fillColor:'#51b5e0',
                                strokeColor: '#174066',
                                strokeOpacity: 0.8,
                                strokeWeight: 0,
                                fillOpacity: 0.5,
                                clickable: false,
                                }"
                            :clickable="false"
                        ></GmapCircle>
                    </GmapMap>
                </b-card>
                <small v-if="defoult_address" class="app-input-error-ellips text-error">
                    {{trans('empty-location',184)}}
                </small>
                <div class="d-flex justify-content-end pt-1"><app-button type="cancel" class="app-local-text p-0" v-if="!is_address" @click="setMarkerToAddress">{{trans('fl-set-home-address', 174)}}</app-button><div v-else style="font-size: 0.85rem">&nbsp;</div></div>
            </b-col>

        </b-row>

        <template #modal-footer>

                <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
                <app-button type="primary" @click="change" :loading="loading">
                    {{trans('save',175)}}
                </app-button>
        </template>
    </b-modal>
</template>
<script>
import axios from "@axios";
import { ValidationObserver } from "vee-validate";
import AppMultiSelect from '@core/components/AppMultiSelect.vue';
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

export default {

    components: {
        ValidationObserver,
        AppMultiSelect,
        
    },

    created(){
        this.refresh++;
        this.setDayTypes();
        this.setMap();
        this.$gmapApiPromiseLazy().then(() => {
            this.point = new window.google.maps.Point(10,10);
        });
    },

    props: ["show", "args", "return", "result"],

    computed: {
        computedShow: {
        get() { return this.show },
        set() { this.$emit('update:show', false) }, 
        },

        cArgs: {
            get() { return this.args },
            set() { this.$emit('update:args', false) }
        },

        cReturn: {
            get() { return this.return },
            set() { this.$emit('update:return', false) }
        },
        cZoom: {
            get() { return (0 - this.cArgs.plan_preferences.working_area.radius) * (6.2 - 9.5) / (0 - 150) + 9; }
        }
    },

    data(){
        return{
            loading: false,
            availabilty_options: [
                { value: 0, text: this.trans('day-part-day',182) },
                { value: 1, text: this.trans('day-part-evening',182) },
                { value: 2, text: this.trans('day-part-night',182) }
            ],
            period_options: [
                { value: 0, text: this.trans('yes-option',3) },
                { value: 1, text: this.trans('no-option',3) },
            ],
            test: null,
            refresh:0,
            options: {
                dotSize: 14,
                direction: 'ltr',
                min: 0,
                max: 150,
                interval: 10,
                tooltipPlacement: 'bottom',
                tooltip: 'active',
            },
            tooltip_style: {
                color: '#174066',
                background: 'transparent',
                borderColor: 'transparent',
                transform: 'translate(-50%, -7px)',
                position: 'absolute',
                fontWeight: '500'
            },
            marks: {
                '0': 0,
                '150': 150
            },
            defoult_address: false,
            marker: null,
            is_radius: true,
            center: null,
            is_address: true,
            point: null,
            day: '0',
            evening: '0',
            night: '0',
            styles: [
                        {
                            "elementType": "geometry",
                            "stylers": [
                            {
                                "color": "#f5f5f5"
                            }
                            ]
                        },
                        {
                            "elementType": "labels.text.fill",
                            "stylers": [
                            {
                                "color": "#616161"
                            }
                            ]
                        },
                        {
                            "elementType": "labels.text.stroke",
                            "stylers": [
                            {
                                "color": "#f5f5f5"
                            }
                            ]
                        },
                        {
                            "featureType": "administrative.land_parcel",
                            "elementType": "labels.text.fill",
                            "stylers": [
                            {
                                "color": "#bdbdbd"
                            }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "geometry",
                            "stylers": [
                            {
                                "color": "#eeeeee"
                            }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "labels.text.fill",
                            "stylers": [
                            {
                                "color": "#757575"
                            }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "geometry",
                            "stylers": [
                            {
                                "color": "#e5e5e5"
                            }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "labels.text.fill",
                            "stylers": [
                            {
                                "color": "#9e9e9e"
                            }
                            ]
                        },
                        {
                            "featureType": "road",
                            "elementType": "geometry",
                            "stylers": [
                            {
                                "color": "#ffffff"
                            }
                            ]
                        },
                        {
                            "featureType": "road.arterial",
                            "elementType": "labels.text.fill",
                            "stylers": [
                            {
                                "color": "#757575"
                            }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "geometry",
                            "stylers": [
                            {
                                "color": "#dadada"
                            }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "labels.text.fill",
                            "stylers": [
                            {
                                "color": "#616161"
                            }
                            ]
                        },
                        {
                            "featureType": "road.local",
                            "elementType": "labels.text.fill",
                            "stylers": [
                            {
                                "color": "#9e9e9e"
                            }
                            ]
                        },
                        {
                            "featureType": "transit.line",
                            "elementType": "geometry",
                            "stylers": [
                            {
                                "color": "#e5e5e5"
                            }
                            ]
                        },
                        {
                            "featureType": "transit.station",
                            "elementType": "geometry",
                            "stylers": [
                            {
                                "color": "#eeeeee"
                            }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "geometry",
                            "stylers": [
                            {
                                "color": "#c9c9c9"
                            }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "labels.text.fill",
                            "stylers": [
                            {
                                "color": "#9e9e9e"
                            }
                            ]
                        }
                    ]
        }
    },

    methods: {

        change(){
            this.$refs.form.validate().then((result) => {
                if (!result) {
                    return false;
                } else {

                this.loading = true;
                
                axios
                    .post('people/profile/plan-preferences/change', {
                        id_person_data_main: this.cArgs.id_person_data_main,
                        plan_preferences: JSON.stringify(this.cArgs.plan_preferences)
                    })
                    .then((res) => {
                        this.cReturn.person_data = res.data;
                        this.$emit('update:result', 1);
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    })
                    .finally(() => {
                        this.loading = false;
                        this.computedShow = false;
                    });
                }
            });
        },

        addMarker(event){
            if(this.marker == null){
                this.marker = {lat: event.latLng.lat() , lng: event.latLng.lng()};
            }
        },

        moveMarker(){
            this.is_radius = false;
        },

        updateMarker(event){
            this.marker = {lat: event.latLng.lat() , lng: event.latLng.lng()};
            this.cArgs.plan_preferences.working_area.lat = event.latLng.lat();
            this.cArgs.plan_preferences.working_area.lng = event.latLng.lng();
            this.defoult_address = false;
            this.is_radius = true;

            if(this.args.address.lat != null && this.args.address.lng != null){
                if(this.args.plan_preferences.working_area.lat != parseFloat(this.args.address.lng) && this.args.plan_preferences.working_area.lng != parseFloat(this.args.address.lng)){
                    this.is_address = false;
                }
            }
        },
        
        setMarkerToAddress(){
            if(this.args.address.lat != null && this.args.address.lng != null){
                this.marker = {lat: parseFloat(this.args.address.lat), lng: parseFloat(this.args.address.lng)};
                this.center = {lat: parseFloat(this.args.address.lat), lng: parseFloat(this.args.address.lng)};
                this.cArgs.plan_preferences.working_area.lat = parseFloat(this.args.address.lat);
                this.cArgs.plan_preferences.working_area.lng = parseFloat(this.args.address.lng);
                this.is_address = true;
                this.defoult_address = false;
            }
        },

        setDayTypes(){
            if(this.cArgs.plan_preferences.ids_tod_const_type.includes(1)) this.day = '1';
            if(this.cArgs.plan_preferences.ids_tod_const_type.includes(2)) this.evening = '1';
            if(this.cArgs.plan_preferences.ids_tod_const_type.includes(3)) this.night = '1';
        },

        setMap(){
            if(this.args.plan_preferences.working_area.lat == null && this.args.plan_preferences.working_area.lng == null){
                if(this.args.address.lat != null && this.args.address.lng != null){
                    this.marker = {lat: parseFloat(this.args.address.lat), lng: parseFloat(this.args.address.lng)};
                    this.center = {lat: parseFloat(this.args.address.lat), lng: parseFloat(this.args.address.lng)};
                } else {
                    this.center = {lat: 52.15, lng: 5.383333};
                    this.marker = {lat: 52.15, lng: 5.383333};
                    this.cArgs.plan_preferences.working_area.lat = 52.15;
                    this.cArgs.plan_preferences.working_area.lng = 5.383333;
                    this.is_address = true;
                    this.defoult_address = true;
                }
                
            } else {
                this.marker = {lat: parseFloat(this.args.plan_preferences.working_area.lat), lng: parseFloat(this.args.plan_preferences.working_area.lng)};
                this.center = {lat: parseFloat(this.args.plan_preferences.working_area.lat), lng: parseFloat(this.args.plan_preferences.working_area.lng)};

                if(this.args.plan_preferences.working_area.lat != parseFloat(this.args.address.lng) && this.args.plan_preferences.working_area.lng != parseFloat(this.args.address.lng)){
                    this.is_address = false;
                }
            }

            
        },

        workingTimesChange(val, i){
            if(val == '1') this.cArgs.plan_preferences.ids_tod_const_type.push(i);
            else this.cArgs.plan_preferences.ids_tod_const_type.splice(this.cArgs.plan_preferences.ids_tod_const_type.indexOf(i), 1);
        },

        closeModal() {
            this.$emit("update:show", false);
        },

    }
    
}
</script>
<style scoped>
.app-local-text{
    color: #7f7f7f !important;
}

.app-local-padding .card-body{
    padding: 6px !important;
}
</style>