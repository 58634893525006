<template>
    <div>
        <b-card class="mb-3" v-bind:class="{ 'app-card-without-shadow': profile.person_data.id_status_const_type === 4}">
            <b-row>
                <b-col start >
                    <div class="app-card-profile-title" v-if="profile.person_data.id_status_const_type == 3 || (profile.person_data.id_status_const_type > 3 && profile.compliance_check.correct === 0 && profile.person_data.id_status_const_type != 1002)">
                        {{trans('fp-screening',8)}}
                    </div>
                </b-col>  


                <b-col cols="auto" end class="d-flex align-items-center">
                    <div style="min-width:24px; max-width:24px; min-height: 27px;">
                            <b-link v-if="!loading_list" style="color: #adb5bd; cursor:pointer;outline: none !important;" @click="getList">
                                <b-icon icon="arrow-repeat" style="width:22px; height:22px" />
                            </b-link>
                            <b-spinner v-else style="width:19px; height:19px; margin-left:2px; margin-top:5px" small></b-spinner>       
                    </div>
                </b-col>

            </b-row>

             <b-row class="mt-3">
                <b-col start>
                    
                    <!-- (profile.person_data.id_status_const_type > 3 && profile.compliance_check.correct === 0)-->
                    <b-alert variant="light" :show="show_compliance_button" class="mb-3" v-if="profile.person_data.id_status_const_type == 3">
                        <div class="d-flex w-100 justify-content-between">
                            <div>
                                <div class="d-flex w-100 align-items-center">
                                    <b-icon icon="list-check" font-scale="3" class="mr-4"></b-icon>                                  
                                    <span class="align-middle font-weight-600 text-base">{{trans('confirm-data-statement',177)}}</span>                                    
                                </div>
                            </div>
                            <div class="d-flex align-items-center">
                                <app-button :disabled="loading_confirm" @click="confirmData" type="primary">
                                    {{trans('confirm-data',175)}}
                                </app-button>                           
                            </div>
                        </div>
                    </b-alert>
                    
                    <b-overlay :show="loading" :opacity="0.0">
                        <b-list-group flush>

                            <!-- ID -->
                                <b-list-group-item class="app-checklist-status-item" v-for="item in profile.compliance_check.items" :key="item.id" v-on:change="changeCheckbox(item.id_compliance_data_check, item.visible_status, item.id_compliance_const_type)" v-bind:class="{ 'app-checklist-danger': item.dot_status == 0, 'app-checklist-warning': item.dot_status == 1,  'app-checklist-success': item.dot_status == 2}" >
                                    
                                    <b-row align-v="center" class="ml-1">
                                        <b-col md="auto" class="pr-0">
                                            <!--item.id_compliance_const_type == 1 || item.dot_status != 2-->
                                            <b-form-checkbox  v-model="item.visible_status" class="custom-control-primary checkbox-green" 
                                                             value="1" 
                                                             unchecked-value="0" 
                                                             size="lg" 
                                                             :disabled="(!item.allow_check || (profile.person_data.id_status_const_type >= 4 && profile.compliance_check.correct === 1)) || (user_role > 2 && !profile.can_user_edit)" 
                                                             button-variant="warning" 
                                                             v-bind:class="{'custom-checkbox-checked': item.visible_status == 1 }"
                                                              />
                                        </b-col>
                                        <b-col class="ml-1 pl-0">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div>
                                                    <h4 class="mb-0 text-sm font-weight-600" >{{item.compliance_name}}</h4>
                                                    <span class="app-action-status-gray-text" v-html="item.external_information"></span>
                                                </div>
                                            </div>
                                        </b-col>
                                        
                                        <b-col md="auto">
                                            <!--INNE PRZYCISKI -->
                                            
                                            <!--<span v-if="item.id_compliance_const_type == 3" class="text-sm mr-2">{{item.external_information}}</span>
                                            <span v-if="item.id_compliance_const_type == 6" class="text-sm mr-2">{{item.external_information}}</span>-->
                                            

                                            <b-icon id="popover-id-errors" tabindex="0" icon="exclamation-circle-fill" class="app-icon-warning"  v-if="item.id_compliance_const_type == 1 && item.verified == 2 && item.dot_status == 0" @click="showDataCheckerInfo"></b-icon>
                                            <b-popover target="popover-id-errors" triggers="focus" no-fade v-if="item.id_compliance_const_type == 1 && item.verified == 2 && item.dot_status == 0">
                                                <template #title>
                                                    {{trans('id-devations',8)}}
                                                </template>
                                                <p class="app-popover-text" v-html="item.popover_errors"></p>
                                            </b-popover>

                                            <b-dropdown variant="link"  no-caret right v-if="showDropDownMenu(item) && (!([3, 6, 10, 11].includes(item.id_compliance_const_type)) || user_role < 3 || profile.can_user_edit)">
                                                <template #button-content>
                                                    <b-icon icon="three-dots-vertical" class="text-body" ></b-icon>
                                                </template>
                                                
                                                <!-- CV -->
                                                <b-dropdown-item @click="scrollToDocument([7])" v-if="item.id_compliance_const_type == 5 && (item.dot_status == 1 || item.dot_status == 2)">{{trans('show-document',3)}}</b-dropdown-item>
                                                <b-dropdown-item @click="addDocument(7)" v-if="item.id_compliance_const_type == 5 && item.dot_status == 0 && (user_role < 3 || profile.can_user_edit)">{{trans('upload-cv',8)}}</b-dropdown-item>

                                                <!-- VOG -->
                                                <b-dropdown-item @click="scrollToDocument([9])" v-if="item.id_compliance_const_type == 4 && (item.dot_status == 1 || item.dot_status == 2)">{{trans('show-document',3)}}</b-dropdown-item>
                                                <b-dropdown-item @click="addDocument(9)" v-if="item.id_compliance_const_type == 4 && item.dot_status == 0 && (user_role < 3 || profile.can_user_edit)">{{trans('upload-vog',8)}}</b-dropdown-item>

                                                <!-- BIG -->
                                                <b-dropdown-item @click="changeBIG()" v-if="item.id_compliance_const_type == 3 && (user_role < 3 || profile.can_user_edit)">{{trans('change-big',8)}}</b-dropdown-item>

                                                <!-- KVK -->
                                                <b-dropdown-item @click="changeKVK()" v-if="item.id_compliance_const_type == 6 && (user_role < 3 || profile.can_user_edit)">{{trans('change-kvk',8)}}</b-dropdown-item>

                                                <!-- GESCH -->
                                                <b-dropdown-item @click="scrollToDocument([5])" v-if="item.id_compliance_const_type == 7 && (item.dot_status == 1 || item.dot_status == 2)">{{trans('show-document',3)}}</b-dropdown-item>
                                                <b-dropdown-item @click="addDocument(5)" v-if="item.id_compliance_const_type == 7 && item.dot_status == 0 && (user_role < 3 || profile.can_user_edit)">{{trans('upload-history',8)}}</b-dropdown-item>

                                                <!-- BEROEPSAANSPRAKELIJKHEID id_compliance_const_type == 8 -->
                                                <b-dropdown-item @click="scrollToDocument([6])" v-if="item.id_compliance_const_type == 8 && (item.dot_status == 1 || item.dot_status == 2)">{{trans('show-document',3)}}</b-dropdown-item>
                                                <b-dropdown-item @click="addDocument(6)" v-if="item.id_compliance_const_type == 8 && item.dot_status == 0 && (user_role < 3 || profile.can_user_edit)">{{trans('upload-liability',8)}}</b-dropdown-item>

                                                <!-- KWALISBEIT -->
                                                <!--<b-dropdown-item @click="scrollToDocument([4])" v-if="item.id_compliance_const_type == 9 && (item.dot_status == 1 || item.dot_status == 2)">{{trans('show-document',3)}}</b-dropdown-item>-->
                                                <!--<b-dropdown-item @click="addDocument(4)" v-if="item.id_compliance_const_type == 9 && item.dot_status == 0">Kwalisbeit uploaden</b-dropdown-item>-->

                                                <!-- DIPLOMA -->
                                                <b-dropdown-item @click="scrollToDocument([3])" v-if="item.id_compliance_const_type == 2 && item.total_diploma > 0">{{trans('show-document',3)}}</b-dropdown-item>
                                                <b-dropdown-item @click="addDocument(3)" v-if="item.id_compliance_const_type == 2 && (user_role < 3 || profile.can_user_edit)">{{trans('upload-diploma',8)}}</b-dropdown-item>
                                                <!--<b-dropdown-item @click="verifyDiplomaAgain" v-if="item.id_compliance_const_type == 2 && item.total_diploma > 0">Controleer opnieuw</b-dropdown-item>-->
                                                <!--<b-dropdown-item @click="verifiedDiplomas(item.total_diploma, item.verified_diploma)" v-if="item.id_compliance_const_type == 2 && item.total_diploma > 0 && item.dot_status == 0">Geverifieerd diploma(s)</b-dropdown-item>-->

                                                <!-- ID -->
                                                <b-dropdown-item @click="scrollToDocument([1,2])" v-if="item.id_compliance_const_type == 1">{{trans('show-document',3)}}</b-dropdown-item>
                                                <b-dropdown-item @click="checkData" v-if="item.id_compliance_const_type == 1 && item.verified == 0 && (user_role < 3 || profile.can_user_edit)">{{trans('control-check',8)}}</b-dropdown-item>
                                                <b-dropdown-item @click="confirmIdDocument(item.id_compliance_data_check)" v-if="item.id_compliance_const_type == 1 && user_role < 3 && item.verified != 3 && (item.verified !=2 || item.dot_status != 2) && item.verified !=null">{{trans('fp-confirm', 172)}}</b-dropdown-item>

                                                <!-- LRZA NUMBER -->
                                                <!--<b-dropdown-item @click="changeCheckbox(item.id_compliance_data_check, 1, item.id_compliance_const_type)" v-if="item.id_compliance_const_type == 10 && item.dot_status == 1">Valideer het opgegeven registratienummer WTZA</b-dropdown-item>-->
                                                <b-dropdown-item @click="changeLRZA()" v-if="item.id_compliance_const_type == 10 && (user_role < 3 || profile.can_user_edit)">{{trans('fp-change-lrza',172)}}</b-dropdown-item>

                                                <!-- REFERENCE -->
                                                <!--<b-dropdown-item @click="changeCheckbox(item.id_compliance_data_check, 1, item.id_compliance_const_type)" v-if="item.id_compliance_const_type == 11 && item.dot_status == 1">Referentie gegevens aanpassen</b-dropdown-item>-->
                                                
                                                <b-dropdown-item @click="scrollToReference()" v-if="item.id_compliance_const_type == 11">{{trans('fp-show-reference-data',172)}}</b-dropdown-item>
                                                <b-dropdown-item @click="changeReference()" v-if="item.id_compliance_const_type == 11 && (user_role < 3 || profile.can_user_edit)">{{trans('change-reference',8)}}</b-dropdown-item>
                                                
                                                <!-- BTW NUMBER -->
                                                <b-dropdown-item @click="changeBTWNumber()" v-if="item.id_compliance_const_type == 13 && (user_role < 3 || profile.can_user_edit)">{{trans('change-btw',3)}}</b-dropdown-item>

                                                <!--PROOF REGISTRATION-->
                                                <b-dropdown-item @click="scrollToDocument([44])" v-if="item.id_compliance_const_type == 14 && (item.dot_status == 1 || item.dot_status == 2)">{{trans('show-document',3)}}</b-dropdown-item>
                                                <b-dropdown-item @click="addDocument(44)" v-if="item.id_compliance_const_type == 14 && item.dot_status == 0 && (user_role < 3 || profile.can_user_edit)">{{trans('upload-proof-of-enrollment',8)}}</b-dropdown-item>

                                                <!--PENSION-->
                                                <b-dropdown-item @click="changePension()" v-if="item.id_compliance_const_type == 15 && (user_role < 3 || profile.can_user_edit) && profile.person_data.id_status_const_type != 1002">{{trans('fp-view-pension-information',172)}}</b-dropdown-item>

                                                <!--<b-dropdown-item @click="scrollToDocument(item.id_document_const_type)" v-if="item.id_compliance_const_type == 1 || item.id_compliance_const_type == 2 || item.id_compliance_const_type == 4 || item.id_compliance_const_type == 5">{{trans('show-document',3)}}</b-dropdown-item>
                                                <b-dropdown-item @click="showConfirmation()" v-if="item.id_compliance_const_type == 1">Controle uitvoeren</b-dropdown-item>
                                                <b-dropdown-item v-if="item.id_compliance_const_type == 3 || item.id_compliance_const_type == 6" @click="item.compliance_name == 'BIG' ? changeBIG() : changeKVK()">{{trans('change',175)}}</b-dropdown-item>-->
                                            </b-dropdown> 

                                            <!-- kropka -->
                                            <!--<span class="ml-2" v-bind:class="{ dotInactive: item.dot_status == 0, dotProgress: item.dot_status == 1,  dotActive: item.dot_status == 2}"> </span>-->
                                            
                                        </b-col>
                                    </b-row>
                                    
                                </b-list-group-item>
                            
                        </b-list-group>

                    </b-overlay>
                    
                </b-col>
             </b-row>
        </b-card>
        <people-profile-identity-change-big v-if="mChangeBig.show" :show.sync="mChangeBig.show" :result.sync="mChangeBig.result" :args="mChangeBig.args" :return.sync="profile"/>
        <people-profile-identity-change-kvk v-if="mChangeKvk.show" :show.sync="mChangeKvk.show" :result.sync="mChangeKvk.result" :args="mChangeKvk.args" :return.sync="profile"/>
        <people-profile-lrza-change v-if="mChangeLrza.show" :show.sync="mChangeLrza.show" :result.sync="mChangeLrza.result" :args="mChangeLrza.args" :return.sync="profile" />
        <people-profile-reference-change v-if="mChangeReference.show" :show.sync="mChangeReference.show" :args.sync="mChangeReference.args" :result.sync="mChangeReference.result" :return.sync="profile" />
        <people-profile-documents-add-step2 v-if="mAddDocument.show" :show.sync="mAddDocument.show" :result.sync="mAddDocument.result" :args.sync="mAddDocument.args" :return.sync="profile" />
        <people-profile-btw-number-change v-if="mChangeBTW.show" :show.sync="mChangeBTW.show" :result.sync="mChangeBTW.result" :args="mChangeBTW.args" :return.sync="profile" />
        <people-profile-pension-change v-if="mChangePension.show" :show.sync="mChangePension.show" :args.sync="mChangePension.args" :result.sync="mChangePension.result" :return.sync="profile" />
   
    </div>
</template>
<script>

import AppAlerts from '@core/scripts/AppAlerts';
import { BPopover } from "bootstrap-vue";
import axios from "@axios";
import PeopleProfileIdentityChangeKvk from '@app/People/Profile/Identity/PeopleProfileIdentityChangeKvk.vue';
import PeopleProfileIdentityChangeBig from '@app/People/Profile/Identity/PeopleProfileIdentityChangeBig.vue';
import PeopleProfileDocumentsAddStep2 from '@app/People/Profile/Documents/PeopleProfileDocumentsAddStep2.vue';
import PeopleProfileLrzaChange from '@app/People/Profile/Financial/PeopleProfileLrzaChange.vue';
import PeopleProfileReferenceChange from '@app/People/Profile/Reference/PeopleProfileReferenceChange.vue';
import PeopleProfileBtwNumberChange from '@app/People/Profile/Financial/PeopleProfileBtwNumberChange.vue';
import PeopleProfilePensionChange from '@app/People/Profile/Pension/PeopleProfilePensionChange.vue';

export default {
    components: {
        PeopleProfileIdentityChangeKvk,
        PeopleProfileIdentityChangeBig,
        PeopleProfileDocumentsAddStep2,
        PeopleProfileLrzaChange,
        PeopleProfileReferenceChange,
        PeopleProfileBtwNumberChange,
        PeopleProfilePensionChange,
        BPopover
    },

    props:["profile"],

    created(){
        this.checkShowConfirmDocuments();
    },

    watch: {        

        "mComplianceCheckKvkChange.result": function(val) {
            if (val === 1) {
                this.mComplianceCheckKvkChange.result = 0;
                this.getList();
            }
        },

        "mComplianceChecksBigChange.result": function(val) {
            if (val === 1) {
                this.mComplianceChecksBigChange.result = 0;
                this.getList();
            }
        },

        "profile.compliance_check.refresh": {
            handler: function(val) {
                if (val > 0) {
                    this.getList();
                }                
            },
        },

    },

    computed: {
        cProfile: {
            get() { return this.profile },
            set() { this.$emit('update:profile', false) }
        },
    },

    data() {
            return {
                alert_class: new AppAlerts(),
                loading_list: false,
                loading: false,
                loading_confirm: false,
                sum_verified: 0,
                show_compliance_button: false,
                document_type_id: 0,
                id_document: 0,
                mChangeBig: { show: false, args: {id_person_data_main: null, big_data_main: null, big_check_date: null, big_number: null, is_big_number: null, return_type: null}, result: 0, return: null },
                mChangeKvk: { show: false, args: {id_person_data_main: null, kvk_number: null, kvk_data_main: null, kvk_check_date: null, return_type: null}, result: 0, return: null },
                mAddDocument: {show: false, args: {id_document_const_type: 0, id_person_data_main: 0 , filters: null, return_type: null}, result: 0, return: null},
                mChangeLrza: { show: false, args: {id_person_data_main: null, lrza: null}, result: 0, return: null },
                mChangeBTW: { show: false, args: {id_person_data_main: null, btw_number: null}, result: 0, return: null },
                mChangeReference: { show: false, args: {id_person_data_main: null, person_reference:{
                                                                                                        first_name: null,
                                                                                                        inbetween_name: null,
                                                                                                        last_name: null,
                                                                                                        function: null,
                                                                                                        organisation: null,
                                                                                                        email: null,
                                                                                                        phone_prefix: '+31',
                                                                                                        phone_number: null
                                                                                                    }}, 
                                                                                                        result: 0, return: null },
                mChangePension: { show:false, args: {
                                                        id_person_data_main: null,
                                                        pension_type: null,
                                                        previous_worked_weeks: null,
                                                        pension_free_weeks: null,
                                                        pension_basic_weeks: null,    
                                                    }, result: 0, return: null},
                user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
            };
        },


    methods: {    
        
        getList: function(){
            this.loading_list = true;
            axios
                .post("people/profile/compliance-check/getList", {
                    id_person_data_main: this.profile.id,
                })
                .then(res => {
                    this.cProfile.compliance_check.items = res.data.items;
                    this.cProfile.compliance_check.correct = res.data.correct;
                    this.cProfile.compliance_check.show = res.data.show;
                    this.checkShowConfirmDocuments();
                })
                .catch(error => {
                    console.error(`error during request: ${error}`);
                    this.loading_list = false;
                })
                .finally(() => {                  
                    this.loading_list = false;
                });;

        },

        showDropDownMenu(item){

            if([1, 2, 3, 4, 5, 6, 7, 8, 10, 11, 13, 14, 15].includes(item.id_compliance_const_type)){
                return true;
            }else{
                return false;
            }

        },

        checkData(){
            this.alert_class.openAlertConfirmation(this.trans('document-confirm',177)).then(res => {
                if(res == true){
                       
                    this.loading = true;    
                    axios 
                        .post("core/data-checker/check", {
                            id_person_data_main: this.profile.id,
                            return_type: "compliance_check"
                        })
                        .then((res) => {
                            
                            //this.$emit("showLoading");
                            this.cProfile.compliance_check.items = res.data.items;
                            this.cProfile.compliance_check.correct = res.data.correct;
                            this.cProfile.compliance_check.show = res.data.show;
                            this.loading = false;
                        })
                        .catch((error) => {
                            this.alert_class.openAlertWarning(this.trans('file-not-found',180)).then(res => {})
                            this.loading = false;
                        });

                }
            });    
        
        },


        scrollToDocument(documentType){
            this.cProfile.documents.filter_types = documentType;
            this.$emit('scrollToDocument', documentType);
        },


        scrollToReference(){
            this.$emit('scrollToReference', null);
        },

        changeCheckbox(id_compliance_data_check, status, id_compliance_const_type){
            this.loading = true;
            axios 
                .post("people/profile/compliance-check/changeComplianceCheck", {
                                                                id_person_data_main: this.profile.id,
                                                                id_compliance_data_check: id_compliance_data_check,
                                                                status: status,
                                                                id_compliance_const_type: id_compliance_const_type
                })
                .then((res) => {
                    this.cProfile.compliance_check.items = res.data.items;
                    this.cProfile.compliance_check.correct = res.data.correct;
                    this.cProfile.compliance_check.show = res.data.show;
                    this.checkShowConfirmDocuments();
                    this.loading = false;
                    
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                    this.loading = false;
                    
                });

        },

        checkShowConfirmDocuments(){
            
            if(this.profile.compliance_check.items != null){
                
                this.sum_verified = 0;
                var isIdDocument = false;
                var lengthArray = this.profile.compliance_check.items.length;

                this.cProfile.compliance_check.items.forEach(item => {
                    /*if(item.id_compliance_const_type == 1){
                        lengthArray = lengthArray - 1;
                    }*/

                    if(item.status == 1){
                        this.sum_verified = this.sum_verified + 1;
                    }
                });


                if(this.sum_verified == lengthArray){
                    this.show_compliance_button = true;
                }else{
                    this.show_compliance_button = false;
                }

            }

        },

        confirmData(){
            this.loading_confirm = true;
            axios 
                .post("people/profile/compliance-check/confirmComplianceCheck", {
                    id_person_data_main: this.profile.id
                })
                .then((res) => {
                    
                    this.cProfile.person_data = res.data;
                    this.loading_confirm = false;
                    
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                    this.loading_confirm = false;
                });

        },

        confirmIdDocument(id_compliance_data_check){

            this.loading_list = true;
            axios 
                .post("people/profile/compliance-check/confirmIdDocument", {
                    id_person_data_main: this.profile.id,
                    id_compliance_data_check: id_compliance_data_check

                })
                .then((res) => {
                    if(res.data == -1){
                        this.alert_class.openAlertWarning(this.trans('missing-document-number', 177)).then(() =>{});
                    }else if(res.data == -2){
                        this.alert_class.openAlertWarning(this.trans('fp-compliance-no-id',172)).then(() =>{});
                    }else{
                        this.cProfile.compliance_check.items = res.data.items;
                        this.cProfile.compliance_check.correct = res.data.correct;
                        this.cProfile.compliance_check.show = res.data.show;
                        this.checkShowConfirmDocuments();
                    }

                    this.loading_list = false;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                    this.loading_list = false;
                });

        },

        changeBIG(){
            this.mChangeBig.args.id_person_data_main = this.profile.id;
            this.mChangeBig.args.big_data_main = this.profile.person_data.big_data_main;
            this.mChangeBig.args.big_check_date = this.profile.person_data.big_check_date;
            this.mChangeBig.args.big_number = this.profile.person_data.big_number;
            this.mChangeBig.args.is_big_number = this.profile.person_data.is_big_number;
            this.mChangeBig.args.return_type = "compliance_check";
            this.mChangeBig.show = true;
        },

        changeKVK(){
            this.mChangeKvk.args.id_person_data_main = this.profile.id;
            this.mChangeKvk.args.kvk_number = this.profile.person_data.kvk_number;
            this.mChangeKvk.args.kvk_data_main = this.profile.person_data.kvk_data_main;
            this.mChangeKvk.args.kvk_check_date = this.profile.person_data.kvk_check_date;
            this.mChangeKvk.args.return_type = "compliance_check";
            this.mChangeKvk.show = true;
        },

        addDocument(id_document_const_type) {  
            this.mAddDocument.args.id_document_const_type = id_document_const_type;
            this.mAddDocument.args.id_person_data_main = this.profile.id;
            this.mAddDocument.args.return_type = "compliance_check"   
            this.mAddDocument.show = true;
        },

        changeLRZA(){
            this.mChangeLrza.args.id_person_data_main = this.profile.id;
            this.mChangeLrza.args.lrza = this.profile.person_data.lrza;
            this.mChangeLrza.args.return_type = "compliance_check";
            this.mChangeLrza.show = true;
        },

        changeBTWNumber(){
            this.mChangeBTW.args.id_person_data_main = this.profile.id;
            this.mChangeBTW.args.btw_number = this.profile.person_data.btw_number;
            this.mChangeBTW.args.return_type = "compliance_check";
            this.mChangeBTW.show = true;
        },

        changeReference(){
            this.mChangeReference.args.person_reference = JSON.parse(this.profile.person_data.person_reference) ? JSON.parse(this.profile.person_data.person_reference) : this.mChangeReference.args.person_reference;
            this.mChangeReference.args.id_person_data_main = this.profile.id;
            this.mChangeReference.args.return_type = "compliance_check";
            this.mChangeReference.show = true;
        },

        changePension(){

            if(this.profile.person_data.pension_age_warning == 0){
                
                this.mChangePension.args.id_person_data_main = this.profile.id;
                this.mChangePension.args.pension_type = this.profile.person_data.pension_type;
                this.mChangePension.args.previous_worked_weeks = this.profile.person_data.previous_worked_weeks;
                this.mChangePension.args.pension_free_weeks = this.profile.person_data.pension_free_weeks;
                this.mChangePension.args.pension_basic_weeks = this.profile.person_data.pension_basic_weeks;
                this.mChangePension.show = true;

            }else{
                this.$emit('scrollToPension', null);
            }
            

        },

        showDataCheckerInfo() {
            this.$root.$emit("bv::show::popover", "popover-id-errors");
        },


    }
 
};
</script>

<style scoped>
.app-local-width{
    min-width:24px; 
    max-width:24px;
}
.app-local-link{
    color: #adb5bd;
    cursor:pointer;
    outline: none !important;
}
.dotActive {
  height: 10px;
  width: 10px;
  margin-right: 6px;
  background-color: #43a047;
  border-radius: 50%;
  display: inline-block;
}

.dotInactive {
  height: 10px;
  width: 10px;
  margin-right: 6px;
  background-color: #e53935;
  border-radius: 50%;
  display: inline-block;
}

.dotProgress {
  height: 10px;
  width: 10px;
  margin-right: 6px;
  background-color: #fdd835;
  border-radius: 50%;
  display: inline-block;
}

.checkbox-green .custom-control-input:checked ~ .custom-control-label::before{
    background-color: #12B76A !important;
}

.app-action-status-red-text{
    font-size: 12px;
    font-weight: 600;
    color: #f04438 !important;
}

.app-action-status-gray-text{
    font-size: 12px;
    color: #8898aa;
}

.app-icon-warning{
    color: #f04438;
}

.app-card-without-shadow{
    box-shadow: none;
    -webkit-box-shadow: none;
}

.popover {
    max-width: 360px;
}
</style>