<template>
    <b-modal ref="modal-add" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('fp-reference',172)">

        <ValidationObserver ref="form" >
            
            <app-row-left-label :label="trans('first-name',182)">
                <app-input  v-model="cArgs.person_reference.first_name" :validatorRules="'required'" validatorName="Voornaam"  mask="letters-20" />
            </app-row-left-label>

            <app-row-left-label :slots="[4,8]" :label="trans('last-name',182)">
                <template v-slot:col-1>
                    <app-input :placeholder="trans('inbetween-name',182)" v-model="cArgs.person_reference.inbetween_name" mask="lettersE1-10" />
                </template>

                <template v-slot:col-2>
                    <app-input :placeholder="trans('last-name',182)" v-model="cArgs.person_reference.last_name" :validatorRules="'required'" validatorName="Achternaam"  mask="lettersE1-30" />
                </template>
            </app-row-left-label>

            <app-row-left-label :label="trans('function',3)">
                <app-input v-model="cArgs.person_reference.function" :validatorRules="'required'" validatorName="Functie" />
            </app-row-left-label>

            <app-row-left-label :label="trans('organization',169)">
                <app-input v-model="cArgs.person_reference.organisation" :validatorRules="'required'" validatorName="Organisatie" />
            </app-row-left-label>

            <app-row-left-label :label="trans('email',182)">
                <app-email :email.sync="cArgs.person_reference.email" ref="email_control" :changeMode="true" type="person" :validatorRules="'required'" validatorName="Email" />
            </app-row-left-label>

            <app-row-left-label :label="trans('phone-number',182)">
                <app-phone :phone.sync="cArgs.person_reference.phone_number" :prefix.sync="cArgs.person_reference.phone_prefix" :isRequired="true" /> 
            </app-row-left-label>

        </ValidationObserver>

        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="change" :loading="loading">
                {{trans('save',175)}}
            </app-button>
        </template>
    </b-modal>
</template>

<script>
import axios from "@axios";
import AppPhone from '@core/components/AppPhone.vue';
import AppEmail from '@core/components/AppEmail.vue';
import { ValidationObserver } from "vee-validate";

export default {
    components: {
        AppPhone,
        AppEmail,
        ValidationObserver
    },

    props: ["show", "args", "return", "result"],

    watch: {
        
    },

    created(){

    },

    computed: {
        computedShow: {
        get() { return this.show },
        set() { this.$emit('update:show', false) }, 
        },

        cArgs: {
            get() { return this.args },
            set() { this.$emit('update:args', false) }
        },

        cReturn: {
            get() { return this.return },
            set() { this.$emit('update:return', false) }
        },
    },

    data() {
        return {
            loading: false,
        };
    },

    methods: {
        change() {
            
            this.$refs.form.validate().then((result) => {
                if (!result) {
                    return false;
                } else {
            
                    this.loading = true;
                    
                    axios
                        .post("people/profile/reference/change", {
                            id_person_data_main: this.args.id_person_data_main,
                            person_reference: JSON.stringify(this.cArgs.person_reference),
                            return_type: this.args.return_type
                        })
                        .then((res) => {
                            this.$emit("update:show", false);
                            this.$emit("update:result", 1);

                            if(this.args.return_type == "compliance_check"){
                                this.cReturn.compliance_check.items = res.data.items;
                                this.cReturn.compliance_check.correct = res.data.correct;
                                this.cReturn.compliance_check.show = res.data.show;
                                this.cReturn.refresh ++;
                            }else if(this.args.return_type == "reference"){
                                this.cReturn.person_data = res.data;
                                this.cReturn.compliance_check.refresh ++;
                            }
                            
                            this.loading = false;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        });
                }
            });
                
            
        },

        closeModal() {
            this.$emit("update:show", false);
        },

    }
};
</script>

<style scoped>

</style>
