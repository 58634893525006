<template>
    <div style="min-height:34px">
        <b-row  v-if="profile.person_data.id_status_const_type > 2 && array_functions.includes(profile.person_data.id_function_data_main) && profile.person_data.id_status_const_type != 1002" class="app-component-icon-edit">
            <b-dropdown v-if="!loading && (user_role < 3 || profile.can_user_edit)" variant="link" no-caret right>
                <template  #button-content >
                    <b-icon icon="three-dots-vertical" class="text-body"></b-icon>
                </template>
                <b-dropdown-item @click="change" >{{trans('change',175)}}</b-dropdown-item>
            </b-dropdown>
        </b-row>
        
        <b-row v-if="array_functions.includes(profile.person_data.id_function_data_main) && Object.keys(profile.competences).length > 0">
            <b-col>
                <div class="mb-2" v-for="group in profile.competences" :key="group.id_competence_const_group">
                    <p class="col-list-label font-weight-600" >{{group.competence_group_name}}</p>
                    <div v-for="type in group.competences" :key="type.id_competence_const_type">
                        <b-row>
                            <b-col>
                                <p class="col-list-label">{{ type.competence_name }}</p>
                            </b-col>
                            <b-col>
                                <p class="col-form-value">{{ type.competence_date ? type.competence_date : '-'}}</p>
                            </b-col>
                        </b-row>
                    </div>
                </div>
            </b-col>
        </b-row>

        <b-row v-if="array_functions.includes(profile.person_data.id_function_data_main) && Object.keys(profile.competences).length == 0">
            <b-col start>
                <p class="col-list-label">{{trans('no-risks-registered',177)}}</p>
            </b-col>
        </b-row>
        <b-row v-if="!array_functions.includes(profile.person_data.id_function_data_main)">
            <b-col start>
                <p class="col-list-label">{{trans('no-risky-actions-needed',177,{function_name:lowerCase(profile.person_data.function_name)})}}</p>
            </b-col>
        </b-row>

        <person-profile-competence-change v-if="mCompetenceChange.show" :show.sync="mCompetenceChange.show" :result.sync="mCompetenceChange.result" :args="mCompetenceChange.args" :return.sync="cProfile"/>
    </div>
</template>
<script>

import PersonProfileCompetenceChange from "./PersonProfileCompetenceChange.vue";

export default {
    components: {
        PersonProfileCompetenceChange,
    },

    props: ["profile"],

    created(){
        if(this.profile.person_data.applicant_type == 3){
            this.array_functions = [3, 5, 10];
        }
    },
    watch: {

    },

     computed: {
        cProfile: {
            get() { return this.profile },
            set(value) { this.$emit('update:profile', value) }
        }
    },

  data(){
        return{
            array_functions: [8, 9, 10, 11],
            mCompetenceChange: { show: false, args: {
                id_person_data_main: null, 
                id_function_data_main: null,
                applicant_type: null,
                }, result: 0, return: null },
            loading: false,
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
        }
    },

    methods:{
        change() {
            this.mCompetenceChange.args.id_function_data_main = this.profile.person_data.id_function_data_main;
            this.mCompetenceChange.args.id_person_data_main = this.profile.id;
            this.mCompetenceChange.args.applicant_type = this.profile.person_data.applicant_type;
            this.mCompetenceChange.show = true;
        },
        
        lowerCase(text){
            return text ? text.toLowerCase() : '';
        }
    }
 
};
 
</script>

<style scoped>

</style>