<template>
    <div>
        <b-modal ref="modal-add" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('select-document-type',3)">
            <b-card class="app-local-card-size">
                <div class="app-fixed-search d-flex align-items-center">
                    <div class="d-flex align-content-center justify-content-between w-100">
                        <b-input-group class="input-group-merge" >
                        
                            <b-input-group-prepend is-text class="app-local-icon">
                                <b-icon icon="search" class="text-muted" v-if="!loading"></b-icon>      
                                <b-spinner class="align-middle" small v-if="loading"></b-spinner>  
                            </b-input-group-prepend >

                            <b-form-input class="pl-2 mt-1 border-0"  :placeholder="trans('search',3)" v-model="filters.search" :lazy="true"/>

                            <b-input-group-append is-text class="app-local-dropdown">
                                <b-dropdown variant="link" no-caret right >
                                    <template #button-content >
                                            <b-icon icon="three-dots-vertical" class="text-body"></b-icon>
                                    </template>
                                        <b-dropdown-item @click="filters.sort_desc = false"> {{trans('sort-az',260)}} </b-dropdown-item>
                                        <b-dropdown-item @click="filters.sort_desc = true"> {{trans('sort-za',260)}} </b-dropdown-item>
                                </b-dropdown>
                            </b-input-group-append>
                        </b-input-group>
                    </div>
                </div>
                <hr class="app-search-modal-separator">
                    <b-list-group flush class="app-local-list-group-custom-style">
                        <b-list-group-item class="d-flex  align-items-center app-local-list-group-item-custom-style" action v-for="item in items" :key="item.value" tabindex="0" @click.prevent="selectItem(item)" >
                        <b-form-checkbox  v-model="id_document_const_type" :value="item.id" unchecked-value="0" size="sm" :class="id_document_const_type == item.id ? 'custom-checkbox-checked' : ''">
                            <span class="app-documents-item">{{item.document_type_name}}</span>
                        </b-form-checkbox>                    
                        </b-list-group-item>
                    </b-list-group>
            </b-card>
            <template #modal-footer>
                <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
                <app-button type="primary" :loading="loading_change" @click="change" :disabled="checkId">
                    {{trans('save',175)}}
                </app-button>
            </template>
        </b-modal>

        <b-modal ref="datepicker-modal" centered okVariant="primary" okTitle="OK" :title="datepicker_modal_title" size="sm" ok-only @ok="handleDatepickerOk">
            <b-row>
                <b-col>
                    <datepicker v-model="selected_date" :inline="true" :language="languages[language]" :disabled-dates="disabled_dates"></datepicker>
                </b-col>
            </b-row>
        </b-modal>
        
    </div> 
</template>       
            

<script>
import axios from "@axios";
import AppAlerts from '@core/scripts/AppAlerts';
import * as lang from "vuejs-datepicker/src/locale";
import moment from 'moment'
import Datepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js";

export default {
    components: {
        Datepicker
    },
    props: ["show", "result", "args", "return"],

    created() {
        this.getDocumentsTypes();
    },

    watch: {
        filters: {
            handler: function(val) {
                if (this.filters.sort_by != "") {
                    this.loading = true;
                    this.getDocumentsTypes();
                }
            },
            deep: true,
        }        
    },

    computed: {
        computedShow: {
        get() { return this.show },
        set() { this.$emit('update:show', false) }, 
        },
        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        },
        checkId: {
            get() {
                if(this.id_document_const_type === undefined) {
                    return true;
                } else if (this.id_document_const_type === false){
                    return true;
                } else {
                    return false;
                }

            }
        }
    },

    data() {
        return {
            loading: 0,
            loading_change: false,
            id_document_const_type: false,
            filters: {
                sort_by: "document_type_name",
                sort_desc: false,
                search: null,
            },
            items:[],
            types: ['application/pdf', "application/msword"],
            alert_class: new AppAlerts(),
            datepicker_modal_title: "",
            selected_date: "",
            date: null,
            language: "nl",
            languages: lang,
            form_data: new FormData(),
            disabled_dates: {
                to: moment()
                    .subtract(1, "days")
                    .toDate(),
            },
            
        };
    },

    methods: {
        openDatepickerModal(){
            this.$refs["datepicker-modal"].show();
        },
        handleDatepickerOk(){
            if(this.id_document_const_type == 5 || this.id_document_const_type == 6){                
                if(moment(this.selected_date).format("yyyy-MM-DD") == "Invalid date"){
                    this.date = moment().format("yyyy-MM-DD")
                }else{
                    this.date = moment(this.selected_date).format("yyyy-MM-DD")
                }
            }
            else if(this.id_document_const_type == 4 || this.id_document_const_type == 9){
                if(moment(this.selected_date).format("yyyy-MM-DD") == "Invalid date"){
                    this.date = moment().format("yyyy-MM-DD")
                }else{
                    this.date = moment(this.selected_date).format("yyyy-MM-DD")
                }
            }
            this.send();
        },

        selectItem(item) {          
           var index = this.items.indexOf(item);
           if (item.selected === '1') {
                item.selected = '0';
            } else {
                item.selected = '1';             
            }

            this.id_document_const_type = item.id;
            this.$set(this.items, index, item);
        },

        addDate(id_document_const_type){
            if(id_document_const_type == 5 || id_document_const_type == 6){
                this.disabled_dates = {to: moment().subtract(1, "days").toDate()}
                this.datepicker_modal_title = this.trans('set-expiration-date',8);
                this.openDatepickerModal();
            }else if(id_document_const_type == 4){
                this.disabled_dates = {from: moment().subtract(0, "days").toDate()}
                this.datepicker_modal_title = this.trans('set-creation-date',8);
                this.openDatepickerModal();
            }else if(id_document_const_type == 9){
                this.disabled_dates = {to: moment().subtract(90, "days").toDate(), from: moment().subtract(0, "days").toDate()}
                this.datepicker_modal_title = this.trans('set-creation-date',8);
                this.openDatepickerModal();
            }
        },

        getDocumentsTypes(){
            this.loading = 1;
            axios
                .post("people/profile/documents/getTypesChange", {
                    filters: JSON.stringify(this.filters),
                    applicant_type: this.args.applicant_type,
                    id_document_const_type: this.args.id_document_const_type,
                })
                .then((res) => {
                   this.items = res.data;
                   this.loading = 0;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                });
        },

        closeModal() {
            this.$emit("update:show", false);
        },

        change() {
            if(this.id_document_const_type == 7 && (!this.types.includes(this.args.mine_type))){
                this.alert_class.openAlertWarning(this.trans('document-change-type-error',177));
            } else {
                if([4,5,6,9].includes(this.id_document_const_type)){
                    this.addDate(this.id_document_const_type)
                }else{
                    this.send();
                }
            }     
        },

        send(){
            this.loading_change = true;
            axios
                .post("people/profile/documents/changeType", {
                    filters: this.args.filters,
                    applicant_type: this.args.applicant_type,
                    id_document_data_main: this.args.id_document_data_main,
                    id_document_const_type: this.id_document_const_type,
                    id_person_data_main: this.args.id_person_data_main,
                    date: this.date,
                })
                .then((res) => {
                this.cReturn.documents.items = res.data.items;
                this.cReturn.compliance_check.refresh ++;
                this.loading_change = false;
                })
                .catch((error) => {
                    this.alert_class.openAlertWarning(this.trans('file-not-found',180)).then(res => {})
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.computedShow = false;
                });
        }

    },
};
</script>

<style scoped>
.input-group-text {
    border: none;
}
.ps{
    height: 350px;
}
.card-body {
    padding-top: 0.5rem;
}
.app-local-card-size{
    min-height:300px !important; 
    max-height:600px !important
}
.app-local-dropdown{
    width:65px
}
.app-local-icon{
    width:40px !important;
}
.app-local-list-group-custom-style{
    max-height:225px !important;
    overflow-y: auto;
}

.app-local-list-group-item-custom-style{
    cursor:pointer; 
    padding:4px 4px;
}
</style>
