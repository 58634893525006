<template>
    <b-modal ref="modal-add" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('fp-scaling-title',172)">
        <ValidationObserver ref="form" >
            <b-form @submit.prevent="onSubmit">

                <app-row-left-label :label="trans('date-from',182)">
                    <app-date v-model="date_from" validatorName="Startdatum" validatorRules="required" />
                </app-row-left-label>
                
                <app-row-left-label :label="trans('cao',182)" >
                    <app-select v-model="section" type="getSectionsShortcuts" :validatorName="trans('cao',182)" validatorRules="required" :refreshIndex.sync="refresh_cao" :disable="!date_from"/>
                </app-row-left-label>

                <app-row-left-label :label="trans('scale',182)" >
                    <app-select v-model="scale_value" type="getSectionsScales" :validatorName="trans('scale',182)" :args.sync="users_args"  validatorRules="required" :refreshIndex.sync="refresh_scale" :disable="!section"/>
                </app-row-left-label>

                <app-row-left-label :label="trans('fp-periodic',184)" >
                    <app-select v-model="level" type="getSectionsLevels" :validatorName="trans('fp-periodic',184)" :args.sync="users_args"  validatorRules="required" :refreshIndex.sync="refresh_level" :disable="!scale_value"/>
                </app-row-left-label>

                <app-row-left-label :label="trans('cao-hourly-wage',184)">
                    <app-input v-model="rate" :placeholder="'00,00'" :disabled="true" :loading="loading_rate"/>
                </app-row-left-label>
                
            </b-form>
        </ValidationObserver>

        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="addWageData" :loading="loading">
                {{trans('add',175)}}
            </app-button>
        </template>
    </b-modal>
</template>

<script>
import axios from "@axios";
import { ValidationObserver } from "vee-validate";

export default {
    components: {
        ValidationObserver,
    },

    props: ["show", "result", "args", "return"],

    computed: {
        computedShow: {
        get() { return this.show },
        set() { this.$emit('update:show', false) }, 
        },
        
        cReturn: {
        get() { return this.return },
        set() { this.$emit('update:return', false) }
        }
    },

    watch: {

        date_from:{
            handler: function(value){
                if(this.date_from !== null){
                    this.users_args.date_from = this.date_from;
                    if(this.section  != null){
                        this.section.name = "";
                        this.section.value = null;
                        this.section = null;
                    }
                    if(this.scale_value  != null){
                        this.scale_value.name = "";
                        this.scale_value.value = null;
                        this.scale_value = null;
                    }
                    if(this.level  != null){
                        this.level.name = "";
                        this.level.value = null;
                        this.level = null;
                    }
                    this.rate = null;
                    this.refresh_cao++;
                }
            }
        },

        section: {
            handler: function(value){
                if(this.section !== null){
                    this.users_args.id_cao_data_main = this.section.value;
                    if(this.scale_value  != null){
                        this.scale_value.name = "";
                        this.scale_value.value = null;
                        this.scale_value = null;
                    }
                    if(this.level  != null){
                        this.level.name = "";
                        this.level.value = null;
                        this.level = null;
                    }
                    this.rate = null;
                    this.refresh_scale++;
                }
            },
            deep: true
        },

        scale_value:{
            handler: function(value){
                if(this.scale_value !== null){
                    this.users_args.scale_value = this.scale_value.value;
                    if(this.level  != null){
                        this.level.name = "";
                        this.level.value = null;
                        this.level = null;
                    }
                    this.rate = null;
                    this.refresh_level++;
                }
            }
        },

        level: {
            handler: function(value) {
                this.rate = null;
                if(this.date_from != null && this.section != null && this.scale_value != null && this.level != null){
                    this.loadRate();
                }
            }
        }
    },

    data() {
        return {
            loading: false,
            loading_rate: false,
            section: null,
            date_from: null,
            refresh: 0,
            refresh_cao:0,
            refresh_scale:0,
            refresh_level:0,
            users_args:{
                id_cao_data_main:null,
                scale_value:null,
                date_from:null,
            },
            scale_value: null,
            level: null,
            rate: null,
        };
    },

    methods: {
        addWageData() {

            this.$refs.form.validate().then((result) => {
                if (!result) {
                    return false;
                }else{
                    this.loading = true;
                    axios
                        .post("people/profile/wages/addPayroll", {
                            id_person_data_main: this.args.id_person_data_main,
                            id_cao_data_main: this.section.value,
                            scale_value: this.scale_value.name,
                            level: this.level.name,
                            date_from: this.date_from,
                            filters: JSON.stringify(this.args.filters),
                        })
                        .then((res) => {
                            this.$emit("update:show", false);
                            this.$emit("update:result", 1);
                            this.cReturn.wages.payroll.items = res.data.items;
                            this.loading = false;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                            this.loading = false;
                        });
                    }
            })
        },

        loadRate(){
            this.loading_rate = true;

            axios
                .post('people/profile/wages/getPayrollRate', {
                    date_from: this.date_from,
                    id_cao_data_main: this.section.value,
                    scale: this.scale_value.name,
                    level: this.level.name
                })
                .then((res) => {
                    this.rate = res.data.toString().replace('.', ',');
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_rate = false;
                })
        },
       
        closeModal() {
            this.$emit("update:show", false);
        },

    },
};
</script>

<style scoped>
</style>
