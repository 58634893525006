<template>
    <b-card no-body class="app-card-profile">
        <b-overlay :show="loading" :opacity="0.6">
            
            <div class="w-100 d-flex align-center align-items-center flex-wrap">
                <div class="app-card-tittle">
                    <div>{{trans('schedule',3)}} </div>
                </div>
                <div class="flex-grow-1"></div>
                <div class="d-flex align-center align-items-center flex-wrap justify-content-end gap-15 ml-auto">
                    
                    <div class="d-flex align-center align-items-center ">
                        <div>{{trans('pl-show-registrations',209)}}</div>
                        <b-form-checkbox id="switch_1" v-model="filters.show_registered" switch class="app-switch"/>
                    </div> 

                    <div class="d-flex align-center align-items-center flex-wrap justify-content-end gap-15 ml-auto" style="z-index:1">
                        <app-date-week-selector :listType="2" :onlyWeek="true" :changeArrows="true" :weekDates.sync="filters.week" :key="refresh_component" :clearable="false"/>
                        <app-refresh :refresh.sync="refresh" :loading.sync="loading_list"/>
                    </div>
                    
                </div>
            </div>

        <b-row class="mt-3">
            <b-col>
                <div class="app-planner-container">
                    <div class="app-planner-weeks-header">
                        <planning-planner-timeline-header-component :isWeekHeader="false" :minute_pixel_width.sync="minute_pixel_width" :week="week" v-for="week in planner_properties.weeks" :key="week.index"/>
                    </div> 

                    <div class="app-planner-person-row-profile app-planner-person-row-selected" v-if="plan != null">    
                        <div class="app-planner-person-plan-row-blocks">
                           <planning-planner-blocks-component 
                                                                :person.sync="plan"
                                                                :profile.sync="cProfile"                                                                
                                                                :filters.sync="filters"
                                                                :popover_statistics.sync="popover_week_statistics"
                                                                :hovered_block.sync="hovered_block"
                                                                :edited_availability.sync="edited_availability"
                                                                :week_index="0"
                                                                :dates="planner_properties.weeks[0].dates"
                                                                :minute_pixel_width="minute_pixel_width"
                                                                :read_only="read_only"
                            />
                        </div>             
                    </div>      
                </div>
            </b-col>
        </b-row>

        <b-row class="mt-2">
            <b-col>
                <app-button type="secondary" @click="openUnavailability">Onbeschikbaarheid</app-button>
            </b-col>
        </b-row>        
        </b-overlay>
        <planning-planner-unavailability-component v-if="mUnavailability.show" :show.sync="mUnavailability.show" :args.sync="mUnavailability.args" :result.sync="mUnavailability.result" :return.sync="mUnavailability.return" @close="onCloseUnavailabilityList"/>    
    </b-card>
</template>


<script>
import axios from "@axios";
import moment from "moment";
import PlanningPlannerTimelineHeaderComponent from './Components/PlanningPlannerTimelineHeaderComponent.vue';
import PlanningPlannerBlocksComponent from './Components/PlanningPlannerBlocksComponent.vue';
import PlanningPlannerUnavailabilityComponent from './Components/Unavailability/PlanningPlannerUnavailabilityComponent.vue';


export default {
    name: 'PlanningPlannerPersonProfileComponent',

    components: {
        PlanningPlannerTimelineHeaderComponent,
        PlanningPlannerBlocksComponent,
        PlanningPlannerUnavailabilityComponent
    },

    created(){
        this.getList(); 
        this.onCreateThis();
    },

    computed: {       
        cProfile: {
            get() { return this.profile },
            set(value) { this.$emit('update:profile', value) }
        }
    },

    props:["id_person_data_main", "profile"],
    

    mounted() {
    
    },

    data() {
       return {

             filters : {
                type: 0,
                search: '',
                week: moment().format('YYYY-W'),
                weeks_count: 1,
                office: null,
                show_registered: false
            },

            hovered_block: {
                type: null,
                id: null
            },

            edited_availability: {
                week_index: null,
                item: null,
                id_person_data_main: null
            },

            popover_week_statistics: {
                show: false,
                target: 'popover-week-statistics',
                values: {
                    hours: 0,
                    worked: 0,
                    notavailable: 0,
                    diff: 0
                }
            },
            mUnavailability: { show: false, args: { id_person_data_main: null}, result: 0, return: null},
            minute_pixel_width: null,
            loading: false,
            loading_list: false,
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
            plan: null,
            statistics: {
                total: 0,
                week: [
                    {},
                    {},
                    {},
                    {}
                ]
            },
            
            planner_properties: {
                dates: [],
                weeks: []                
            },

            refresh: 0,
            refresh_component: 0,
            read_only: false
        };
    },
    
    methods: {
        
        getList() {
            if(this.filters.week == null || this.filters.week == ''){
                this.filters.week = moment().format('YYYY-W');
                this.refresh_component++;
            }
            this.loading_list = true;
            axios
                .post("planning/planner/getPlan", {
                    filters: JSON.stringify(this.filters),
                    id_person_data_main: this.id_person_data_main
                })
                .then((res) => {
                    this.plan = res.data.people[0];
                    this.plan.selected = true;
                    this.calculateDatesAndWeeks(res.data.start_date);
                    //this.filters.page = res.data.page;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                        this.loading_list = false;
                })

        },

        calculateDatesAndWeeks(startDate){

            let start_date = moment(startDate, 'YYYY-MM-DD').format('DD-MM-YYYY');
            let start_week = moment(startDate, 'YYYY-MM-DD');

            this.planner_properties.weeks = [];

                let dates = [];
                
            for(let d=0; d<7; d++) {
                start_date = moment(start_date, 'DD-MM-YYYY').isoWeekday(d+1);
                let label = start_date.locale('nl').format('dd');
                label = label.charAt(0).toUpperCase() + label.slice(1);
                
                dates.push({
                                index: d,
                                day_name: label, 
                                short_date: start_date.format('DD-MM'), 
                                date: start_date.format('DD-MM-YYYY'),
                                is_today: start_date.format('DD-MM-YYYY') == moment().format('DD-MM-YYYY'),
                                is_weekend: start_date.isoWeekday() < 6 ? false : true
                            });
                
                
            }
            
            this.planner_properties.weeks.push( {
                                                    index: 0,
                                                    week: start_week.format('YYYY-W'), 
                                                    label: this.trans('week',3) + ' ' + start_week.format('W'),
                                                    dates: dates,
                                                    is_selected: this.selected_week == start_week.format('YYYY-W') ? true : false
                                                });
                
        },

        openUnavailability() {

            this.mUnavailability.args.id_person_data_main = this.id_person_data_main;
            this.mUnavailability.result = 0;
            this.mUnavailability.show = true;
        },

        onCreateThis() { 
            //this.loadSettings();

            this.$watch('refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.edited_availability.id_person_data_main = null;
                    this.edited_availability.item = null;
                    this.edited_availability.week_index = null;
                    
                    this.getList();
                }   
            });

            this.$watch('filters.week', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('filters.show_registered', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });
        },

        onCloseUnavailabilityList(){
            this.getList();
            this.cProfile.refresh++;
        }
        
    },

    
};
</script>

<style scoped>
.gap-15{
    gap: 15px;
}
</style>