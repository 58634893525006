<template>
    <div>
        <b-card no-body class="app-card-profile">
               <b-row class="app-card-header">
                    <b-col >
                        <div class="app-card-profile-title">
                            {{trans('contact-details',182)}}
                        </div>
                    </b-col>
                </b-row>
                        
                <b-row class="mt-3">
                    <b-col md="3" xl="3">
                        <b-form-group :label="trans('phone-number',182)">
                            <b-link :href="'tel:' + phone">
                                <span class="app-local-bold-text"> {{ phone }}</span>
                            </b-link>                          
                        </b-form-group>
                    </b-col>
                    <b-col md="5" xl="5">
                        <b-form-group :label="trans('email',182)">
                            <b-link :href="'mailto:' + this.profile.person_data.email"><span class="app-local-bold-text"> {{this.profile.person_data.email}} </span></b-link>
                        </b-form-group>
                    </b-col>
                    <b-col md="4" xl="4">
                        <b-form-group :label="trans('residence',182)">
                            <span class="app-local-bold-text"> {{this.profile.person_data.city}} </span>
                        </b-form-group>
                    </b-col>
                </b-row>
           
        </b-card>
    </div>
</template>
<script>


export default {

    props:["profile"],

    computed: {
        phone: function () {
            if (this.profile.person_data.phone_number !== null && this.profile.person_data.phone_prefix == '+31') {
                return 0 + this.profile.person_data.phone_number.replace(/^(.{1})(.{3})(.{3})(.*)$/, "$1 $2 $3 $4");
            } else {
                return this.profile.person_data.phone_prefix + " " + this.profile.person_data.phone_number;
            }
        }
    },

    data(){
        return{
        }
    },

};
</script>

<style scoped>
.app-local-bold-text{
    font-weight:700;
    color:#174066;
}
</style>