<template>
    <div>
        <app-view-table :isTitle="false" :loading="cLoading" :isTitleTotal="false" :filters.sync="filters" :items="items" :fields="fields" :isSettings="false">
           
            <template v-slot:menu-items="cell">
                <b-dropdown-item @click="openDocument(cell.data.item)">{{trans('show-document',3)}}</b-dropdown-item>
            </template>
            
        </app-view-table>
     </div>
</template>

<script>
import axios from "@axios";
import AppAlerts from '@core/scripts/AppAlerts';

export default {
    components: { 

    },

    props:["profile", "refresh", "loading"],

    mounted() {
        this.onCreateThis();
    },

    computed: {
        cProfile: {
            get() { return this.profile },
            set() { }
        },

        cRefresh: {
            get() { return this.refresh },
            set(value) {this.$emit('update:refresh', value) }
        },

        cLoading: {
            get() { return this.loading },
            set(value) {this.$emit('update:loading', value) }
        },
    },

    data() {
        
        return {
            alert_class: new AppAlerts(),
            filters: {
                sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                },
                filtering: {},
                page: 1,
                per_page: 5,
                search: "",
                changed: 0,
                type: 3,
            },

            fields: [
                { key: "year", label: this.trans('year',182), thStyle: { "width": "100%" },visible: true },
            ],
            
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
            items: []
        };
    },

    methods: {
        
        getList: function() {
            this.cLoading = true;

            axios
                .post("people/profile/documents/getList", {
                    id_person_data_main: this.profile.id,
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {
                    this.items = res.data.items;
                    this.filters.page = res.data.page;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.cLoading = false;
                });
        },

           openDocument: function(data) {
            
            this.cLoading = true;
            axios
                .post("people/profile/documents/downloadFile", {
                    id_document_data_main: data.id,
                    id_person_data_main: this.profile.id,
                    type: 3,
                }, 
                {
                    responseType: 'blob', 
                    crossDomain: true,
                })
                .then((res) => {

                    const url = window.URL.createObjectURL(new Blob([res.data], {type :  'application/pdf'}));
                    const link = document.createElement('a');
                    link.href = url;
                    link.target= "_blank";
                     data.mine == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                     data.mine == 'application/msword' ||
                     data.mine == 'text/plain'
                                     ? link.setAttribute('download', data.document_name):link.setAttribute('open', data.document_name); 
                    link.setAttribute('open', data.document_name); 
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((error) => {
                    //console.error(error);
                    this.alert_class.openAlertWarning(this.trans('file-not-found',180)).then(res => {})
                })
                .finally(() => {
                   this.cLoading = false;
                });

        },
        
        onCreateThis() { 

            this.getList();

            this.$root.$on('locationsChanged', () => {
        
                if (!this.cLoading) {
                    this.getList();
                } 
            });

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                } 
            });
            this.$watch('refresh', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                }   
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                }   
            });

            this.$watch('filters.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                } 
            });

            this.$watch('profile.documents.items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });

        },

    },
};
</script>

<style scoped>
</style>
