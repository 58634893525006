<template>
    <b-modal scrollable ref="modal-add" size="xl" v-model="computedShow" no-close-on-backdrop :title="trans('fp-riskyacts',172)">
        <ValidationObserver ref="form">

            <b-card class="mb-3" v-if="competences != null && Object.keys(competences).length != 1">
                <b-row v-if="array_functions.includes(args.id_function_data_main) && !loading_competences" class="mb-3">
                    <b-col class="mb-1">
                        <div class="flabel">{{trans('select-risky-actions',172)}}</div>
                        <div class="filters">
                            <b-button class="app-input-min-height app-list-button app-local-btn" v-bind:class="{ 'btn-secondary-grey': !cSelectAll, 'app-list-button-selected':  cSelectAll }" v-on:click="selectAll" id="all">
                                {{trans('select-all',175)}}
                            </b-button>
                            <b-button class="app-input-min-height app-list-button app-local-btn" v-bind:class="{ 'btn-secondary-grey': !item.selected, 'app-list-button-selected':  item.selected }" v-for="item in competences" :key="item.id_competence_const_group" v-on:click="selectOne(item)">
                                {{item.competence_group_name}}
                            </b-button>
                        </div>
                    </b-col>
                </b-row>               
            </b-card>

            <div v-for="item in competences" :key="item.id_competence_const_group">
                <b-card class="mb-3" v-if="item.selected == 1 || (competences != undefined && Object.keys(competences).length == 1)">
                    <app-row-left-label :slots="[6,6]">
                        <template v-slot:col-1>
                            <label class="app-input-top-label">{{item.competence_group_name}}</label>
                        </template>
                        <template v-slot:col-2>
                            <label class="app-input-top-label">{{trans('enter-expiration-date',184)}}</label>
                        </template>
                    </app-row-left-label>

                    <app-row-left-label :slots="[6,6]" v-for="competence in item.competences" :key="competence.id_competence_const_type">
                        <template v-slot:col-1>
                            <app-check-box :id="'checkbox_' + competence.id_competence_const_type.toString()" v-model="competence.selected" class="custom-control-primary mr-4" value="1" unchecked-value="0" size="lg" :rightLabel="competence.competence_name" v-on:change="changeSelected(competence)" :minHeight="true" />
                        </template>
                        <template v-slot:col-2>
                            <app-date v-model="competence.competence_date" :validatorRules="competence.selected == '1' ? 'required|date-check' : '' " :validatorName="competence.competence_name" v-if="competence.selected == '1' " :validatorCustomMessage="{ required: trans('date-required',177) }" :disabledDatesPass="1" />
                        </template>
                    </app-row-left-label>

                </b-card>
            </div>


        </ValidationObserver>
        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="change" :loading="loading || loading_competences">{{trans('save',175)}}</app-button>
        </template>
    </b-modal>
</template>

<script>
import axios from "@axios";
import { ValidationObserver } from "vee-validate";


export default {
    components: {
        ValidationObserver,
    },

    props: ["show", "result", "args", "return"],

    created() {
       this.getData();
    },

    computed: {
        computedShow: {
            get() { return this.show },
            set() { this.$emit('update:show', false) }, 
        },
        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        },
        
        cSelectAll: {
            get() { return Object.keys(this.competences).length == Object.values(this.competences).filter(field => field.selected).length ? true : false},
        }
    },

    data() {
        return {
            loading: false,
            loading_competences: false,
            competences: [],
            array_functions: [8, 9, 10, 11]
        };
    },
   
    methods: {
        getData: function() {
            this.loading_competences = true;
            axios
                .post("people/profile/competence/getData", {
                    id_person_data_main: this.args.id_person_data_main,
                    id_function_data_main: this.args.id_function_data_main,
                    applicant_type: this.args.applicant_type,
                    checked: false
                })
                .then((res) => {  
                    this.competences = res.data;                    
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_competences = false;
                });
        },

        change() {
            this.$refs.form.validate().then((result) => {
                if (!result) { 
                    return false;
                } else{
                    
                    this.loading = true;
                    axios
                        .post("people/profile/competence/change", {
                            id_person_data_main: this.args.id_person_data_main,
                            id_function_data_main: this.args.id_function_data_main,
                            applicant_type: this.args.applicant_type,
                            competences: JSON.stringify(this.competences)
                        })
                        .then((res) => {
                            
                            this.$emit("update:show", false);
                            this.$emit("update:result", 1);

                            this.cReturn.competences = res.data;
                            this.loading = false;

                           
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                            this.loading = false;
                        });
                }
            })
                
        },

        closeModal() {
            this.$emit("update:show", false);
        },

        selectAll(){
            
            if(!this.cSelectAll){
                 Object.keys(this.competences).forEach((item) => {
                    this.competences[item].selected = 1;
                });
                
            }else{
                Object.keys(this.competences).forEach((item) => {
                    this.competences[item].selected = 0;

                    this.competences[item].competences.forEach(competence => {
                        competence.selected = 0;
                        competence.competence_date = null;
                    });

                });

            }
        },

        selectOne(item){
            if(this.cSelectAll){
                item.selected = 0;
               
                item.competences.forEach(competence => {
                    competence.selected = 0;
                    competence.competence_date = null;
                });

            }else if(item.selected == 0){
                item.selected = 1;
            }else if (item.selected == 1){
                item.selected = 0;
            }
            
        },

        changeSelected(item){
            if(item.selected == '0'){
                item.competence_date = null;
            }

        },
    }
}
</script>

<style>

.b-selected {
    filter: brightness(0) invert(1);
}

.app-local-btn{
    margin-top: 10px;
}

</style>
