<template>
    <b-modal ref="modal-add" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('unsubscribe-date',184)">
        <ValidationObserver ref="form" >
            <b-form @submit.prevent="onSubmit">
                <app-row-left-label :label="trans('date',182)">
                    <app-date v-model="finish_date" :loading="loading_data" :disabled="loading_data" validatorName="Datum" :validatorRules="'required|date-check|date-more-than:'+min_date" :disabledDatesRange="disabledDatesRange"/>
                </app-row-left-label>
            </b-form>
        </ValidationObserver>

        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="changeWeek()" :loading="loading">{{trans('save',175)}}</app-button>
        </template>
    </b-modal>
</template>

<script>
import axios from "@axios";
import { ValidationObserver } from "vee-validate";
import moment from 'moment'

export default {
    components: {
        ValidationObserver,
    },

    props: ["show", "result", "args","return"],

    computed: {
        computedShow: {
            get() { return this.show },
            set() { this.$emit('update:show', false) }, 
        },
        cArgs: {
            get() { return this.args },
            set() { this.$emit('update:args', false) }
        },
         cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        }
    },

    created(){
        this.getLastWorkDate();
    },

    data() {
        return {
            loading: false,
            loading_data: false,
            finish_date: null,
            disabledDatesRange:null,
            min_date: null,
        };
    },

    methods: {
        changeWeek() {

            this.$refs.form.validate().then((result) => {
                if (!result) {
                    return false;
                }else{
                    this.loading = true;
                    axios 
                        .post("people/profile/action-points/changeStatus", {
                            id_person_data_main: this.args.id_person_data_main,
                            id_status_const_type: 1002,
                            finish_date: this.finish_date,
                        })
                        .then((res) => {
                            this.$emit("update:show", false);
                            this.$emit("update:result", 1);
                            this.cReturn.person_data = res.data[0];
                            this.loading = false;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                            this.loading = false;
                        });         
                    }
            })
        },

        getLastWorkDate() {

            this.loading_data = true;
            axios 
                .post("people/profile/action-points/getLastWorkDate", {
                    id_person_data_main: this.args.id_person_data_main,
                })
                .then((res) => {
                    if(res.data != 1){
                        this.finish_date = res.data;
                    }else{
                        this.finish_date = moment(new Date()).format('DD-MM-YYYY');
                    }
                    this.min_date = this.finish_date;
                    this.disabledDatesRange = ' (date < new Date('+moment(this.finish_date,"DD-MM-YYYY")+'))';
                    this.loading_data = false;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                });         
        },

        closeModal() {
            this.$emit("update:show", false);
        },

    },
};
</script>

<style scoped>
</style>
