<template>
    <div>
        <b-overlay :show="loading_list" :opacity="0.0">

            <app-view-table :title="trans('contracts',3)" :loading="loading_list" :customColumns="custom_columns" :customColumnsHeaders="custom_headers" :isTitleTotal="false" :filters.sync="filters" :items="cProfile.contracts_employment.contracts.items" :fields="fields">
            
                        
                <template v-slot:menu-items="cell">
                    <b-dropdown-item @click="openDocument(cell.data.item)">{{trans('show-document',3)}}</b-dropdown-item>
                    <b-dropdown-item @click="removeContract(cell.data.item)" v-if="(cell.data.item.status == 0 || cell.data.item.status == 2) && (user_role < 3 || profile.can_user_edit)">{{trans('remove',175)}}</b-dropdown-item>
                </template>

                <template v-slot:status="cell">
                    <span v-if="cell.data.item.status == 0">
                        <b-icon id="popover-id-errors" tabindex="0" icon="exclamation-circle-fill" data-toggle="tooltip" :title="trans('agreement-not-settled',177)"></b-icon>
                    </span>
                    <span v-if="cell.data.item.status == 2">
                        <b-icon :id="'popover-id-errors'+cell.data.item.id_contract_data_main" tabindex="0" icon="exclamation-circle-fill" class="app-icon-warning"></b-icon>
                    </span>
                    <b-popover :target="'popover-id-errors'+cell.data.item.id_contract_data_main" triggers="focus" no-fade>
                        <template #title>
                            {{trans('fp-rejected-by-employee',172)}}
                        </template>
                        {{trans('fp-reject-reason',172)}} {{cell.data.item.description}}
                    </b-popover>
                </template>

                <template v-slot:head_hour_wage>
                    <span v-if="[1,3].includes(profile.person_data.applicant_type)">{{trans('hour-wage',3)}}</span>
                    <span v-if="profile.person_data.applicant_type == 2">{{trans('hourrage',184)}}</span>
                </template>
            
                <template v-slot:buttons>
                    <app-button type="secondary" v-if="(profile.person_data.register_status == 2 || (profile.person_data.register_status == 1 && profile.person_data.id_status_const_type == 4)) && (user_role < 3 || profile.can_user_edit)" :disabled="profile.compliance_check.correct == 0" @click="addContract()" >{{trans('create-contract',175)}}</app-button>
                </template>
            </app-view-table>

            <template #overlay>
                <div></div>
            </template>
        </b-overlay>

        <person-profile-contracts-add-step-1 v-if="mContractAddStep1.show" :show.sync="mContractAddStep1.show" :result.sync="mContractAddStep1.result" :args="mContractAddStep1.args" :return.sync="mContractAddStep1.return"/>
        <person-profile-contracts-add-step-2 v-if="mContractAddStep2.show" :show.sync="mContractAddStep2.show" :result.sync="mContractAddStep2.result" :args.sync="mContractAddStep2.args" :return.sync="profile" />
    </div>
</template>

<script>

import axios from "@axios";
import AppAlerts from '@core/scripts/AppAlerts';
import PersonProfileContractsAddStep2 from '@app/People/Profile/ContractsEmployment/Contracts/PersonProfileContractsAddStep2.vue';
import PersonProfileContractsAddStep1 from '@app/People/Profile/ContractsEmployment/Contracts/PersonProfileContractsAddStep1.vue';

export default {
    components: { 
        PersonProfileContractsAddStep2,
        PersonProfileContractsAddStep1,
    },

    props:["profile"],

    created(){
        this.getList();
    },

    mounted() {
        this.onCreateThis();
    },

    computed: {
        cProfile: {
            get() { return this.profile },
            set(value) { this.$emit('update:profile', value) }
        }
    },

    data() {
        return {
            loading_open_file: false,
            custom_columns: ["status"],
            custom_headers: ["hour_wage"],
            filters: {
                sorting: {
                        field_name: "date_from",
                        sort_direction: 1,
                        changed: 0
                },
                page: 1,
                per_page: 3,
                search: "",
                list_type: 1,
                changed: 0
            },
            fields: [
                { key: "name", label: this.trans('contract-type',184), thStyle: { "min-width": "150px", "width": "100%" }, visible:true, filtering: false },
                { key: "date_from", label: this.trans('date-from',182), thStyle: { "min-width": "170px", "max-width": "170px" },visible:true, filtering: false },
                { key: "date_to", label: this.trans('date-to',182), thStyle: { "min-width": "160px", "max-width": "160px" }, visible:  [1,3].includes(this.profile.person_data.applicant_type) ? true : false, filtering: false },
                { key: "hour_wage", label: this.trans('hour-wage',3), thStyle: { "min-width": "100px", "max-width": "100px" }, visible: [1,3].includes(this.profile.person_data.applicant_type) ? true : false, filtering: false },
                { key: "contract_number", label: this.trans('number',182), thStyle: { "min-width": "95px", "max-width": "95px" }, visible:true, filtering: false },
                { key: "status", label: "", thStyle: { "min-width": "38px", "max-width": "38px" },visible:true, filtering: false },
            ],
            loading_list: false,
            alert_class: new AppAlerts(),
            mContractAddStep1: {show: false, args: {applicant_type : null}, result: 0, return: {id_document_data_version: 0, id_document_const_type: 0}},
            mContractAddStep2: {show: false, args: {id_document_data_version: 0, id_document_const_type: 0, faseb_contracts: null, finish_date: null}, result: 0, return: this.profile},
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
        };
    },

    watch: {
        "mContractAddStep1.result": function(val) {
            if (val === 1) {
                this.mContractAddStep2.args.id_document_data_version = this.mContractAddStep1.return.id_document_data_version;
                this.mContractAddStep2.args.id_document_const_type = this.mContractAddStep1.return.id_document_const_type;
                this.mContractAddStep2.args.applicant_type = this.profile.person_data.applicant_type;
                this.mContractAddStep2.args.id_person_data_main = this.profile.id;
                this.mContractAddStep2.args.faseb_contracts = this.profile.faseb_contracts;
                this.mContractAddStep2.args.finish_date = this.profile.person_data.finish_date;
                this.mContractAddStep2.show = true;
                this.mContractAddStep1.result = 0;
            }
        },

        "mContractAddStep2.result": function(val){
            if(val === 1){
                this.getList();
            }
        }
    },

    methods: {
        getList: function() {
            this.loading_list = true;
            axios
                .post("people/profile/contracts/getList", {
                    id_person_data_main: this.profile.id,
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {
                    this.cProfile.contracts_employment.contracts.items = res.data.items;
                    this.filters.page = res.data.page;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_list = false;
                });
        }, 

        openDocument: function(data) {
            
            if(data.document_status == 0 || data.document_status == 1 || data.document_status == 3){//0 - zawsze wysweitlamy (dokumenty H1), 1 - zaimportowany można otworzyć

                this.loading_list = true;

                axios
                    .post("people/profile/documents/downloadFile", {
                        id_document_data_main: data.id_document_data_main,
                        id_person_data_main: this.profile.id,
                        type: 1,
                    }, 
                    {
                        responseType: 'blob', 
                        crossDomain: true,
                    })
                    .then((res) => {
                        const url = window.URL.createObjectURL(new Blob([res.data], {type : data.mine_type}));
                        const link = document.createElement('a');
                        link.href = url;
                        link.target= "_blank";
                        data.mine_type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                        data.mine_type == 'application/msword' ||
                        data.mine_type == 'text/plain'
                                        ? link.setAttribute('download', data.document_name):link.setAttribute('open', data.document_name); 
                        link.setAttribute('open', data.document_name); 
                        document.body.appendChild(link);
                        link.click();
                    })
                    .catch((error) => {
                        this.alert_class.openAlertWarning(this.trans('file-not-found',180)).then(res => {})
                        console.error(error);
                    })
                    .finally(() => {
                    this.loading_list = false;
                    });

            }else if(data.document_status == 2){//3 - nie mozemy otworzyc pliku, nie ma powiązania
                this.alert_class.openAlertWarning(this.trans('fp-cannot-open-file-error',177)).then(res => {});
            }

        },

        removeContract(data){
            
            this.alert_class.openAlertConfirmation(this.trans('withdraw-contract-confirm',177)).then(res => {
            if(res == true) {

                this.loading_list = true;

                axios
                    .post("people/profile/contracts/removeContract", {
                        id_person_data_main: this.profile.id,
                        id_contract_data_main: data.id_contract_data_main,
                        filters: JSON.stringify(this.filters),
                    })
                    .then((res) => {
                        this.cProfile.contracts_employment.contracts.items = res.data.contracts.items;
                        this.cProfile.person_data = res.data.person_data;
                        this.filters.page = res.data.contracts.page;
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    })
                    .finally(() => {
                        this.loading_list = false;
                    });

                }
            })


        },

        onCreateThis() { 
            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });
            this.$watch('profile.contracts_employment.contracts.items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('filters.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });
        },


        addContract() {
            this.mContractAddStep1.args.applicant_type = this.profile.person_data.applicant_type;           
            this.mContractAddStep1.show = true;
        }

    },
};
</script>

<style scoped>
.vs__selected-options {
    font-size:9px;
}

.app-icon-warning{
    color: #f04438;
}

</style>
