<template>
  <div>    
        <b-overlay :show="loading_list" :opacity="0.0">
           
                <app-view-table :title="trans('fp-payfrequency',172)" :loading="loading_list" :isTitleTotal="false" :filters.sync="filters" :items="cProfile.loon_wages.period.items" :isMenuColumn="user_role < 3 || profile.can_user_edit" :isDropdownMenu="profile.person_data.id_status_const_type != 1002" :fields="fields">
                
                            
                    <template v-slot:menu-items="cell">
                       <b-dropdown-item @click="deleteItem(cell.data.item)">{{trans('remove',175)}}</b-dropdown-item>
                    </template>

                
                    <template v-slot:buttons>
                       <app-button type="secondary" v-if="profile.person_data.id_status_const_type > 2 && (user_role < 3 || profile.can_user_edit)" @click="addItem" >
                            <span>{{trans('change-frequency',174)}}</span>
                        </app-button>
                    </template>
                </app-view-table>
           
            <template #overlay>
                <div></div>
            </template>
        </b-overlay>

    <people-profile-loon-wages-period-add v-if="mAdd.show" :show.sync="mAdd.show" :result.sync="mAdd.result" :args.sync="mAdd.args" :return.sync="profile"/>

</div>
</template>

<script>


import AppAlerts from '@core/scripts/AppAlerts';
import axios from "@axios";
import PeopleProfileLoonWagesPeriodAdd from './PeopleProfileLoonWagesPeriodAdd.vue';
import moment from "moment";

export default {
    components: { 
        PeopleProfileLoonWagesPeriodAdd
    },

    props:["profile"],

    mounted() {
        this.onCreateThis();
    },

    computed: {
        cProfile: {
            get() { return this.profile },
            set(value) { this.$emit('update:profile', value) }
        }
    },

    data() {
            return {
                    alert_class: new AppAlerts(),
                    loading_list: false,
                    filters: {
                                page: 1,
                                per_page: 3,
                                changed:0,
                                sorting: {
                                        field_name: null,
                                        sort_direction: 0,
                                        changed: 0
                                },
                    },
                    fields: [
                                { key: "date_from", label: this.trans('entry-date',182), thStyle: {"min-width": "150px", "max-width": "200px"}, visible: true},
                                { key: "period", label: this.trans('salary-label',184), thStyle: {"min-width": "100px", "width":"100%" }, visible: true },
                    ],
                    mAdd: { show: false, args: {id_person_data_main: null, filters: null}, result: 0, return: "profile" },
                    user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,        
            }
    },

    methods: {
        setFormatDate(dateValue) {
           return moment(dateValue).format('DD-MM-YYYY');
        },
        
        getList: function() {
            this.loading_list = true;
            axios
                .post("people/profile/wage-details/getPeriod", {
                    id_person_data_main: this.profile.id,
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {
                    this.filters.page = res.data.page;
                    this.cProfile.loon_wages.period.items = res.data.items;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_list = false;
                });; 
        },

        deleteItem(data){
            this.alert_class.openAlertConfirmation(this.trans('delete-line-confirm',177)).then(res => {
                if(res == true){
                    this.loading_list = true;
                    axios
                        .post("people/profile/wage-details/deletePeriod", {
                            id_wage_data_period: data.id,
                            id_person_data_main: this.profile.id,
                            filters: JSON.stringify(this.filters),
                        })
                        .then((res) => {
                            this.filters.page = res.data.loon_wages.page;
                            this.cProfile.loon_wages.period.items = res.data.loon_wages.items;
                            this.cProfile.person_data = res.data.person_data;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            this.loading_list = false;
                        });; 
                    }     
            }); 
        },
        
        addItem() {
            this.mAdd.args.id_person_data_main = this.profile.id;
            this.mAdd.args.filters = this.filters;
            this.mAdd.show = true;
        }, 

        onCreateThis() { 

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });
            this.$watch('profile.loon_wages.period.items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('filters.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });
            

        }
        
    }

};
</script>

<style scoped>

</style>
