<template>
    <div>
        <b-row v-if="profile.person_data.id_status_const_type > 2 && (user_role < 3 || profile.can_user_edit) && profile.person_data.id_status_const_type != 1002" class="app-component-icon-edit">
            <b-dropdown variant="link"  no-caret right>
                <template  #button-content>
                    <b-icon icon="three-dots-vertical" class="text-body"></b-icon>
                </template>
                <b-dropdown-item @click="change()">{{trans('change',175)}}</b-dropdown-item>
            </b-dropdown>
        </b-row>      
            <b-row class="app-card-header">
                <b-col start>
                    <p class="col-list-label">{{trans('branch',182)}}</p>
                    <p class="col-list-label">{{trans('recruiter',182)}}</p>
                    <p class="col-list-label">{{trans('consultant',184)}}</p>
                    <p class="col-list-label" v-if="profile.person_data.applicant_type < 4">{{(profile.person_data.easyflex_source == 1 || (profile.person_data.easyflex_source == null && (profile.person_data.applicant_type == 1 || profile.person_data.applicant_type == 3))) ? trans('fp-ef-uzk',273) : trans('fp-ef-zzp',273)}}</p>
                    <p class="col-list-label">{{trans('fp-registration',184)}}</p>
                </b-col>
                <b-col>
                    <p class="col-form-value">{{profile.person_data.location_name ? profile.person_data.location_name : '-'}}</p>
                    <p class="col-form-value">{{profile.person_data.recruiter_name ? profile.person_data.recruiter_name : '-'}}</p>
                    <p class="col-form-value">{{profile.person_data.consultant_name ? profile.person_data.consultant_name : '-'}}</p>
                    <p class="col-form-value" v-if="profile.person_data.applicant_type < 4">{{profile.person_data.id_easyflex ? profile.person_data.id_easyflex : '-'}}</p>
                    <p class="col-form-value">{{profile.person_data.entry_date ? profile.person_data.entry_date : '-'}}</p>
                </b-col>
            </b-row>      

        <people-profile-registration-change v-if="mRegistrationChange.show" :show.sync="mRegistrationChange.show" :result.sync="mRegistrationChange.result" :args.sync="mRegistrationChange.args" :return.sync="profile"/>
    </div>
</template>
<script>

import PeopleProfileRegistrationChange from "./PeopleProfileRegistrationChange.vue";


export default {

    components: {
        PeopleProfileRegistrationChange
    },

    props:["profile"],

    data(){
        return{
            mRegistrationChange: { show: false, args: {
                id_person_data_main: null,
                recruiter: null,
                consultant:null,
                previous_applicant_type: null,
                location:null
            }, result: 0, return: null },
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
        }
    },

    methods:{
       
        change(){
            this.mRegistrationChange.args.id_person_data_main = this.profile.id;
            this.mRegistrationChange.args.recruiter = {name: this.profile.person_data.recruiter_name_list, value: this.profile.person_data.recruiter_id};
            this.mRegistrationChange.args.consultant = {name: this.profile.person_data.consultant_name_list, value: this.profile.person_data.consultant_id};
            this.mRegistrationChange.args.location = this.profile.person_data.id_office_data_location ? {name: this.profile.person_data.location_name, value: this.profile.person_data.id_office_data_location, users: this.profile.person_data.location_users, student_recruitment: this.profile.person_data.student_recruitment} : null;
            this.mRegistrationChange.args.previous_applicant_type = this.profile.person_data.applicant_type;
            this.mRegistrationChange.show = true;
        }
    }
 
};
</script>

<style scoped>

</style>