<template>
    <b-modal ref="modal-shift" size="sm" v-model="computedShow" no-close-on-backdrop title="Dienst">
        
        <b-overlay :show="loading" rounded="sm" blur="2px">
            <b-card class="mt-2" style="min-height: 200px;">
                <div v-if="!loading">
                    <div class="d-flex justify-content-between"> <div><b-badge variant="secondary" class="mr-4">Dienst</b-badge> <b-badge :variant="cVariant">{{shift_data.status_name}}</b-badge></div> <b-icon icon="sun" class="mr-2"></b-icon></div> <br />
                    <div class="d-flex justify-content-between"> <b class="ml-2 mb-0">{{getDate()}}</b></div>

                    <hr />
                    <b-icon icon="building" class="mr-2"></b-icon> {{shift_data.office_name}}, {{shift_data.department_name}} <br />
                    <b-icon icon="people" class="mr-2"></b-icon> {{shift_data.function_name}} <br /> 
                    <b-icon icon="geo-alt" class="mr-2"></b-icon> {{shift_data.location_street}} {{shift_data.location_house_number}} <br />
                    <b-icon icon="telephone" class="mr-2"></b-icon> {{shift_data.location_phone_number}} <br />
                </div>
            </b-card>
        </b-overlay>

        <template #modal-footer>

                    <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            </template>
    </b-modal>
</template>
<script>

import axios from "@axios";
import moment from "moment";

export default {

    props: {
        id: {type: Number},
        show: {type: Boolean},
        idPersonDataMain: {type: String},
    },

    components: {

    },

    computed: {
        cVariant: {
            get(){
                if(this.shift_data.item_status == 3){
                    return 'success'
                } else if(this.shift_data.item_status == 2){
                    return 'warning'
                } else if(this.shift_data.item_status == 4){
                    return 'danger'
                } else {
                    return 'secondary'
                }
            }
        },

        computedShow: {
        get() { return this.show },
        set(value) { this.$emit('update:show', value) }, 
        },
    },

    created(){
        this.getData();
    },

    data(){
        return {
            loading: false,
            shift_data: null
        }
    },

    methods: {

        getData(){
            this.loading = true;

            axios
                .post('people/profile/mobile/getItemDetails',{
                    id_shift_data_main: this.id,
                    id_person_data_main: this.idPersonDataMain
                })
                .then((res) => {
                    this.shift_data = res.data;
                })
                .catch((error) => {
                    console.error(`error: ${error}`);
                })
                .finally(() => {
                    this.loading = false;
                })

        },

        getDate(){
            var date =  moment(this.shift_data.date_from, 'YYYY-MM-DD');

            return date.format('D') + ' ' + date.format('MMMM') + ', ' + this.shift_data.time_from + ' - ' + this.shift_data.time_to;
        },

        closeModal() {
            this.$emit("update:show", false);
        },

    }
    
}
</script>
<style scoped>
.app-local-back:hover{
    cursor: pointer;
    color:#8EC1F0;
}
</style>