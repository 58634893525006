<template>
    <div>
        <b-overlay :show="loading_list" :opacity="0.0">
                <app-view-table :title="trans('fp-prefered-rate',172)" :loading="loading_list" :isTitleTotal="false" :filters.sync="filters" :customColumns="customColumns" :items="cProfile.wages.independent.items" :isMenuColumn="user_role < 3 || profile.can_user_edit" :isDropdownMenu="profile.person_data.id_status_const_type != 1002" :fields="fields">
                    
                    <template v-slot:shift_work="cell">
                        <span data-toggle="tooltip" :title="cell.data.item.shift_work ? cell.data.item.shift_work.substring(0, cell.data.item.shift_work.length - 2) : ''">{{cell.data.item.shift_work ? cell.data.item.shift_work.substring(0, cell.data.item.shift_work.length - 2) : ''}} </span>
                    </template>

                    <template v-slot:menu-items="cell">
                        <b-dropdown-item @click="changeItem(cell.data.item)">{{trans('change',175)}}</b-dropdown-item>
                        <b-dropdown-item @click="deleteItem(cell.data.item)">{{trans('remove',175)}}</b-dropdown-item>
                    </template>
                
                    <template v-slot:buttons>
                        <app-button type="secondary" v-if="profile.person_data.id_status_const_type > 2 && (user_role < 3 || profile.can_user_edit) && profile.person_data.id_status_const_type != 1002" @click="addItem" >
                            <span>Standaard tarief toevoegen</span>
                        </app-button>
                    </template>
                </app-view-table>

            <template #overlay>
                <div></div>
            </template>
        </b-overlay>
        <people-profile-wage-independent-add v-if="mAdd.show" :show.sync="mAdd.show" :args.sync="mAdd.args" :result.sync="mAdd.result" :return.sync="profile" />
        <people-profile-wage-independent-change v-if="mChange.show" :show.sync="mChange.show" :args.sync="mChange.args" :result.sync="mChange.result" :return.sync="profile" />
    </div>
</template>
<script>

import axios from "@axios";
import moment from "moment";
import AppAlerts from '@core/scripts/AppAlerts';
import PeopleProfileWageIndependentAdd from './PeopleProfileWageIndependentAdd.vue';
import PeopleProfileWageIndependentChange from './PeopleProfileWageIndependentChange.vue'

export default {

    components: {
        PeopleProfileWageIndependentAdd,
        PeopleProfileWageIndependentChange,

    },
    props: ["profile"],

    created(){
        this.getList();
    },

    watch: {
        "filters.sorting.changed": {
            handler: function(value){
                if(!this.loading_list) {
                    this.getList();
                }
            }
        },

        "filters.page": {
            handler: function(value) {
                if(!this.loading_list) {
                    this.getList();
                }
            }
        },

        "filters.changed": {
            handler: function(value) {
                if(!this.loading_list) {
                    this.getList();
                }
            }
        },
        "profile.wages.independent.items.refresh": {
            handler: function(value) {
                if(!this.loading_list) {
                    this.getList();
                }
            }
        }
    },


    computed: {
        cProfile: {
            get() { return this.profile },
            set(value) { this.$emit('update:profile', value) }
        }
    },

    data(){
        return{
            customColumns: ["shift_work"],
            loading_list: false,
            alert_class: new AppAlerts(),
            filters: {
                        page: 1,
                        per_page: 3,
                        changed:0,
                        sorting: {
                                field_name: null,
                                sort_direction: 0,
                                changed: 0
                                },
            },
            fields: [
                { key: "date_from", label: this.trans('date-from',182), thStyle: {"min-width": "120px", "max-width": "120px"}, visible: true },
                { key: "function_name", label:  this.trans('function',3), thStyle: { "width": "100%"}, visible: true, sortable: false},
                { key: "rate_day", label:  this.trans('day-part-day',182), thStyle: { "min-width": "70px", "max-width": "70px"}, visible: true, sortable: false},
                { key: "rate_evening", label:  this.trans('day-part-evening',182), thStyle: { "min-width": "70px", "max-width": "70px"}, visible: true, sortable: false},
                { key: "rate_night", label:  this.trans('day-part-night',182), thStyle: { "min-width": "70px", "max-width": "70px"}, visible: true, sortable: false},
                { key: "rate_weekend", label:  this.trans('weekend',192), thStyle: { "min-width": "70px", "max-width": "70px"}, visible: true, sortable: false},
                { key: "rate_saturday", label:  this.trans('saturday',182), thStyle: { "min-width": "70px", "max-width": "70px"}, visible: true, sortable: false},
                { key: "rate_sunday", label:  this.trans('sunday',182), thStyle: { "min-width": "70px", "max-width": "70px"}, visible: true, sortable: false},
                { key: "rate_sleep_service", label:  this.trans('sleeping-service',182), thStyle: { "min-width": "70px", "max-width": "70px"}, visible: true, sortable: false},
                { key: "rate_24_hours", label:  this.trans('24-hour-care',182), thStyle: { "min-width": "70px", "max-width": "70px"}, visible: true, sortable: false},
                { key: "rate_holidays", label:  this.trans('holidays',190), thStyle: { "min-width": "70px", "max-width": "70px"}, visible: true, sortable: false},
            ],
            mAdd: { show: false, args:{ id_person_data_main: this.profile.person_data.id_person_data_main,
                                        function: null,
                                        filters: null
                                    }, result: 0, return: null },
            mChange: { show: false, args: {
                                    id_wage_data_independent: null, 
                                    id_person_data_main: this.profile.person_data.id_person_data_main, 
                                    date_from: null,
                                    filters: null,
                                    function: null,
                                    rates_options:{
                                        rate_day: null,
                                        rate_evening: null,
                                        rate_night: null,
                                        rate_weekend: null,
                                        rate_saturday: null,
                                        rate_sunday: null,
                                        rate_sleep_service: null,
                                        rate_24_hours: null,
                                        rate_holidays: null,
                                    }
                                }, result: 0, return: null },
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
        }
    },

    methods: {

        getList(){
            this.loading_list = true;

            axios   
                .post('people/profile/wages/getIndependentList', {
                    id_person_data_main: this.profile.person_data.id_person_data_main,
                    filters: JSON.stringify(this.filters)
                })
                .then((res) => {
                    this.cProfile.wages.independent.items = res.data.items,
                    this.filters.page = res.data.page
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_list = false;
                });
        },

        addItem(){
            this.mAdd.args.filters = this.filters;
            this.mAdd.args.function = {name: this.profile.person_data.function_name, value: this.profile.person_data.id_function_data_main};
            this.mAdd.show = true;
        },

        changeItem(item){
            this.mChange.args.id_wage_data_independent = item.id_wage_data_independent;
            this.mChange.args.date_from = item.date_from;
            this.mChange.args.function = {name: item.function_name, value: item.id_function_data_main};
            this.mChange.args.filters = this.filters;
            this.mChange.args.rates_options.rate_day = item.rate_day;
            this.mChange.args.rates_options.rate_evening = item.rate_evening;
            this.mChange.args.rates_options.rate_night = item.rate_night;
            this.mChange.args.rates_options.rate_weekend = item.rate_weekend;
            this.mChange.args.rates_options.rate_saturday = item.rate_saturday;
            this.mChange.args.rates_options.rate_sunday = item.rate_sunday;
            this.mChange.args.rates_options.rate_sleep_service = item.rate_sleep_service;
            this.mChange.args.rates_options.rate_24_hours = item.rate_24_hours;
            this.mChange.args.rates_options.rate_holidays = item.rate_holidays;
            this.mChange.show = true;
        },

        deleteItem(item){

            this.alert_class.openAlertConfirmation(this.trans('confirm-message',180)).then(res => {
                if(res == true) {

                    this.loading_list = true;
                    axios
                        .post('people/profile/wages/deleteIndependent', {
                            id_wage_data_independent: item.id_wage_data_independent,
                            id_person_data_main: this.profile.person_data.id_person_data_main,
                            filters: JSON.stringify(this.filters)
                        })
                        .then((res) => {
                            this.cProfile.wages.independent.items = res.data.items;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            this.loading_list = false;
                        })

                }
            });


        }
    }
    
}
</script>
<style>

</style>