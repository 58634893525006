<template>
    <div>
        <b-row class="app-component-icon-edit" v-if="profile.person_data.id_status_const_type > 2 && (user_role < 3 || profile.can_user_edit) && profile.person_data.id_status_const_type != 1002">
            <b-dropdown  variant="link"  no-caret right>
                <template  #button-content>
                    <b-icon icon="three-dots-vertical" class="text-body"></b-icon>
                </template>
                <b-dropdown-item @click="change">{{trans('change',175)}}</b-dropdown-item>
                <!-- <b-dropdown-item @click="getOpenShifts">{{trans('fp-open-shifts', 172)}}</b-dropdown-item> -->
                <b-dropdown-item :to="{ name: 'people-profile-mobile', params: { id_person_data_main: profile.person_data.id_person_data_main }}"  v-if="user_role == 1 && profile.person_data.id_user_portal != null">{{trans('fp-mobile',172)}}</b-dropdown-item>
            </b-dropdown>
        </b-row>

        <b-row class="app-card-header">
            <b-col start>
                <p class="col-list-label">{{trans('hours-per-week',184)}}</p>
                <p class="col-list-label">{{trans('day-part',172)}}</p>
                <p class="col-list-label">{{trans('fp-sector',172)}}</p>
                <p class="col-list-label">{{trans('radius',184)}}</p>
                <p class="col-list-label">{{trans('period-assignment',184)}}</p>
            </b-col>
            <b-col>
                <p class="col-form-value">{{plan_preferences.hour_per_week ? plan_preferences.hour_per_week : '-'}}</p>
                <p class="col-form-value">{{availabilty_label ? availabilty_label : '-'}}</p>
                <p class="col-form-value">{{sector_label ? sector_label : '-'}}</p>
                <p class="col-form-value">{{plan_preferences.working_area.radius ? plan_preferences.working_area.radius : '-'}}</p>
                <p class="col-form-value">{{plan_preferences.assigments_allow != null ? (plan_preferences.assigments_allow == 1 ? this.trans('no-option',3) : this.trans('yes-option',3))  : '-'}}</p>
            </b-col>
        </b-row>
        <b-row class="mt-3">
            <b-col cols="12">
                <b-card class="app-local-padding">
                    <GmapMap
                        :center="center"
                        :zoom="cZoom"
                        map-type-id="terrain"
                        style="height: 250px"
                        :options="{
                        zoomControl: false,
                        mapTypeControl: false,
                        styles: styles,
                        scaleControl: false,
                        streetViewControl: false,
                        rotateControl: false,
                        fullscreenControl: false,
                        disableDefaultUI: false,
                        scrollwheel: false,
                        disableDoubleClickZoom: true,
                        draggingCursor: false,
                        draggable: false,
                        keyboardShortcuts: false,
                        clickableIcons: false
                        }"
                    >
                        <GmapMarker
                            v-if="marker != null"
                            :position="marker"
                            :clickable="false"
                            :draggable="false"
                            :icon="{url: require('assets/images/icons/marker.png'), anchor: point}"
                        />

                       

                        <GmapCircle
                            v-if="plan_preferences.working_area.radius && marker != null"
                            :center="marker != null ? marker : center"
                            :radius="plan_preferences.working_area.radius*1000"
                            :visible="true"
                            :options="{
                                fillColor:'#51b5e0',
                                strokeColor: '#174066',
                                strokeOpacity: 0.8,
                                strokeWeight: 0,
                                fillOpacity: 0.5,
                                clickable: false
                                }"
                            :clickable="false"
                        ></GmapCircle>
                    </GmapMap>
                </b-card>
            </b-col>
        </b-row>

        <people-profile-plan-preferences-change v-if="mChange.show" :show.sync="mChange.show" :args.sync="mChange.args" :result.sync="mChange.result" :return.sync="profile" />
        <people-profile-plan-preferences-shifts v-if="mShifts.show" :show.sync="mShifts.show" :args.sync="mShifts.args" :result.sync="mShifts.result" :return.sync="mShifts.return" />
        <people-profile-plan-preferences-my-shifts v-if="mMyShifts.show" :show.sync="mMyShifts.show" :args.sync="mMyShifts.args" :result.sync="mMyShifts.result" :return.sync="mMyShifts.return" />
    </div>
</template>
<script>

import PeopleProfilePlanPreferencesChange from './PeopleProfilePlanPreferencesChange.vue';
import PeopleProfilePlanPreferencesShifts from './PeopleProfilePlanPreferencesShifts.vue';
import PeopleProfilePlanPreferencesMyShifts from './PeopleProfilePlanPreferencesMyShifts.vue';

export default {
    props: ["profile"],

    components: {
        PeopleProfilePlanPreferencesChange,
        PeopleProfilePlanPreferencesShifts,
        PeopleProfilePlanPreferencesMyShifts
    },

    created(){
        this.parseData();
        this.$gmapApiPromiseLazy().then(() => {
            this.point = new window.google.maps.Point(10,10);
        });
    },

    computed: {
        cZoom: {
            get() { return (0 - this.plan_preferences.working_area.radius) * (6 - 9.3) / (0 - 150) + 9; }
        }
    },

    watch: {
        "mChange.result": {
            handler: function(val){
                if(val > 0){
                    this.parseData();
                    this.mChange.result = 0;
                }
            }
        },
        "profile.person_data":{
            handler: function(){
                this.parseData();
            },
            deep:true
        }
    },

    data(){
        return{
            plan_preferences: null,
            availabilty_label: null,
            sector_label: null,
            mChange: { show:false, args: {
                plan_preferences: {
                    hour_per_week: null,
                    ids_tod_const_type: [],
                    ids_sector_const_type: null,
                    working_area: {
                        radius: null,
                        lng: null,
                        lat: null
                    },
                    assigments_allow: null
                },
                address: {
                    lng: null,
                    lat: null
                },
                id_person_data_main: null
            }, result: 0, return: null},
            mShifts: {show: false, args: {id_person_data_main: null, plan_preferences: null, sectors: null, tod: null, }, result: 0, return: null },
            mMyShifts: {show: false, args: {id_person_data_main: null}, result: 0, return: null },
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
            center: {lat: null, lng: null},
            marker: null,
            marker_address: null,
            point: null,

    // other properties... 
            styles: [
                        {
                            "elementType": "geometry",
                            "stylers": [
                            {
                                "color": "#f5f5f5"
                            }
                            ]
                        },
                        {
                            "elementType": "labels.text.fill",
                            "stylers": [
                            {
                                "color": "#616161"
                            }
                            ]
                        },
                        {
                            "elementType": "labels.text.stroke",
                            "stylers": [
                            {
                                "color": "#f5f5f5"
                            }
                            ]
                        },
                        {
                            "featureType": "administrative.land_parcel",
                            "elementType": "labels.text.fill",
                            "stylers": [
                            {
                                "color": "#bdbdbd"
                            }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "geometry",
                            "stylers": [
                            {
                                "color": "#eeeeee"
                            }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "labels.text.fill",
                            "stylers": [
                            {
                                "color": "#757575"
                            }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "geometry",
                            "stylers": [
                            {
                                "color": "#e5e5e5"
                            }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "labels.text.fill",
                            "stylers": [
                            {
                                "color": "#9e9e9e"
                            }
                            ]
                        },
                        {
                            "featureType": "road",
                            "elementType": "geometry",
                            "stylers": [
                            {
                                "color": "#ffffff"
                            }
                            ]
                        },
                        {
                            "featureType": "road.arterial",
                            "elementType": "labels.text.fill",
                            "stylers": [
                            {
                                "color": "#757575"
                            }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "geometry",
                            "stylers": [
                            {
                                "color": "#dadada"
                            }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "labels.text.fill",
                            "stylers": [
                            {
                                "color": "#616161"
                            }
                            ]
                        },
                        {
                            "featureType": "road.local",
                            "elementType": "labels.text.fill",
                            "stylers": [
                            {
                                "color": "#9e9e9e"
                            }
                            ]
                        },
                        {
                            "featureType": "transit.line",
                            "elementType": "geometry",
                            "stylers": [
                            {
                                "color": "#e5e5e5"
                            }
                            ]
                        },
                        {
                            "featureType": "transit.station",
                            "elementType": "geometry",
                            "stylers": [
                            {
                                "color": "#eeeeee"
                            }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "geometry",
                            "stylers": [
                            {
                                "color": "#c9c9c9"
                            }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "labels.text.fill",
                            "stylers": [
                            {
                                "color": "#9e9e9e"
                            }
                            ]
                        }
                    ]
            
        }
    },

    methods: {

        parseData(){
            this.plan_preferences = JSON.parse(this.profile.person_data.plan_preferences);
            this.availabilty_label = null;

            var availabilty = [];

            if(this.plan_preferences.ids_tod_const_type.includes(1)) availabilty.push(this.trans('day-part-day',182));
            if(this.plan_preferences.ids_tod_const_type.includes(2)) availabilty.push(this.trans('day-part-evening',182));
            if(this.plan_preferences.ids_tod_const_type.includes(3)) availabilty.push(this.trans('day-part-night',182));
            

            this.availabilty_label = availabilty.join(', ');
            
            this.sector_label = null;
            var sectors = [this.trans('sector-disability-care',172), this.trans('sector-nursing-homes-and-homecare',172), this.trans('sector-mental-health-care',172), this.trans('sector-hospitals',172), this.trans('sector-other',172)];
            var s = [];
            if(this.plan_preferences.ids_sector_const_type.length > 0){
                this.plan_preferences.ids_sector_const_type.forEach(element => {
                    s.push(sectors[element-1]);
                });

                this.sector_label = s.join(', ');
            }

            if(this.plan_preferences.working_area.lat != null && this.plan_preferences.working_area.lng != null){
                this.marker = {lat: parseFloat(this.plan_preferences.working_area.lat), lng: parseFloat(this.plan_preferences.working_area.lng)};
                this.center = {lat: parseFloat(this.plan_preferences.working_area.lat), lng: parseFloat(this.plan_preferences.working_area.lng)};
            } else if(this.profile.person_data.address_latitude != null && this.profile.person_data.address_longitude != null){
                this.marker = {lat: parseFloat(this.profile.person_data.address_latitude), lng: parseFloat(this.profile.person_data.address_longitude)};
                this.center = {lat: parseFloat(this.profile.person_data.address_latitude), lng: parseFloat(this.profile.person_data.address_longitude)};
            } else {
                this.center = {lat: 52.15, lng: 5.383333};
            }
        },

        change(){
            this.parseData();
            this.mChange.args.id_person_data_main = this.profile.id;
            this.mChange.args.plan_preferences.hour_per_week = this.plan_preferences.hour_per_week;
            this.mChange.args.plan_preferences.ids_tod_const_type = this.plan_preferences.ids_tod_const_type;
            this.mChange.args.plan_preferences.ids_sector_const_type = this.plan_preferences.ids_sector_const_type;
            this.mChange.args.plan_preferences.working_area.radius = this.plan_preferences.working_area.radius;
            this.mChange.args.plan_preferences.working_area.lng = this.plan_preferences.working_area.lng;
            this.mChange.args.plan_preferences.working_area.lat = this.plan_preferences.working_area.lat;
            this.mChange.args.plan_preferences.assigments_allow = this.plan_preferences.assigments_allow;
            this.mChange.args.address.lng = this.profile.person_data.address_longitude;
            this.mChange.args.address.lat = this.profile.person_data.address_latitude;
            this.mChange.show = true;
        },

        getOpenShifts(){
            this.mShifts.args.id_person_data_main = this.profile.id;
            this.mShifts.args.plan_preferences = JSON.parse(this.profile.person_data.plan_preferences);
            this.mShifts.args.sectors = this.sector_label;
            this.mShifts.args.tod = this.availabilty_label;
            this.mShifts.show = true;
        },

        getMyShifts(){
            this.mMyShifts.args.id_person_data_main = this.profile.id;

            this.mMyShifts.show = true;
        }
    }
}
</script>
<style scoped>
.app-local-padding .card-body{
    padding: 6px !important;
}
</style>