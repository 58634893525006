<template>
    <div>
        <b-row class="app-component-icon-edit" v-if="(user_role < 3 || profile.can_user_edit) && profile.person_data.id_status_const_type != 1002 && profile.person_data.pension_age_warning == 0">
            <b-dropdown  variant="link"  no-caret right>
                <template  #button-content>
                    <b-icon icon="three-dots-vertical" class="text-body"></b-icon>
                </template>
                <b-dropdown-item @click="change">{{trans('change',175)}}</b-dropdown-item>
            </b-dropdown>
        </b-row>

        <b-row class="app-card-header">
            <b-col start>
                <p class="col-list-label">{{trans('fp-pension-type',172)}}</p>
                <p class="col-list-label">{{trans('pw-number-of-weeks-accrued',196)}}</p>
                <p class="col-list-label">{{trans('fp-number-of-pension-free-weeks',172)}}</p>
                <p class="col-list-label">{{trans('fp-number-of-weeks-in-basic',172)}}</p>
            </b-col>
            <b-col>
                <p class="col-form-value">{{profile.person_data.pension_type == 0 ? trans('fp-no-retirement',172) : (profile.person_data.pension_type == 1 ? trans('pw-basic-scheme',196) : trans('pw-plus-scheme',196))}}</p>
                <p class="col-form-value">{{profile.person_data.previous_worked_weeks ? profile.person_data.previous_worked_weeks : '-'}}</p>
                <p class="col-form-value">{{profile.person_data.pension_free_weeks ? profile.person_data.pension_free_weeks : '-'}}</p>
                <p class="col-form-value">{{profile.person_data.pension_basic_weeks ? profile.person_data.pension_basic_weeks : '-'}}</p>
            </b-col>
        </b-row>

        <b-alert variant="warning" show class="mt-3" v-if="profile.person_data.pension_age_warning == 1">
            <div class="text-md-start">{{trans('fp-flexworker-under-twenty-one',172)}}</div> 
        </b-alert> 

        <b-alert variant="warning" show class="mt-3" v-if="profile.person_data.pension_age_warning == 2">
            <div class="text-md-start">{{trans('fp-flexworker-older-than-sixty-seven',172)}}</div> 
        </b-alert>
      

        <people-profile-pension-change v-if="mChange.show" :show.sync="mChange.show" :args.sync="mChange.args" :result.sync="mChange.result" :return.sync="profile" />

    </div>
</template>
<script>

import PeopleProfilePensionChange from './PeopleProfilePensionChange.vue';

export default {
    props: ["profile"],

    components: {
        PeopleProfilePensionChange
    },

    created(){
    },

    computed: {
        cProfile: {
            get() { return this.profile },
            set() { }
        },
    },

    watch: {

    },

    data(){
        return{

            mChange: { show:false, args: {
                id_person_data_main: null,
                pension_type: null,
                previous_worked_weeks: null,
                pension_free_weeks: null,
                pension_basic_weeks: null,    
            }, result: 0, return: null},
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
        }
    },

    methods: {

        change(){
            this.mChange.args.id_person_data_main = this.profile.id;
            this.mChange.args.pension_type = this.profile.person_data.pension_type;
            this.mChange.args.previous_worked_weeks = this.profile.person_data.previous_worked_weeks;
            this.mChange.args.pension_free_weeks = this.profile.person_data.pension_free_weeks;
            this.mChange.args.pension_basic_weeks = this.profile.person_data.pension_basic_weeks;
            this.mChange.show = true;
        },
    }
}
</script>
<style scoped>

</style>