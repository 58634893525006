<template>
    <b-modal ref="modal-add" size="xl" v-model="computedShow" no-close-on-backdrop :title="trans('fp-unavailability', 172)" @close="closeModal">

        <b-card class="mt-2">
            <app-view-table :loading="loading_list" :items="items" :fields="fields" :title="trans('fp-unavailability', 172)" :filters="filters">

                <template v-slot:menu-items="cell">
                
                    <b-dropdown-item  @click="changeUnavailability(cell.data.item)">{{trans('change',175)}}</b-dropdown-item>    
                    <b-dropdown-item  @click="remove(cell.data.item)">{{trans('remove',175)}}</b-dropdown-item>
                 </template>

                 <template v-slot:buttons>
                    <app-button type="add" @click="addUnavailability">
                        {{trans('add', 175)}}
                    </app-button>
                 </template>

            </app-view-table>

            
        </b-card>


        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
        </template>
        
        <planning-planner-unavailability-add v-if="mAdd.show" :show.sync="mAdd.show" :args.sync="mAdd.args" :result.sync="mAdd.result" :return.sync="mAdd.return" />
        <planning-planner-unavailability-change v-if="mChange.show" :show.sync="mChange.show" :args.sync="mChange.args" :result.sync="mChange.result" :return.sync="mChange.return" />
    </b-modal>
</template>

<script>
import axios from "@axios";
import moment from "moment";
import PlanningPlannerUnavailabilityChange from './PlanningPlannerUnavailabilityChange.vue';
import PlanningPlannerUnavailabilityAdd from './PlanningPlannerUnavailabilityAdd.vue';
import AppAlerts from '@core/scripts/AppAlerts';


export default {
    components: {
        PlanningPlannerUnavailabilityChange,
        PlanningPlannerUnavailabilityAdd,
    },

    props: ["show", "args", "return", "result"],

    created(){
        this.getList();
    },

    computed: {
        computedShow: {
        get() { return this.show },
        set(value) { this.$emit('update:show', value) }, 
        },

        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },

        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        },
    },

    watch: {
        "items.refresh": {
            handler: function(newVal){
                if(newVal > 0){
                    this.getList();
                }
            }
        },

        "mAdd.result": {
            handler: function(val){
                if(val == 1){
                    this.items = this.mAdd.return;

                    this.mAdd.result = 0;
                    this.mAdd.return = null;
                }
            }
        },

        "mChange.result": {
            handler: function(val){
                if(val == 1){
                    this.items = this.mChange.return;

                    this.mChange.result = 0;
                    this.mChange.return = null;
                }
            }
        },

        "filters.sorting.changed": {
            handler: function(val){
                if(!this.loading_list){
                    this.getList();
                }
                
            }
        },

        "filters.filtering.types.changed": {
            handler: function(val){
                if(!this.loading_list){
                   this.getList(); 
                }
            }
        },

        "filters.changed": {
            handler: function(val){
                if(!this.loading_list){
                   this.getList(); 
                }
            }
        },

        "filters.page": {
            handler: function(val){
                if(!this.loading_list){
                   this.getList(); 
                }
            }
        }
    },

    data() {
        return {
            loading: false, 
            loading_list: false,
            items: [],
            fields:[
                { key: 'date_from', label: this.trans('date-from', 182), thStyle: { "min-width": "140px", "max-width": "140px" },visible: true },
                { key: 'date_to', label: this.trans('date-to', 182), thStyle: { "min-width": "140px", "max-width": "140px" },visible: true },
                { key: 'type', label: this.trans('type', 182), thStyle: { "min-width": "120px", "max-width": "120px" }, visible: true, filtering: true, filtering_name: "types"},
                { key: 'hours_amount', label: this.trans('sh-hours', 215), thStyle: { "min-width": "60px", "max-width": "60px" }, visible: true, filtering: false},
                { key: 'description', label: this.trans('description', 182), thStyle: { "width": "100%" },visible: true}
            ],
            filters: {
                sorting: {
                        field_name: '',
                        sort_direction: 0,
                        changed: 0
                },
                filtering: {                        
                    types: {type: 'list', changed: 0, values: [], function: "getAvailabilityTypes", parameters: null, widthClass:'app-width-min-250'},
                },
                page: 1,
                per_page: 5,
                search: "",
                changed: 0
            },
            mAdd: { show: false, args: { id_person_data_main: null, filters: null, return_type: 1}, result: 0, return: null},
            mChange: { show: false, args: {
                id_person_data_main: null,
                filters: null,
                date_from: null,
                date_to: null,
                type: { name: null, value: null },
                description: null,
                id_unavailability_data_main: null,
                hours_amount: null,
                return_type: 1
            }, return: null },
            alert_class: new AppAlerts(),

        };
    },

    methods: {

        getList(){

            this.loading_list = true;
            axios
                .post('planning/planner/unavailability/getList', {
                    id_person_data_main: this.args.id_person_data_main,
                    filters: JSON.stringify(this.filters)
                })
                .then(res => {
                    this.items = res.data.items;
                    this.filters.page = res.data.page;
                })
                .catch((error) => {
                    console.error(`error: ${error}`);
                })
                .finally(() => {
                    this.loading_list = false;
                })
        },

        addUnavailability(){
            this.mAdd.args.id_person_data_main = this.args.id_person_data_main;
            this.mAdd.args.filters = this.filters;

            this.mAdd.show = true;
        },

        changeUnavailability(item){
            this.mChange.args.id_person_data_main = this.args.id_person_data_main;
            this.mChange.args.filters = this.filters;
            this.mChange.args.date_from = moment(item.date_from,"DD-MM-YYYY").format("DD-MM-YYYY");
            this.mChange.args.date_to = moment(item.date_to, "DD-MM-YYYY").format("DD-MM-YYYY");
            this.mChange.args.type.name = item.type;
            this.mChange.args.type.value = item.id_availability_const_type;
            this.mChange.args.description = item.description;
            this.mChange.args.id_unavailability_data_main = item.id_unavailability_data_main;
            this.mChange.args.hours_amount = item.hours_amount;
            this.mChange.show = true;
        },

        remove(item){
            this.alert_class.openAlertConfirmation(this.trans('confirm-message', 180)).then(res => {
                if(res == true){
                    this.loading_list = true;

            axios
                .post('planning/planner/unavailability/remove', {
                    id_person_data_main: this.args.id_person_data_main,
                    filters: JSON.stringify(this.filters),
                    id_unavailability_data_main: item.id_unavailability_data_main,
                    return_type: 1
                })
                .then(res => {
                    this.items = res.data.items;
                    this.filters.page = res.data.page;
                })
                .catch((error) => {
                    console.error(`error: ${error}`);
                })
                .finally(() => {
                    this.loading_list = false;
                })
                }
            })
        },


        closeModal() {
            this.$emit("update:show", false);
            this.$emit('close');
        },




    },
};
</script>

<style scoped>

</style>
