<template>
    <b-modal ref="modal-add" scrollable size="md" v-model="computedShow" no-close-on-backdrop :title="trans('add-contract-details',8)">
         <ValidationObserver ref="form" >
        <b-form @submit.prevent="onSubmit">

            <app-row-left-label :label="trans('entry-date',182)">
                <app-date v-model="date_start" validatorName="Ingangsdatum" validatorRules="required|date-check" :disabledDatesPass="disabled_dates_pass" :validatorCustomMessage="{ 'disable-date-past': trans('cw-start-date-error',194) }"/>
            </app-row-left-label>

            <app-row-left-label :label="trans('date-to',182)" v-if="[14,15,11,56,57,58].includes(args.id_document_const_type)">
                <app-date v-model="date_end" validatorName="Einddatum" :validatorRules="date_end_required+'date-check|date-more-than:'+date_start" :validatorCustomMessage="{ 'date-more-check': trans('end-date-past-error',199) }"/>
            </app-row-left-label>

            <app-row-left-label :label="args.applicant_type === 1 ? trans('payment-frequency',184) : trans('invoice-frequency',182)" v-if="![11, 12, 55, 57, 17].includes(args.id_document_const_type)">
                <app-radio-group v-model="period_value" :options="period_options" :validatorName="args.applicant_type === 1 ? trans('payment-frequency',184) : trans('invoice-frequency',182)" validatorRules="required" :disabled="loading_data == true" />
            </app-row-left-label>

            <app-row-left-label :label="trans('hours-per-week',184)" v-if="[11,14,15,16,56,57,58,59].includes(args.id_document_const_type)">
                <app-input v-model="hours_per_week_value" validatorName="Uren per week" validatorRules="required|integer|min_value:0|max_value:50" :validatorCustomMessage="{ 'min_value': trans('contracts-hour-required',177), 'max_value':trans('contracts-hour-required',177) }" type="integer_02" />
            </app-row-left-label>

            <app-row-left-label :label="trans('hour-wage',3)" v-if="[14,15,16,58,59].includes(args.id_document_const_type)">
                <app-input v-model="hourly_wage_value" validatorName="Uurloon" validatorRules="required" :placeholder="'00,00'" type="decimal_02" />
            </app-row-left-label>

            <!-- Alert sprawdzajacy role pracownika -->
            <b-row v-if="user_role == 3 && args.id_document_const_type != 12 && args.id_document_const_type != 11 && args.id_document_const_type != 17">
                <b-col cols="12 mt-2">
                    <b-alert variant="warning" show class="mb-0">
                        <div class="font-weight-600 text-base">{{trans('contract-create-allert',177)}}</div>
                        <div class="text-small">{{trans('contract-not-authorised',177)}}</div>    
                    </b-alert> 
                </b-col>
            </b-row>

            <b-row v-if="user_role == 3 && args.id_document_const_type != 12 && args.id_document_const_type != 11 && args.id_document_const_type != 17">
                <b-col cols="12 mt-2">
                    <app-memo v-model="motivation" :class="{'app-local-memo': args.id_document_const_type == 16}" rows="3" :placeholder="args.id_document_const_type == 16 ? trans('motivation-placeholder',184) : trans('motivation',184)" :lettersCounter="true" :maxLength="500" validatorRules="required" validatorName="Motivatie"/>
                </b-col>
            </b-row>
               
            <!-- Contract training for student -->
            <hr v-if="args.applicant_type == 3" class="mt-1 mb-3" />

            <app-row-left-label :label="'Aanmaken scholingovereenkomst'" v-if="args.applicant_type == 3">
                <app-radio-group v-model="training_contract" :options="training_contract_options" validatorRules="required" :validatorCustomMessage="{ required: trans('field-required-error',180) }" />
            </app-row-left-label>

            <!-- Alert sprawdzający czy prcownik posiada podpis  v-if="user_signature == null && user_signature.length == 0" -->
            <b-row v-if="user_signature == null || user_signature.length == 0">    
                <b-col cols="12 mt-2">
                    <b-alert variant="warning" show class="mb-0">
                        <div class="font-weight-600 text-base">{{trans('missing-signature',180)}}</div>
                        <div class="text-small">{{trans('click',175)}} <b-link @click="creatSignature">{{trans('here',175)}}</b-link> {{trans('signature-for-contracts',175)}}</div>    
                    </b-alert> 
                </b-col>
            </b-row>

        </b-form>
        </ValidationObserver>

        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button v-if="user_role == 3 && args.id_document_const_type != 12 && args.id_document_const_type != 11 && args.id_document_const_type != 17" type="send" @click="add" :loading="loading">{{trans('contract-request',174)}}</app-button>
            <app-button v-else type="primary" @click="add" :loading="loading" :disabled="user_signature == null || user_signature.length == 0">{{trans('cw-send-contract',194)}}</app-button>
        </template>

    </b-modal>
</template>

<script>

import axios from "@axios";
import { ValidationObserver } from "vee-validate";
import AppDate from "@core/components/AppDate.vue";
import moment from "moment";
import AppMemo from '@core/components/AppMemo.vue';
import AppAlerts from '@core/scripts/AppAlerts';

export default {
    components: {
        ValidationObserver,
        AppDate,
        AppMemo,
    },

    created(){
       this.getData();


       const today = new Date();
       if(this.args.finish_date != null){
            this.disabled_dates_range = '(date < ' + today.setDate(today.getDate() - 15) +') ||' + '(date < new Date(' + moment(this.args.finish_date,"DD-MM-YYYY").add(1) + '))';
            
            let a = moment(this.args.finish_date,"DD-MM-YYYY");
            let b = moment().subtract(15, "days");

            if(a > b){
                this.date_start = moment(this.args.finish_date,"DD-MM-YYYY").add(1, 'days').format('DD-MM-YYYY');
                this.disabled_dates_pass = moment().diff(a, 'days') - 1;
            }else{
                this.date_start = moment(new Date()).format('DD-MM-YYYY');
                this.disabled_dates_pass = 15;
            }

       }else{
            this.disabled_dates_range = '(date < ' + today.setDate(today.getDate() - 15) +')';
            this.date_start = moment(new Date()).format('DD-MM-YYYY');
            this.disabled_dates_pass = 15;
       }
    },

    props: ["show", "result","args", "return"],

    computed: {
        computedShow: {
            get() { return this.show },
            set(value) { this.$emit('update:show', value) }, 
        },
        
        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        }
    },

    data() {
        return {
            loading: false,
            status: 0,
            loading_data: false,
        
            period_options:  [
                { value: 1, text: this.trans('week',3) },
                { value: 2, text: this.trans('4-weeks',3) },
                { value: 3, text: this.trans('month',3) },
            ],
            period_value: null,
            
            training_contract: null,
            training_contract_options: [
                                            { value: 1, text: this.trans('yes-option',3) },
                                            { value: 0, text: this.trans('no-option',3) },
                                        ],

            hours_per_week_value: 0.0,
            hourly_wage_value: null,
            sector_risk_group_value: 0,
            date_start: null,
            date_end: null,
            date_end_required: '',
            id_document_data_version: null,
            user_role: null,
            user_signature : JSON.parse(localStorage.getItem('user')).signature,
            motivation: '',
            alertClass: new AppAlerts(),
            disabled_dates_range: null,
            disabled_dates_pass: null
        };
    },

    methods: {
        getData(){
            if([11, 15, 56, 57, 58].includes(this.args.id_document_const_type)){
                this.date_end_required = 'required|';
            }
                this.user_role = JSON.parse(localStorage.getItem('user')).id_user_const_role;
                 this.loading_data = true;
                        axios
                            .post("people/profile/contracts/getPaymentPeriod", {                                
                                id_person_data_main: this.args.id_person_data_main,
                                applicant_type: this.args.applicant_type
                            })
                            .then((res) => {
                                if(res.data.length > 0){
                                    this.period_value = res.data[0].period;
                                } else {
                                    this.period_value = 1;
                                }                               
                                this.loading_data = false;
                            })
                            .catch((error) => {
                                console.error(error);
                                this.loading_data = false;
                            });
        },

        add() {
            
            this.$refs.form.validate().then((result) => {
                if (!result) {
                    return false;
                } else {

                    if(this.args.id_document_data_version == 55){
                        if(this.args.faseb_contracts.total >= 6){
                            this.alertClass.openAlertWarning(this.trans('fp-faze-b-error',177)).then(res => {}); 
                            return;
                        }else if(this.args.faseb_contracts.days + moment(this.date_end, "DD-MM-YYYY").diff(moment(this.date_start, "DD-MM-YYYY"), 'days') > moment().diff(moment().subtract(3, 'years'), 'days')){
                            this.alertClass.openAlertWarning(this.trans('fp-not-worked-for-six-months',177)).then(res => {}); 
                            return;
                        }
                    }
                        
                        this.loading = true;
                        var hourly_wage_valueFixed = null;
                        if(this.hourly_wage_value != null){
                            hourly_wage_valueFixed = this.hourly_wage_value.toString().replace(',','.');
                        }

                        axios
                            .post("people/profile/contracts/addContract", {
                                
                                id_person_data_main: this.args.id_person_data_main,
                                date_from: this.date_start,
                                date_to: this.date_end,
                                period: this.period_value,
                                week_hours: this.hours_per_week_value,
                                hour_wage: hourly_wage_valueFixed,
                                risk_group: this.sector_risk_group_value,
                                id_document_data_version: this.args.id_document_data_version,
                                motivation: this.motivation,
                                training_contract: this.training_contract


                            })
                            .then((res) => {

                                this.cReturn.person_data = res.data.person_data;
                                this.cReturn.contracts_employment.contracts.items = res.data.contracts.items;
                                this.computedShow = false;

                            })
                            .catch((error) => {
                                console.error(error);
                            })
                            .finally(() => {
                                this.loading = false;
                                this.$emit("update:show", false);
                                this.$emit("update:result", 1);
                                
                            });
                }})
        },

        closeModal() {
            this.$emit("update:show", false);
        },

        creatSignature(){
            this.$router.push({ name: "user-signature-pad", params: { idUserSignature: JSON.parse(localStorage.getItem('user')).id, returnType: "profil_card", returnIdType: this.args.id_person_data_main} });
        }
    },
};
</script>

<style>

.app-local-memo .app-memo{
    min-height: 245px;
}
</style>
