<template>
    <div>
        <b-row v-if="profile.person_data.id_status_const_type > 2 && (user_role < 3 || profile.can_user_edit) && profile.person_data.id_status_const_type != 1002" class="app-component-icon-edit">
            <b-dropdown variant="link"  no-caret right>
                <template  #button-content>
                    <b-icon icon="three-dots-vertical" class="text-body"></b-icon>
                </template>
                <b-dropdown-item @click="change()">{{trans('change',175)}}</b-dropdown-item>
            </b-dropdown>
        </b-row>      
            <b-row class="app-card-header">
                <b-col start>
                    <p class="col-list-label">{{trans('first-name',182)}}</p>
                    <p class="col-list-label">{{trans('inbetween-name',182)}}</p>
                    <p class="col-list-label">{{trans('last-name',182)}}</p>
                    <p class="col-list-label">{{trans('function',3)}}</p>
                    <p class="col-list-label">{{trans('organization',169)}}</p>
                    <p class="col-list-label">{{trans('phone-number',182)}}</p>
                    <p class="col-list-label">{{trans('email',182)}}</p>
                </b-col>
                <b-col>
                    <p class="col-form-value">{{person_reference && person_reference.first_name ? person_reference.first_name : '-'}}</p>
                    <p class="col-form-value">{{person_reference && person_reference.inbetween_name ? person_reference.inbetween_name : '-'}}</p>
                    <p class="col-form-value">{{person_reference && person_reference.last_name ? person_reference.last_name : '-'}}</p>
                    <p class="col-form-value">{{person_reference && person_reference.function ? person_reference.function : '-'}}</p>
                    <p class="col-form-value">{{person_reference && person_reference.organisation ? person_reference.organisation : '-'}}</p>
                    <p class="col-form-value">{{person_reference && person_reference.phone_number ? phone : '-'}}</p>
                    <p class="col-form-value">{{person_reference && person_reference.email ? person_reference.email : '-'}}</p>
                </b-col>
            </b-row>      

        <people-profile-reference-change v-if="mChange.show" :show.sync="mChange.show" :args.sync="mChange.args" :result.sync="mChange.result" :return.sync="profile" />
    </div>
</template>
<script>

import PeopleProfileReferenceChange from './PeopleProfileReferenceChange.vue'
export default {

    components: {
        PeopleProfileReferenceChange
    },

    props:["profile"],

    created(){
        this.parseData();
    },

    watch:{
        'mChange.result': {
            handler: function(value){
                if(this.mChange.result == 1){
                    this.mChange.result = 0;
                    this.parseData();
                }
            },
            deep: true
        },

        "profile.person_data.person_reference": {
            handler: function(value){
                this.parseData();
            },
            deep: true
        }
    },

    computed: {
        phone: function () {
            if (this.person_reference.phone_number !== null && this.person_reference.phone_prefix == '+31') {
                return 0 + this.person_reference.phone_number.replace(/^(.{1})(.{3})(.{3})(.*)$/, "$1 $2 $3 $4");
            } else {
                return this.person_reference.phone_prefix + " " + this.person_reference.phone_number;
            }
        }
    },
    
    data(){
        return{
            person_reference: null,
            mChange: { show: false, args: {
                id_person_data_main: null,
                person_reference:{
                    first_name: null,
                    inbetween_name: null,
                    last_name: null,
                    function: null,
                    organisation: null,
                    email: null,
                    phone_prefix: null,
                    phone_number: null
                }

            }, result: 0, return: null },
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
        }
    },

    methods:{
       
        parseData(){
            if(this.profile.person_data.person_reference != null){
                this.person_reference = JSON.parse(this.profile.person_data.person_reference);
                if(!this.person_reference.phone_prefix){
                    this.person_reference.phone_prefix = '+31';
                }
            } else {
                this.person_reference = {
                    first_name: null,
                    inbetween_name: null,
                    last_name: null,
                    function: null,
                    organisation: null,
                    email: null,
                    phone_prefix: null,
                    phone_number: null
                }
            }
        },

        change(){

            var person_reference = JSON.parse(JSON.stringify(this.person_reference));
            this.mChange.args.person_reference.first_name = person_reference.first_name;
            this.mChange.args.person_reference.last_name = person_reference.last_name;
            this.mChange.args.person_reference.inbetween_name = person_reference.inbetween_name;
            this.mChange.args.person_reference.email = person_reference.email;
            this.mChange.args.person_reference.function = person_reference.function;
            this.mChange.args.person_reference.organisation = person_reference.organisation;
            this.mChange.args.person_reference.phone_prefix = person_reference.phone_prefix != null ? person_reference.phone_prefix : "+31";
            this.mChange.args.person_reference.phone_number = person_reference.phone_number;
            this.mChange.args.id_person_data_main = this.profile.id;
            this.mChange.args.return_type = "reference";
            this.mChange.show = true;
        }
    }
 
};
</script>

<style scoped>

</style>