<template>
    <div>
        <b-row class="align-items-center ml-1 mr-1">

            <img class="rounded-circle custom-avatar mt-1" v-if="profile.person_data.avatar != null" :src="profile.person_data.avatar" alt="Avatar" />
            <img class="rounded-circle custom-avatar mt-1" v-else-if="profile.person_data.sex == 2" src="@/assets/images/avatars/female.jpg" alt="Avatar" />
            <img class="rounded-circle custom-avatar mt-1" v-else  src="@/assets/images/avatars/male.jpg" alt="Avatar" /> 
            <b-col>
                <div class="app-local-name">{{this.profile.person_data.profile_name}}</div>
                <div v-if="this.profile.person_data.applicant_type == 1 || this.profile.person_data.applicant_type == 3">
                    <div v-if="this.profile.person_data.is_contract == 1" class="app-local-text">{{this.trans('id-number',3)}} {{this.profile.id}} | {{ profile.person_data.applicant_type == 1 ? this.trans('fp-status-salaried-service',172) : trans('fl-student', 176)}} <span v-if="this.profile.person_data.contract_phase != null && this.profile.person_data.contract_phase != ''">| {{this.trans('phase',3)}} {{this.profile.person_data.contract_phase}}</span></div>
                    <div v-else class="app-local-text">{{this.trans('id-number',3)}} {{this.profile.id}} |  {{profile.person_data.applicant_type == 1 ? this.trans('fp-status-salaried-service',172) : trans('fl-student', 176)}}</div>
                </div>
                <div v-else-if="this.profile.person_data.applicant_type == 2" class="app-local-text">{{this.trans('id-number',3)}} {{this.profile.id}} | {{this.trans('fp-self-employed',172)}}</div>
                <div v-else class="app-local-text">{{this.trans('id-number',3)}} {{this.profile.id}}</div>
            </b-col>


            <b-col style="max-width:215px">
                <div class="app-local-applicant-type text-right">
                    <div v-if="this.profile.person_data.register_status === 1">
                        <span v-bind:class="{ 
                                                'dot-green' : this.profile.person_data.deleted == 0 && ![7, 8].includes(this.profile.person_data.id_status_const_type), 
                                                'dot-orange' : this.profile.person_data.deleted == 0 && [7, 8].includes(this.profile.person_data.id_status_const_type), 
                                                'dot-red' : (this.profile.person_data.deleted == 1 || [1000, 1001, 1002].includes(this.profile.person_data.id_status_const_type)) 
                                            }"> </span> {{this.trans('applicant',176)}} <br>
                        <span @click=" user_role == 1 ? changeStatus() : null" class="font-weight-300" :class="{'app-cursor-pointer': user_role == 1}">{{this.profile.person_data.status_name}}</span>
                        <b-icon @click="user_role == 1 ? changeStatus() : null" icon="pencil-fill" class="text-body list-button-icon mt-1 ml-2 align-top app-cursor-pointer" v-if="user_role == 1"></b-icon>   
                    </div>
                    <div v-if="this.profile.person_data.register_status === 2">
                        <span class="mt-1" v-bind:class="{ 
                                                            'dot-green' : this.profile.person_data.deleted == 0 && ![7, 8].includes(this.profile.person_data.id_status_const_type),
                                                            'dot-orange' : this.profile.person_data.deleted == 0 && [7, 8].includes(this.profile.person_data.id_status_const_type),  
                                                            'dot-red': (this.profile.person_data.deleted == 1 || [1000, 1001, 1002].includes(this.profile.person_data.id_status_const_type)) 
                                                        }"> </span> {{ this.profile.person_data.status_name }}
                        <b-icon icon="pencil-fill" @click="user_role == 1 ? changeStatus() : null" class="text-body list-button-icon mt-1 ml-2 align-top app-cursor-pointer" v-if="user_role == 1"></b-icon>   
                    </div>
                </div>
            </b-col>

            <b-col cols="4">
                <b-row>
                    <b-col cols="auto" class="ml-1">
                        <people-profile-status-bar-easy-flex :profile.sync="profile"/>
                    </b-col>
                    <b-col end>
                        <div class="text-right">
                            <div class="text-right" v-if="profile.person_data.id_status_const_type >= 2 && profile.person_data.id_status_const_type != 1002">
                                <span :class="{'app-cursor-pointer': (user_role < 3 || profile.can_user_edit)}" @click=" (user_role < 3 || profile.can_user_edit) ? change() : null">{{this.profile.person_data.function_name == null ? "Geen functie" : profile.person_data.function_name}}</span>              
                                <b-icon @click="change()" icon="pencil-fill" class="app-local-applicant-type text-body list-button-icon mt-1 ml-2 align-top app-cursor-pointer" v-if="(user_role < 3 || profile.can_user_edit)"></b-icon> 
                            </div>     
                        </div>
                    </b-col>
                </b-row>
                
            </b-col>
        </b-row>
        <people-profile-status-bar-functions-change v-if="mFunctionChoose.show" :show.sync="mFunctionChoose.show" :result.sync="mFunctionChoose.result" :refreshCompetence.sync="profile.competences.refresh" :args.sync="mFunctionChoose.args" :return.sync="profile"/>
        <people-profile-status-bar-status-change v-if="mStatusChoose.show" :show.sync="mStatusChoose.show" :result.sync="mStatusChoose.result" :args.sync="mStatusChoose.args" :return.sync="profile"/>
    </div>
</template>
<script>

import PeopleProfileStatusBarFunctionsChange from './PeopleProfileStatusBarFunctionsChange.vue';
import PeopleProfileStatusBarStatusChange from './PeopleProfileStatusBarStatusChange.vue';
import PeopleProfileStatusBarEasyFlex from './PeopleProfileStatusBarEasyFlex.vue';

export default {
    components: {
        PeopleProfileStatusBarFunctionsChange,
        PeopleProfileStatusBarStatusChange,
        PeopleProfileStatusBarEasyFlex
    },

     props:["profile"],

    data(){
        return{
            mFunctionChoose: {show: false, args: {}, result: 0, return: null},
            mStatusChoose: {show: false, args: {}, result: 0, return: null},
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
        }
    },

    methods:{
        change(){            
            this.mFunctionChoose.args.id_function_data_main = this.profile.person_data.id_function_data_main;
            this.mFunctionChoose.args.id_person_data_main = this.profile.id;
            this.mFunctionChoose.args.applicant_type = this.profile.person_data.applicant_type;
            this.mFunctionChoose.show = true;
        },

        changeStatus(){            
            this.mStatusChoose.args.id_status_const_type = this.profile.person_data.id_status_const_type;
            this.mStatusChoose.args.id_person_data_main = this.profile.id;
            this.mStatusChoose.show = true;
        },
        
    }
};
</script>

<style scoped>

.app-icon-warning{
    color: #f04438;
}


.app-local-popover{
    color: red;
}

.app-local-popover-icon{
    padding: 0px;
    margin-left: 4px;
    background-color: inherit;
    border: none;
}

.app-local-text{
    font-size:16px;
}
.app-local-name{
    font-size:24px;
}
.app-local-applicant-type{
    font-size: 14px;
    font-weight: 600;
}

.custom-avatar{
    height: 50px;
    width: 50px;
}


</style>
