<template>
    <b-modal ref="modal-add" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('fp-change-big',172)">
        <ValidationObserver ref="form" >
            <b-form @submit.prevent="onSubmit">

                <app-row-left-label :label="trans('registered',3)">
                    <app-radio-group v-model="cArgs.is_big_number" :options="opt" validatorName="isBig" validatorRules="required" :validatorCustomMessage="{ required: trans('required-message',180) }"/>
                </app-row-left-label>


                <app-big ref="big_control" v-if="cArgs.is_big_number == 1" :bigData.sync="cArgs"/>


            </b-form>
        </ValidationObserver>
        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="change()" :loading="loading">
                {{trans('save',175)}}
            </app-button>
        </template>
    </b-modal>
</template>

<script>
import axios from "@axios";
import { ValidationObserver } from "vee-validate";
import AppBig from "@core/components/AppBig"
import AppRadioGroup from '@core/components/AppRadioGroup.vue';

export default {
    components: {
        ValidationObserver,
        AppBig,
        AppRadioGroup,
    },

    props: ["show", "args", "return", "result"],

    computed: {
        computedShow: {
            get() { return this.show },
            set() { this.$emit('update:show', false) }, 
        },

        cArgs: {
            get() { return this.args },
            set() { this.$emit('update:args', false) }
        },
        
        cReturn: {
            get() { return this.return },
            set() { this.$emit('update:return', false) }
        }
    },

    created(){
       if (this.args.big_number !== undefined && this.args.big_number !== null && this.args.big_data_main !== null) {
           this.checkedBigNumber = this.args.big_number;
       } 
    },

    data() {
        return {
            loading: false,
            opt: [ { value: 1, text: this.trans('yes-option',3) }, { value: 0, text: this.trans('no-option',3) }],
        };
    },

    methods: {

        change() {
            
            this.$refs.form.validate().then((result) => {
                if (!result) {
                    return false;
                }else{
                    if(this.$refs.big_control !=  null &&!this.$refs.big_control.fInformation){
                        !this.$refs.big_control.checkBig();
                    }else{
                    
                        this.loading = true;
                        axios
                            .post("people/profile/identity/changeBig", {
                                id_person_data_main: this.args.id_person_data_main,
                                is_big_number: this.args.is_big_number,
                                big_number: this.args.big_number,
                                big_data_main: JSON.stringify(this.args.big_data_main),
                                return_type: this.args.return_type
                            })
                            .then((res) => {
                                this.$emit("update:show", false);
                                this.$emit("update:result", 1);
                                
                                if(this.args.return_type == "compliance_check"){
                                    this.cReturn.compliance_check.items = res.data.items;
                                    this.cReturn.compliance_check.correct = res.data.correct;
                                    this.cReturn.compliance_check.show = res.data.show;
                                    this.cReturn.refresh ++;
                                }else if(this.args.return_type == "identity"){
                                    this.cReturn.person_data = res.data;
                                    this.cReturn.compliance_check.refresh ++;
                                }

                                this.loading = false;
                            })
                            .catch((error) => {
                                console.error(`error during request: ${error}`);
                                this.loading = false;
                            });
                    }
                }
            });
        },

        closeModal() {
            this.$emit("update:show", false);
        },

    },
};
</script>


