<template>
    <b-modal ref="modal-change" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('fp-send-notification',172)">
        <b-row>
            <b-col cols="12">
                <ValidationObserver ref="form" >

                    <app-row-left-label :label="trans('td-title',205)">
                        <app-input v-model="cArgs.title" :validatorName="trans('td-title',205)" validatorRules="required|max:20" mask="lettersE5-20"/>
                    </app-row-left-label>

                    <app-row-left-label :label="trans('se-message',167)">
                        <app-memo v-model="cArgs.message" rows="3" :maxLength="80" :lettersCounter="true" validatorRules="required" :validatorName="trans('se-message',167)"/>
                    </app-row-left-label>

                </ValidationObserver>
            </b-col>
        </b-row>
        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="send" :loading="loading">
                {{trans('fp-send',172)}}
            </app-button>
        </template>
    </b-modal>
</template>
<script>
import axios from "@axios";
import { ValidationObserver } from "vee-validate";

export default {

    components: {
        ValidationObserver,
    },

    props: ["show", "args", "return", "result"],

    computed: {
        computedShow: {
        get() { return this.show },
        set() { this.$emit('update:show', false) }, 
        },

        cArgs: {
            get() { return this.args },
            set() { this.$emit('update:args', false) }
        },

        cReturn: {
            get() { return this.return },
            set() { this.$emit('update:return', false) }
        },
    },

    data(){
        return{
            loading: false,
        }
    },

    methods: {

        send(){
            this.$refs.form.validate().then((result) => {
                if (!result) {
                    return false;
                } else {

                this.loading = true;
                
                axios
                    .post("settings/users/sendNotification", {
                        id_user_portal: this.cArgs.id_user_portal,
                        title: this.cArgs.title,
                        message: this.cArgs.message
                    })
                    .then(() => {
                        this.$emit("update:show", false);
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
                }
            });
        },

        closeModal() {
            this.$emit("update:show", false);
        },

    }
    
}
</script>
<style scoped>

</style>