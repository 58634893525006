<template>
    <div>
        <b-card no-body class="app-card-profile">
            <people-profile-loon-wages-payroll-component :profile.sync="profile" @scrollToDocument="scrollToDocument"/>
            <hr class="app-table-hr-separator">
            <people-profile-loon-wages-period-component :profile.sync="profile"/>  
        </b-card>
    </div>
</template>

<script>
import PeopleProfileLoonWagesPayrollComponent from './Payrol/PeopleProfileLoonWagesPayrollComponent.vue';
import PeopleProfileLoonWagesPeriodComponent from './Period/PeopleProfileLoonWagesPeriodComponent.vue';

export default {
    components: { 
        PeopleProfileLoonWagesPayrollComponent,
        PeopleProfileLoonWagesPeriodComponent 
      },

    props:["profile"],

    methods: {
        scrollToDocument(event){
            this.$emit('scrollToDocument', event);
        }
    }

};

</script>

<style scoped>

</style>
