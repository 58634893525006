<template>
    <div v-if="showActionPoints">
        <b-card class="mb-3" v-if="profile.person_data.id_status_const_type == 2">
            <b-row class="app-card-header">
                <b-col start>
                    <div class="app-card-profile-title mb-3">
                        {{trans('data-collecting',3)}}
                    </div>

                    <b-alert variant="light" show class="mb-0">
                        <div class="d-flex w-100 justify-content-between">
                            <div>
                                <div class="d-flex w-100 align-items-center">
                                    <b-icon icon="person-lines-fill" font-scale="3" class="mr-4"></b-icon>                                  
                                    <span class="align-middle font-weight-600 text-base">{{trans('a-candidate-status',172)}}</span>                                    
                                </div>
                            </div>
                            <div class="d-flex align-items-center">
                                <app-button type="primary" v-if="profile.person_data.is_webform == 0 && (user_role < 3 || profile.can_user_edit)" @click="sendWebForm()">
                                    {{trans('cp-submit-registration-form',189)}}
                                </app-button>
                                <app-button type="primary" :url="{ name: 'people-applicants-profile-wizard', params: { id_person_data_main: id_person_data_main }}"  v-if="(user_role < 3 || profile.can_user_edit)">{{trans('fp-open-registration-form',172)}}</app-button>                              
                            </div>
                        </div>
                    </b-alert> 

                </b-col>
            </b-row>
        </b-card>

        <b-card class="mb-3" v-if="profile.person_data.id_status_const_type == 4">
             <b-row class="app-card-header">
                <b-col start>
                    
                    <b-alert variant="light" show class="mb-0">
                        <div class="d-flex w-100 justify-content-between">
                            <div>
                                <div class="d-flex w-100 align-items-center">
                                    <b-icon icon="file-earmark-check-fill" font-scale="3" class="mr-4"></b-icon>
                                    <span class="align-middle font-weight-600 text-base">{{trans('fp-make-contract',172)}}</span>
                                </div>
                            </div>
                            <div class="d-flex align-items-center">
                               <app-button type="primary" :disabled="profile.compliance_check.correct == 0" @click="addContract()" v-if="(user_role < 3 || profile.can_user_edit)">{{trans('create-contract',175)}}</app-button>
                            </div>
                        </div>
                    </b-alert>  

                </b-col>
             </b-row>
        </b-card>

        <b-card class="mb-3" v-if="[1,3].includes(profile.person_data.applicant_type) && profile.person_data.id_status_const_type == 100 && profile.person_data.is_contract == 1 && profile.person_data.is_payroll_tax == 0">
            <b-row class="app-card-header">
                <b-col start>
                    
                    <b-alert variant="light" show class="mb-0">
                        <div class="d-flex w-100 justify-content-between">
                            <div>
                                <div class="d-flex w-100 align-items-center">
                                    <b-icon icon="file-earmark-check-fill" font-scale="3" class="mr-4"></b-icon>
                                    <span class="align-middle font-weight-600 text-base">{{trans('missing-payroll',177)}}</span>
                                </div>
                            </div>
                            <div class="d-flex align-items-center">
                               <app-button type="primary" @click="addPayrollTax()" v-if="(user_role < 3 || profile.can_user_edit)">{{trans('fp-send-payroll',172)}}</app-button>
                            </div>
                        </div>
                    </b-alert>  

                </b-col>
             </b-row>
        </b-card>

        <b-card class="mb-3" v-if="profile.person_data.id_status_const_type == 1002">
            <b-alert variant="light" show class="mb-0">
                <div class="d-flex w-100 justify-content-between">
                    <div class="d-flex align-items-center">
                        <div>
                            <b-icon icon="person-lines-fill" font-scale="3" class="mr-4"></b-icon>                        
                        </div>

                        <div class="w-100 align-items-center">           
                            <span class="align-middle font-weight-600 text-base">{{trans('a-unsubscribed',172)}} </span>  <br/>  
                            <span class="text-small">{{trans('fp-deregisteredon',177)}} {{profile.person_data.finish_date}}.</span>                                                                 
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <app-button type="primary" @click="activatePerson()" :loading="loading" v-if="(user_role < 3 || profile.can_user_edit)"> {{trans('restore',174)}} </app-button>
                    </div>
                </div>
            </b-alert>           
        </b-card>

        <b-card class="mb-3" v-if="profile.person_data.id_status_const_type == 8">
            <b-alert variant="light" show class="mb-0">
                <div class="d-flex w-100 justify-content-between">
                    <div class="d-flex align-items-center">
                        <div>
                            <b-icon icon="person-lines-fill" font-scale="3" class="mr-4"></b-icon>                        
                        </div>

                        <div class="w-100 align-items-center">           
                            <span class="align-middle font-weight-600 text-base">{{trans('a-activate',172)}} </span>  <br/>  
                            <span class="text-small">{{trans('fp-is-passive',172)}}</span>                                                                 
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <app-button type="primary" @click="activatePersonPassive()" :loading="loading" v-if="(user_role < 3 || profile.can_user_edit)"> {{trans('fp-activate',172)}} </app-button>
                    </div>
                </div>
            </b-alert>           
        </b-card>

        <person-profile-contracts-add-step-1 v-if="mContractAddStep1.show" :show.sync="mContractAddStep1.show" :result.sync="mContractAddStep1.result" :args="mContractAddStep1.args" :return.sync="mContractAddStep1.return"/>
        <person-profile-contracts-add-step-2 v-if="mContractAddStep2.show" :show.sync="mContractAddStep2.show" :result.sync="mContractAddStep2.result" :args.sync="mContractAddStep2.args" :return.sync="profile" />
        <people-profile-loon-wages-payroll-add v-if="mPayrollTaxAdd.show" :show.sync="mPayrollTaxAdd.show" :result.sync="mPayrollTaxAdd.result" :args.sync="mPayrollTaxAdd.args" :return.sync="profile"/>

    </div>
</template>
<script>
import AppAlerts from '@core/scripts/AppAlerts';
import axios from "@axios";
import PersonProfileContractsAddStep2 from '@app/People/Profile/ContractsEmployment/Contracts/PersonProfileContractsAddStep2.vue';
import PersonProfileContractsAddStep1 from '@app/People/Profile/ContractsEmployment/Contracts/PersonProfileContractsAddStep1.vue';
import PeopleProfileLoonWagesPayrollAdd from '@app/People/Profile/LoonWages/Payrol/PeopleProfileLoonWagesPayrollAdd.vue';

export default {
    components: {
            PersonProfileContractsAddStep2,
            PersonProfileContractsAddStep1,
            PeopleProfileLoonWagesPayrollAdd,
    },

    props:["profile"],
  
    watch: {
        "mContractAddStep1.result": function(val) {
            if (val === 1) {
                this.mContractAddStep2.args.id_document_data_version = this.mContractAddStep1.return.id_document_data_version;
                this.mContractAddStep2.args.id_document_const_type = this.mContractAddStep1.return.id_document_const_type;
                this.mContractAddStep2.args.id_person_data_main = this.profile.id;
                this.mContractAddStep2.args.applicant_type = this.profile.person_data.applicant_type;
                this.mContractAddStep2.args.faseb_contracts = this.profile.faseb_contracts;
                this.mContractAddStep2.show = true;
                this.mContractAddStep1.result = 0;
            }
        },
        "mContractAddStep2.result": function(val) {
            if (val === 1) {
                this.mContractAddStep2.show = false;
                this.mContractAddStep2.result = 0;
            }
        }
    },

    computed: {

        showActionPoints () {
            if([2,4,100,1002,8].includes(this.profile.person_data.id_status_const_type)) {
                return true;
            } else {
                return false;
            }
        },
        cProfile: {
            get() { return this.profile },
            set(value) { this.$emit('update:profile', value) }
        } 
    },

    data() {
        return {
            id_person_data_main: this.$route.params.id_person_data_main,
            alert_class: new AppAlerts(),
            loading: false,
            loadingAddTrainingContract: false,
            loadingSkipTrainingContract: false,
            mContractAddStep1: {show: false, args: {applicant_type : null}, result: 0, return: {id_document_data_version: 0, id_document_const_type: 0}},
            mContractAddStep2: {show: false, args: {id_document_data_version: 0, id_document_const_type: 0, faseb_contracts: null }, result: 0, return: this.profile},
            mPayrollTaxAdd: {show: false, args: {id_person_data_main: null, filters: null, return_type: null}, result: 0, return: null},
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
        };
    },

    methods: {
        sendWebForm(){
            this.alert_class.openAlertConfirmation(`${this.trans('send-registration-form-confirmation',177,{email:this.profile.person_data.email})}`).then(res => {
                this.loading = true;
                if(res == true){
                    axios 
                        .post("people/profile/action-points/sendWebForm", {
                            id_person_data_main: this.profile.id
                        })
                        .then((res) => {
                            if(res.data.length > 0){
                                this.cProfile.person_data = res.data[0];
                            }
                            this.loading = false;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                            this.loading = false;
                        });     
                }
            }); 
            
        },

        activatePerson(){
            this.alert_class.openAlertConfirmation(`${this.trans('fp-restore-person-confirmation',177, {first_name:this.profile.person_data.first_name, inbetween_name:this.profile.person_data.inbetween_name ? this.profile.person_data.inbetween_name : '', last_name: this.profile.person_data.last_name})}`).then(res => {
                if(res == true){
                    this.loading = true;

                    axios 
                        .post("people/profile/action-points/changeStatus", {
                            id_person_data_main: this.profile.id,
                            id_status_const_type: 100,
                        })
                        .then((res) => {
                            if(res.data.length > 0){
                                this.cProfile.person_data = res.data[0];
                            }
                            this.loading = false;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                            this.loading = false;
                        });     
                }
            });
        },

        activatePersonPassive(){
            this.alert_class.openAlertConfirmation(`${this.trans('fp-activate-person-confirmation',177, {person_name:this.profile.person_data.profile_name})}`).then(res => {
                if(res == true){
                    this.loading = true;

                    axios 
                        .post("people/profile/action-points/activatePersonPassive", {
                            id_person_data_main: this.profile.id,
                            id_status_const_type: 100,
                        })
                        .then((res) => {
                            if(res.data.length > 0){
                                this.cProfile.person_data = res.data[0];
                            }
                            this.loading = false;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                            this.loading = false;
                        });     
                }
            });
        },



        /*showConfirmation(){

            this.alert_class.openAlertConfirmation("Weet je zeker dat je het document wil laten controleren?").then(res => {
                if(res == true){
                    this.checkData();        
                }
            });    
        
        },*/

        addContract() {
            this.mContractAddStep1.args.applicant_type = this.profile.person_data.applicant_type;           
            this.mContractAddStep1.show = true;
        },

        addPayrollTax(){
            this.mPayrollTaxAdd.args.id_person_data_main = this.profile.id;
            this.mPayrollTaxAdd.args.return_type = "action_point";
            this.mPayrollTaxAdd.show = true;
        },

        
       
    }
 
};
</script>

<style>

.dotActive {
  height: 10px;
  width: 10px;
  margin-right: 6px;
  background-color: #43a047;
  border-radius: 50%;
  display: inline-block;
}

.dotInactive {
  height: 10px;
  width: 10px;
  margin-right: 6px;
  background-color: #e53935;
  border-radius: 50%;
  display: inline-block;
}

.dotProgress {
  height: 10px;
  width: 10px;
  margin-right: 6px;
  background-color: #fdd835;
  border-radius: 50%;
  display: inline-block;
}

.checkbox-green .custom-control-input:checked ~ .custom-control-label::before{
    background-color: #12B76A !important;
}

.app-action-status-red-text{
    font-size: 12px;
    font-weight: 600;
    color: #f04438 !important;
}

.app-action-status-gray-text{
    font-size: 12px;
    color: #8898aa;
}

</style>