<template>
    <div id="popover-id-ef" tabindex="0" v-if="this.profile.person_data.register_status === 2" class="app-local-applicant-type" v-bind:class="{ 'app-cursor-pointer': !loading}" style="margin-top:2px;">
            
            <span v-bind:class="{ 
                                    'dot-silver' : this.profile.person_data.easyflex_status != null && this.profile.person_data.easyflex_status.status == 0,
                                    'dot-orange' : this.profile.person_data.easyflex_status != null && this.profile.person_data.easyflex_status.status == 1,
                                    'dot-green' : this.profile.person_data.easyflex_status != null && this.profile.person_data.easyflex_status.status == 2, 
                                    'dot-red' : this.profile.person_data.easyflex_status != null && this.profile.person_data.easyflex_status.status == 3 
                                }"> </span> EF
            <b-spinner v-if="loading" style="width:12px; height:12px; margin-bottom:3px; margin-left:3px;" small></b-spinner>       
            <b-popover v-if="!loading" target="popover-id-ef" triggers="focus" no-fade custom-class="app-local-popover">
                <template #title>
                    <!-- {{trans('rejected-by-client',180)}} -->
                    {{trans('settings-easyflex',167)}}
                </template>
                
                <div style="min-width:280px;" class="mt-2" :class="{'mb-2': user_role > 1}">
                 
                    <b-row v-if="this.profile.person_data.easyflex_status.basic != null">
                        <b-col cols="7" class="mr-0 pr-0 d-flex"> <div :class="getDotClass(this.profile.person_data.easyflex_status.basic.status) + ' mt-1'"/> <div class="text-nowrap text-left"> {{trans('fp-ef-flexworker-data',273)}} </div> </b-col>
                        <b-col cols="5" class="ml-0 ml-0 text-right font-weight-600"> {{getStatusName(this.profile.person_data.easyflex_status.basic.status)}} </b-col>
                    </b-row>

                    <div class="text-muted mt-2" v-if="this.profile.person_data.easyflex_status.basic.status == 3"> {{ this.profile.person_data.easyflex_status.basic.error }} 
                        <span class="app-text-link" v-if="user_role == 1 && this.profile.person_data.easyflex_status.basic.status == 3 && this.profile.person_data.easyflex_status.basic.error_code == '001' " @click="updateRegistrationNumber(profile.person_data.easyflex_status.basic.registration_number)">Connect ID</span>
                    </div>

                    <!--<div class="text-right mt-2" v-if="user_role == 1 && this.profile.person_data.easyflex_status.basic.status == 3 && this.profile.person_data.easyflex_status.basic.error_code == '001' " >
                        <app-button type="primary" @click="updateRegistrationNumber(profile.person_data.easyflex_status.basic.registration_number)" :loading="loading_registration_number" :disabled="loading"> Connect ID </app-button>
                    </div>-->

                    <hr class="app-search-modal-separator mt-2 mb-2">
                    
                    <b-row v-if="this.profile.person_data.easyflex_status.payroll != null">
                        <b-col cols="7" class="mr-0 pr-0 d-flex"><div :class="getDotClass(this.profile.person_data.easyflex_status.payroll.status) + ' mt-1'" /> <div class="text-nowrap text-left"> {{trans('fp-ef-payroll-tax-statement',273)}} </div> </b-col>
                        <b-col cols="5" class="ml-0 ml-0 text-right font-weight-600"> {{getStatusName(this.profile.person_data.easyflex_status.payroll.status)}} </b-col>
                    </b-row>

                    <div class="text-muted mt-2" v-if="this.profile.person_data.easyflex_status.payroll.status == 3"> {{ this.profile.person_data.easyflex_status.payroll.error }} </div>
                    <hr class="app-search-modal-separator mt-2 mb-2">

                    <b-row v-if="this.profile.person_data.easyflex_status.pay_period != null">
                        <b-col cols="7" class="mr-0 pr-0 d-flex"><div :class="getDotClass(this.profile.person_data.easyflex_status.pay_period.status) + ' mt-1'" /> <div class="text-nowrap text-left"> {{trans('fp-ef-pay-frequency',273)}} </div> </b-col>
                        <b-col cols="5" class="ml-0 ml-0 text-right font-weight-600"> {{getStatusName(this.profile.person_data.easyflex_status.pay_period.status)}} </b-col>
                    </b-row>

                    <div class="text-muted mt-2" v-if="this.profile.person_data.easyflex_status.pay_period.status == 3"> {{ this.profile.person_data.easyflex_status.pay_period.error }} </div>
                    <hr class="app-search-modal-separator mt-2 mb-2">

                    <b-row v-if="this.profile.person_data.easyflex_status.contract != null">
                        <b-col cols="7" class="mr-0 pr-0 d-flex"><div :class="getDotClass(this.profile.person_data.easyflex_status.contract.status) + ' mt-1'" /> <div class="text-nowrap text-left"> {{trans('fp-ef-contract',273)}} </div> </b-col>
                        <b-col cols="5" class="ml-0 ml-0 text-right font-weight-600"> {{getStatusName(this.profile.person_data.easyflex_status.contract.status)}} </b-col>
                    </b-row>

                    <div class="text-muted mt-2" v-if="this.profile.person_data.easyflex_status.contract.status == 3"> {{ this.profile.person_data.easyflex_status.contract.error }} </div>
                    <hr class="app-search-modal-separator mt-2 mb-2">

                    <b-row v-if="this.profile.person_data.easyflex_status.bonus != null">
                        <b-col cols="7" class="mr-0 pr-0 d-flex"><div :class="getDotClass(this.profile.person_data.easyflex_status.bonus.status) + ' mt-1'" /> <div class="text-nowrap text-left"> {{trans('fp-ef-premium',273)}} </div> </b-col>
                        <b-col cols="5" class="ml-0 ml-0 text-right font-weight-600"> {{getStatusName(this.profile.person_data.easyflex_status.bonus.status)}} </b-col>
                    </b-row>

                    <div class="text-muted mt-2" v-if="this.profile.person_data.easyflex_status.bonus.status == 3"> {{ this.profile.person_data.easyflex_status.bonus.error }} </div>
                

                    <div class="text-center" v-if="user_role == 1">
                        <hr class="app-search-modal-separator mt-3 mb-2">
                        <app-button type="secondary-grey" @click="syncNow" :loading="loading" :disabled="loading_registration_number"> {{trans('fp-ef-sync-now',273)}} </app-button>
                    </div>
                
                </div>
            
            </b-popover>
    </div>
</template>
<script>

import axios from "@axios";
export default {
    components: {
        
    },

    props:["profile"],

    data(){
        return{
            loading: false,
            loading_registration_number: false,
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
        }
    },

    computed: {
        cProfile: {
            get() { return this.profile },
            set() { this.$emit('update:profile', false) }, 
        }
    },

    methods:{
        getDotClass(status) {
            if (status == 0) return "dot-silver";
            if (status == 1) return "dot-orange";   
            if (status == 2) return "dot-green";
            if (status == 3) return "dot-red";
            return "";
        },

        getStatusName(status) {
            if (status == 0) return this.trans('fp-ef-sync-is-off',273);
            if (status == 1) return this.trans('fp-ef-updating',273);   
            if (status == 2) return this.trans('fp-ef-updated',273);
            if (status == 3) return this.trans('fp-ef-error',273);
            return "";
        },

         syncNow() {
            this.loading = true;
            axios
                .post("people/profile/status/syncEasyFlex", {
                    id_person_data_main: this.profile.id
                })
                .then((res) => {
                    this.cProfile.person_data = res.data;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        updateRegistrationNumber(registration_number){
            
            this.loading_registration_number = true;

            axios
                .post("people/profile/status/changeRegistrationNumber", {
                    id_person_data_main: this.profile.id,
                    registration_number: registration_number
                })
                .then((res) => {
                    this.cProfile.person_data = res.data;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_registration_number = false;
                });

        }
    }
};
</script>
