<template>
    <b-modal ref="modal-edit" v-model="computedShow" no-close-on-backdrop :title="trans('change-office',3)">
        <ValidationObserver ref="form" >
            <b-form @submit.prevent>
                <app-row-left-label :label="trans('fp-iban',184)">
                    <app-input v-model="cArgs.iban" validatorName="IBAN" validatorRules="required|eleven-proof" mask="lettersE3-32" />
                </app-row-left-label>

                <app-row-left-label :label="trans('different-beneficiary',184)">
                    <app-check-box id="vi-ifblocked" v-model="cArgs.iban_is_other_owner" />
                </app-row-left-label>

                <app-row-left-label :label="trans('full-name',3)" v-if="cArgs.iban_is_other_owner == 1">
                    <app-input v-model="cArgs.iban_other_owner_name" validatorName="Naam" validatorRules="required" mask="lettersE5-35" />
                </app-row-left-label>

                <app-row-left-label :label="trans('fp-iban',184)" v-if="cArgs.iban_is_other_owner == 1">
                    <app-input v-model="cArgs.iban_other" validatorName="IBAN" validatorRules="required|eleven-proof" mask="lettersE3-32" />
                </app-row-left-label>

            </b-form>
        </ValidationObserver>

        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="changeBank" :loading="loading">
                {{trans('save',175)}}
            </app-button>
        </template>
    </b-modal>
</template>

<script>
import axios from "@axios";
import { ValidationObserver } from "vee-validate";

export default {
    components: {
        ValidationObserver,
    },

    props: ["show", "result", "args", "return"],

    computed: {
        computedShow: {
            get() { return this.show },
            set() { this.$emit('update:show', false) }, 
        },
        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },
        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        }
    },

    data() {
        return {
            loading: false,
            second_iban:0
        };
    },

    methods: {
       changeBank() {

            this.$refs.form.validate().then((result) => {
                if (!result) {
                    return false;
                }else{
                    this.loading = true;
                    axios
                        .post("people/profile/bank/change", {
                            id_person_data_main: this.args.id_person_data_main,
                            iban: this.args.iban,
                            iban_is_other_owner: this.args.iban_is_other_owner,
                            iban_other_owner_name: this.args.iban_other_owner_name,
                            iban_other: this.args.iban_other
                        })
                        .then((res) => {
                            this.$emit("update:show", false);
                            this.$emit("update:result", 1);
                            this.cReturn.person_data = res.data;
                            this.loading = false;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        });
                    }
            })
        },
        
        closeModal() {
            this.$emit("update:show", false);
        },
    },
};
</script>

<style scoped></style>
