<template>
    <b-modal ref="modal-add" scrollable size="md" v-model="computedShow" no-close-on-backdrop :title="trans('fp-change-billings-modal',8)">
         <ValidationObserver ref="form" >
        <b-form @submit.prevent="onSubmit">

             <app-row-left-label :label="trans('fp-short-paym-term',8)">
                <app-radio-group v-model="cArgs.zzp_payment_deadline" :options="opt" validatorName="Verkorte betaaltermijn" validatorRules="required" />
            </app-row-left-label>

            <app-row-left-label :label="trans('btw-number',182)">
                    <app-input v-model="cArgs.btw_number" id="vi-btw-number" validatorName="BTW nummer" validatorRules="btw-number"  mask="BTW" :uppercase="true"/>
            </app-row-left-label> 

        </b-form>
        </ValidationObserver>

        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="change" :loading="loading">
                {{trans('save',175)}}
            </app-button>
        </template>

    </b-modal>
</template>

<script>

import axios from "@axios";
import { ValidationObserver } from "vee-validate";

export default {
    components: {
        ValidationObserver
    },

    props: ["show", "result","args", "return"],

    computed: {
        computedShow: {
            get() { return this.show },
            set(value) { this.$emit('update:show', value) }, 
        },
        
        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        },

         cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },
    },

    data() {
        return {
            loading: false,
        
            period_options:  [
                { value: 1, text: this.trans('week',3) },
                { value: 2, text: this.trans('4-weeks',3) },
                { value: 3, text: this.trans('month',3) },
            ],
            opt: [ { value: 1, text: this.trans('yes-option',3) }, { value: 0, text: this.trans('no-option',3) }],
        };
    },

    methods: {
       
        change() {
            this.$refs.form.validate().then((result) => {
                if (!result) {
                    return false;
                } else {
                        
                        this.loading = true;
                        axios
                            .post("people/profile/financial/change", {
                                id_person_data_main: this.args.id_person_data_main,
                                zzp_payment_deadline: this.cArgs.zzp_payment_deadline,
                                btw_number: this.cArgs.btw_number,
                                return_type: this.args.return_type
                            })
                            .then((res) => {

                                if(this.args.return_type == "financial"){
                                    this.cReturn.person_data = res.data;
                                    this.cReturn.compliance_check.refresh ++;
                                }
                                
                            })
                            .catch((error) => {
                                console.error(error);
                            })
                            .finally(() => {
                                this.loading = false;
                                this.$emit("update:show", false);
                                this.$emit("update:result", 1);
                                
                            });
                }})
        },

        closeModal() {
            this.$emit("update:show", false);
        },

    },
};
</script>

<style>
</style>
