var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-overlay',{attrs:{"show":_vm.loading_list,"opacity":0.0},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div')]},proxy:true}])},[_c('b-overlay',{attrs:{"show":_vm.loading_list,"opacity":0.0},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div')]},proxy:true}])},[_c('app-view-table',{attrs:{"title":_vm.trans('wage-statement',172),"loading":_vm.loading_list,"isTitleTotal":false,"filters":_vm.filters,"items":_vm.cProfile.loon_wages.payroll.items,"isDropdownMenu":_vm.profile.person_data.id_status_const_type != 1002,"isMenuColumn":_vm.user_role < 3 || _vm.profile.can_user_edit,"fields":_vm.fields},on:{"update:filters":function($event){_vm.filters=$event}},scopedSlots:_vm._u([{key:"menu-items",fn:function(cell){return [(cell.data.item.status == 1 || cell.data.item.status == 4)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.resendLink(cell.data.item)}}},[_vm._v(_vm._s(_vm.trans('resend-form',175)))]):_vm._e(),(cell.data.item.status != 2 )?_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteItem(cell.data.item)}}},[_vm._v(_vm._s(_vm.trans('remove',175)))]):_vm._e(),(cell.data.item.status == 2)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.scrollToDocument(cell.data.item)}}},[_vm._v(_vm._s(_vm.trans('show-document', 3)))]):_vm._e()]}},{key:"buttons",fn:function(){return [([1,3].includes(_vm.profile.person_data.applicant_type) && 
                                                 _vm.profile.person_data.id_status_const_type == 100 && 
                                                 _vm.profile.person_data.is_contract == 1 && 
                                                 _vm.profile.person_data.is_payroll_tax == 0 && (_vm.user_role < 3 || _vm.profile.can_user_edit))?_c('app-button',{attrs:{"type":"secondary","size":"sm"},on:{"click":_vm.addItem}},[_c('span',[_vm._v("Stuur loonheffing formulier")])]):_vm._e(),([1,3].includes(_vm.profile.person_data.applicant_type) && 
                                                 _vm.profile.person_data.id_status_const_type == 100 && 
                                                 _vm.profile.person_data.is_contract == 1 && 
                                                 _vm.profile.person_data.is_payroll_tax == 1 && 
                                                 (_vm.profile.person_data.payroll_tax_status == 2) && (_vm.user_role < 3 || _vm.profile.can_user_edit))?_c('app-button',{attrs:{"type":"secondary","size":"sm"},on:{"click":_vm.addItem}},[_c('span',[_vm._v(_vm._s(_vm.trans('cp-change-payroll-taxes',192)))])]):_vm._e()]},proxy:true}])})],1)],1),(_vm.mAdd.show)?_c('people-profile-loon-wages-payroll-add',{attrs:{"show":_vm.mAdd.show,"result":_vm.mAdd.result,"args":_vm.mAdd.args,"return":_vm.profile},on:{"update:show":function($event){return _vm.$set(_vm.mAdd, "show", $event)},"update:result":function($event){return _vm.$set(_vm.mAdd, "result", $event)},"update:args":function($event){return _vm.$set(_vm.mAdd, "args", $event)},"update:return":function($event){_vm.profile=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }