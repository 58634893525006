<template>
        <b-modal ref="modal-change" size="xl" v-model="computedShow" no-close-on-backdrop title="Mobile Dashboard">
        
            <b-card>
                <app-view-table title="Planned" isTitleTotal :isTitle="true" :fields="fields" :items="items_planned" :filters="filters" :loading="loading_list" :topFiltersCount="1">

                    <template v-slot:menu-items="cell">
                        <b-dropdown-item @click="openShift(cell.data.item)">{{trans('to-service',200)}}</b-dropdown-item>
                    </template>

                </app-view-table>

            </b-card>

            <b-card class="mt-3">
                <app-view-table title="Rejected" isTitleTotal :isTitle="true" :fields="fields" :items="items_rejected" :filters="filters" :loading="loading_list" :topFiltersCount="1">

                    <template v-slot:menu-items="cell">
                        <b-dropdown-item @click="openShift(cell.data.item)">{{trans('to-service',200)}}</b-dropdown-item>
                    </template>

                </app-view-table>

            </b-card>

            <b-card class="mt-3">
                <app-view-table title="Requested" isTitleTotal :isTitle="true" :fields="fields" :items="items_requested" :filters="filters" :loading="loading_list" :topFiltersCount="1">

                    <template v-slot:menu-items="cell">
                        <b-dropdown-item @click="openShift(cell.data.item)">{{trans('to-service',200)}}</b-dropdown-item>
                    </template>

                </app-view-table>

            </b-card>

            <template #modal-footer>

                    <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            </template>

            <people-profile-plan-preferences-shift-data-component v-if="shift_profile" :show.sync="shift_profile" :id.sync="id_shift_data_main" :idPersonDataMain="cArgs.id_person_data_main" />
            
        </b-modal>
</template>
<script>
import axios from "@axios";
import moment from "moment";
import PeopleProfilePlanPreferencesShiftDataComponent from './PeopleProfilePlanPreferencesShiftDataComponent.vue'

export default {

    components: {
        PeopleProfilePlanPreferencesShiftDataComponent
    },

    created(){
    },

    mounted(){
        this.getList();
    },

    props: ["show", "args", "return", "result"],

    computed: {
        computedShow: {
        get() { return this.show },
        set() { this.$emit('update:show', false) }, 
        },

        cArgs: {
            get() { return this.args },
            set() { this.$emit('update:args', false) }
        },

        cReturn: {
            get() { return this.return },
            set() { this.$emit('update:return', false) }
        },
    },

    watch: {
        "items.refresh": {
            handler: function(val){
                if(!this.loading_list){
                    this.getList();
                }
            }
        },

        "items_planned.refresh": {
            handler: function(val){
                if(!this.loading_list){
                    this.getList();
                }
            }
        },

        "items_requested.refresh": {
            handler: function(val){
                if(!this.loading_list){
                    this.getList();
                }
            }
        },

        "items_rejected.refresh": {
            handler: function(val){
                if(!this.loading_list){
                    this.getList();
                }
            }
        },


        "filters.per_page": {
            handler: function(val){
                if(!this.loading_list){
                    this.filters.page = 1;
                    this.getList();
                }
            }
        },

        "filters.page": {
            handler: function(val){
                if(!this.loading_list){
                    this.getList();
                }
            }
        },

        "filters.changed": {
            handler: function(val){
                if(!this.loading_list){
                    this.filters.page = 1;
                    this.getList();
                }
            }
        },

        "filters.sorting.changed":{
            handler: function(val){
                if(!this.loading_list){
                    this.getList();
                }
            }
        },

        "filters.filtering.client_names.changed": {
            handler: function(val){
                if(!this.loading_list){
                    this.getList();
                }
            }
        },

        "filters.filtering.client_locations.changed": {
            handler: function(val){
                if(!this.loading_list){
                    this.getList();
                }
            }
        },

        "filters.filtering.client_departments.changed": {
            handler: function(val){
                if(!this.loading_list){
                    this.getList();
                }
            }
        },

        "filters.filtering.functions.changed": {
            handler: function(val){
                if(!this.loading_list){
                    this.getList();
                }
            }
        },

        "filters.filtering.dates.changed": {
            handler: function(val){
                if(!this.loading_list){
                    this.getList();
                }
            }
        }
        
    },  

    data(){
        return{
            loading: false,
            loading_list: false,
            items_planned: {list: [], total: null, refresh :0},
            items_rejected: {list: [], total: null, refresh :0},
            items_requested: {list: [], total: null, refresh :0},
            filters: {

                filtering:{
                    client_names: { type: 'list', changed: 0, values: [], function: "getClients", parameters: null, widthClass:'app-width-min-350'},           
                    client_locations: { type: 'list', changed: 0, values: [], function: "getClientLocations", parameters: null, widthClass:'app-width-min-350'},
                    client_departments: { type: 'list', changed: 0, values: [], function: "getClientDepartments", parameters: null, widthClass:'app-width-min-350'},
                    functions: { type: 'list', changed: 0, values: [], function: "getFunctions", parameters: null, widthClass:'app-width-min-350'},
                    dates: { type: 'dates', changed: 0, values: [],  function: "", parameters: {type: null}, widthClass:'app-width-min-350'},
                },
                sorting: {
                        field_name: '',
                        sort_direction: 0,
                        changed: 0
                },
                search: "",
                page: 1,
                changed: 0,
                per_page: 10,
                total: 0,
                period: {
                    date_from: null,
                    date_to: null
                }

            },
            fields:[
                { key: "client_name", label: this.trans('organization',169), thStyle: { "min-width": "150px", "max-width": "150px" }, visible: true, filtering: false, filtering_name: "client_names"},
                { key: "location_name", label: this.trans('location',3), thStyle: { "min-width": "150px", "max-width": "150px" }, visible: true, filtering: false, filtering_name: "client_locations"},
                { key: "department_name", label: this.trans('department',182), thStyle: { "width": "100%" }, visible: true, filtering: false, filtering_name: "client_departments"},
                { key: "function_name", label: this.trans('function',3), thStyle: { "min-width": "150px", "max-width": "150px" }, visible: true, filtering: false, filtering_name: "functions"},
                { key: "date_from", label: this.trans('date',182), thStyle: { "min-width": "100px", "max-width": "100px" }, visible: true, filtering: false, filtering_name: "dates"},
                { key: "time_from", label: this.trans('planning-begin',209), thStyle: { "min-width": "80px", "max-width": "80px" }, visible: true, filtering: false},
                { key: "time_to", label: this.trans('planning-end',209), thStyle: { "min-width": "80px", "max-width": "80px" }, visible: true, filtering: false},
            ],
            clients: null,
            shift_profile: false,
            id_shift_data_main: null
        }
    },

    methods: {

        getList(){
            this.loading_list = true;

                axios
                    .post('v1/plan/getDashboard', {
                        id_person_data_main: this.cArgs.id_person_data_main,
                        filters: JSON.stringify(this.filters)
                    })
                    .then((res) => {
                        this.items_planned.list = res.data.planned.items;
                        this.items_planned.total = res.data.planned.total;

                        this.items_rejected.list = res.data.rejected.items;
                        this.items_rejected.total = res.data.rejected.total;

                        this.items_requested.list = res.data.requested.items; 
                        this.items_requested.total = res.data.requested.total;   

                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    })
                    .finally(() => {
                        this.loading_list = false;
                    });
               
            
        },

        openShift(item){
            this.id_shift_data_main = item.item_id;
            this.shift_profile = true;
        },


        closeModal() {
            this.$emit("update:show", false);
        },

    }
    
}
</script>
<style scoped>

</style>