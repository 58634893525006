<template>
    <b-modal ref="modal-add" scrollable size="md" v-model="computedShow" no-close-on-backdrop :title="trans('fp-send-payroll',172)">
        <ValidationObserver ref="form" >
            <b-form @submit.prevent="onSubmit">

                <b-alert variant="warning" show class="mb-3 pb-1">
                    <div class="d-flex w-100 justify-content-between">
                        <div class="text-md-start mb-2">{{trans('entered-data-check-message',172)}}</div>
                    </div>
                </b-alert> 

                <app-row-left-label :label="trans('entry-date',182)">
                    <app-date id="vi-date-from" v-model="date_from" validatorRules="required|date-check" validatorName="Ingangsdatum" :loading="loading_get_data" :disabled="loading_get_data" :disabledDatesPass="disabled_dates_pass" />
                </app-row-left-label>
                <app-row-left-label :label="trans('apply-payroll-credit',184)">
                    <b-spinner class="app-local-spinner" v-if="loading_get_data" small></b-spinner>    
                    <app-radio-group v-if="!loading_get_data" v-model="payroll_taxes" :options="opt" validatorName="Loonheffingskorting toepassen" validatorRules="required" :validatorCustomMessage="{ required: trans('payroll-tax-required',177) }" :stacked="true"/>
                </app-row-left-label>

            </b-form>
        </ValidationObserver>
        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="addItem" :loading="loading">
                {{trans('add',175)}}
            </app-button>
        </template>
    </b-modal>
</template>

<script>

import axios from "@axios";
import { ValidationObserver } from "vee-validate";
import moment from "moment";

export default {
    components: {
        ValidationObserver,
    },

    props: ["show", "result", "args", "return"],

    created(){
        this.getData();
    },

    computed: {
        computedShow: {
            get() { return this.show },
            set() { this.$emit('update:show', false) }, 
        },

        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        },

        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },
    },

    data() {
        return {
            loading: false,    
            loading_get_data: false,      
            opt: [
                { value: 1, text: this.trans('yes-with-credit',184) },
                { value: 2, text: this.trans('yes-without-credit',184) },
                { value: 3, text: this.trans('no-option',3)},
                { value: 4, text: this.trans('anonymous-rate',184)}
            ],            
            payroll_taxes: null,
            date_from: null,
            refresh:0,
            disabled_dates_pass: null
        };
    },

    methods: {
        getData(){
            this.loading_get_data = true;

            axios
                .post("people/profile/wage-details/getLastPayroll", {
                    id_person_data_main: this.args.id_person_data_main
                })
                .then((res) => {
                    this.payroll_taxes = res.data ? res.data.id_payroll_taxes : null;
                    this.date_from = res.data ? moment(res.data.date_from, 'DD-MM-YYYY').add(1, 'd').format('DD-MM-YYYY') : moment(new Date()).format('DD-MM-YYYY');
                    this.disabled_dates_pass = moment().diff(moment(res.data.date_from, 'DD-MM-YYYY'), 'days');
                    this.refresh++;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                    this.loading_get_data = false;
                })
                .finally(() => {
                    this.loading_get_data = false;
                });

        },

        addItem() {
            this.$refs.form.validate().then((result) => {
                if (!result) { 
                    return false;
                } else {
                    this.loading = true;                      
                    axios
                        .post("people/profile/wage-details/addPayroll", {
                            id_person_data_main: this.args.id_person_data_main,
                            date_from: this.date_from,
                            id_payroll_taxes: this.payroll_taxes,
                            return_type: this.args.return_type,
                            filters: JSON.stringify(this.args.filters),
                        })
                        .then((res) => {
                            
                            this.$emit("update:show", false);
                            this.$emit("update:result", 1);

                            if(this.args.return_type == "action_point"){
                                this.cReturn.person_data = res.data;
                                this.cReturn.loon_wages.payroll.items.refresh ++;
                            }else if(this.args.return_type == "wages"){
                                this.cReturn.loon_wages.payroll.items = res.data.items;
                                this.cReturn.refresh ++;
                            }
                            
                            this.loading = false;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                            this.loading = false;
                        });
                }
            });

                
           
        },

        closeModal() {
            this.$emit("update:show", false);
        },
    },
};
</script>

<style scoped>
.app-local-spinner{
    width:19px; 
    height:19px; 
    margin-left:2px;
}
</style>
