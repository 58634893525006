<template>
    <b-modal ref="modal-add" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('edit-address-details',172)">
        <ValidationObserver ref="form" >
            <b-form @submit.prevent="onSubmit">
               <app-address ref="address_control" :required="true" :address.sync="cArgs" />
            </b-form>
        </ValidationObserver>

        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="change" :loading="loading">{{trans('save',175)}}</app-button>
        </template>
    </b-modal>
</template>

<script>
import axios from "@axios";

import { ValidationObserver } from "vee-validate";
import AppAddress from "@core/components/Address/AppAddress.vue";

export default {
    components: {
        ValidationObserver,
        AppAddress
    },

    props: ["show", "args", "return", "result"],

    created(){
        if(this.args.country.value == null){
            this.cArgs.country = null;
        }
    },

    computed: {
        computedShow: {
            get() { return this.show },
            set() { this.$emit('update:show', false) }, 
        },
        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },
        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        }
    },

    data() {
        return {
            loading: false,
        };
    },

    methods: {    

        async change() {
            this.$refs.form.validate().then((result) => {
                if (!result) {
                    return false;
                }
                else {
                    if(!this.$refs.address_control.address.street){
                        return false;
                    }else{
                        this.loading = true;  
                                        
                        axios
                            .post("people/profile/address/change", {
                                id_person_data_main: this.args.id_person_data_main,
                                post_code: this.args.post_code,
                                house_number: this.args.house_number,
                                number_additional: this.args.house_number_additional,
                                street: this.args.street,
                                city: this.args.city,
                                latitude: this.args.latitude,
                                longitude: this.args.longitude,
                                id_country_data_main: this.args.country.value,
                                country_name: this.args.country.name,
                                address_type: this.args.type
                            })
                            .then((res) => {
                                this.$emit("update:show", false);
                                this.$emit("update:result", 1);
                                this.cReturn.person_data = res.data;
                                this.loading = false;
                            })
                            .catch((error) => {
                                console.error(`error during request: ${error}`);
                            });
                    }
                }
            })            
        },

        closeModal() {
            this.$emit("update:show", false);
        },

    },
};
</script>

<style>

</style>
