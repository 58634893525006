<template>
    <b-modal ref="modal-add" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('fp-scaling-title',172)">
        <ValidationObserver ref="form" >
            <b-form @submit.prevent="onSubmit">

                <app-row-left-label :label="trans('date-from',182)">
                    <app-date v-model="cArgs.date_from" validatorName="Startdatum" validatorRules="required" />
                </app-row-left-label>
                
                <app-row-left-label :label="trans('cao',182)" >
                    <app-select v-model="cArgs.section" type="getSectionsShortcuts" validatorName="CAO" validatorRules="required" :refreshIndex.sync="refresh_cao" :disable="!cArgs.date_from"/>
                </app-row-left-label>

                <app-row-left-label :label="trans('scale',182)" >
                    <app-select v-model="cArgs.scale_value" type="getSectionsScales" validatorName="Schaal" :args.sync="users_args"  validatorRules="required" :refreshIndex.sync="refresh_scale" :disable="!cArgs.section"/>
                </app-row-left-label>

                <app-row-left-label :label="trans('fp-periodic',184)">
                    <app-select v-model="cArgs.level" type="getSectionsLevels" validatorName="Periodic" :args.sync="users_args"  validatorRules="required" :refreshIndex.sync="refresh_level" :disable="!cArgs.scale_value"/>
                </app-row-left-label>

                <app-row-left-label :label="trans('cao-hourly-wage',184)">
                    <app-input v-model="cArgs.rate" :placeholder="'00,00'" :disabled="true" :loading="loading_rate" />
                </app-row-left-label>
                
            </b-form>
        </ValidationObserver>

        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="change" :loading="loading">
                {{trans('save',175)}}
            </app-button>
        </template>
    </b-modal>
</template>
<script>

import axios from "@axios";
import { ValidationObserver } from "vee-validate";

export default {

    components:{
        ValidationObserver,
    },

    props: ["show", "result", "args", "return"],

    created(){
        this.users_args.id_cao_data_main = this.cArgs.section.value;
        this.users_args.scale_value = this.cArgs.scale_value.name;
        this.users_args.date_from = this.cArgs.date_from;
    },

    computed: {
        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },

        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        },

        computedShow: {
            get() { return this.show },
            set(value) { this.$emit('update:show', value) }
        }
    },

    watch: {

        "cArgs.date_from":{
            handler: function(value){
                if(this.cArgs.date_from !== null){
                    this.users_args.date_from = this.cArgs.date_from;
                    if(this.cArgs.section  != null){
                        this.cArgs.section.name = "";
                        this.cArgs.section.value = null;
                        this.cArgs.section = null;
                    }
                    if(this.cArgs.scale_value  != null){
                        this.cArgs.scale_value.name = "";
                        this.cArgs.scale_value.value = null;
                        this.cArgs.scale_value = null;
                    }
                    if(this.cArgs.level  != null){
                        this.cArgs.level.name = "";
                        this.cArgs.level.value = null;
                        this.cArgs.level = null;
                    }
                    this.cArgs.rate = null;
                    this.refresh_cao++;
                }
            }
        },

        "cArgs.section": {
            handler: function(value){
                if(this.cArgs.section !== null){
                    this.users_args.id_cao_data_main = this.cArgs.section.value;
                    if(this.cArgs.scale_value  != null){
                        this.cArgs.scale_value.name = "";
                        this.cArgs.scale_value.value = null;
                        this.cArgs.scale_value = null;
                    }
                    if(this.cArgs.level  != null){
                        this.cArgs.level.name = "";
                        this.cArgs.level.value = null;
                        this.cArgs.level = null;
                    }
                    this.cArgs.rate = null;
                    this.refresh_scale++;
                }
            },
            deep: true
        },

        "cArgs.scale_value":{
            handler: function(value){
                if(this.cArgs.scale_value !== null){
                    this.users_args.scale_value = this.cArgs.scale_value.value;
                    if(this.cArgs.level  != null){
                        this.cArgs.level.name = "";
                        this.cArgs.level.value = null;
                        this.cArgs.level = null;
                    }
                    this.cArgs.rate = null;
                    this.refresh_level++;
                }
            }
        },

        "cArgs.level": {
            handler: function(value) {
                if(this.cArgs.date_from != null && this.cArgs.section != null && this.cArgs.scale_value != null && this.cArgs.level != null){
                    this.loadRate();
                }
            }
        }
    },

    data(){
        return{
            loading: false,
            loading_rate: false,
            refresh_cao:0,
            refresh_scale:0,
            refresh_level:0,
            users_args:{
                id_cao_data_main:null,
                scale_value:null,
                date_from:null,
            },
        }
    },

    methods: {

        change(){
            this.$refs.form.validate().then((result) => {
                if (!result) {
                    return false;
                }else{
                    this.loading = true;
                    axios
                        .post('people/profile/wages/changePayroll', {
                            id_person_data_main: this.cArgs.id_person_data_main,
                            id_wage_data_payroll: this.args.id_wage_data_payroll,
                            date_from: this.cArgs.date_from,
                            id_cao_data_main: this.cArgs.section.value,
                            scale: this.cArgs.scale_value.name,
                            level: this.cArgs.level.name,
                            filters: JSON.stringify(this.cArgs.filters)
                        }).
                        then((res) => {
                            this.cReturn.wages.payroll.items = res.data.items;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            this.loa = false;
                            this.computedShow = false;
                        });

                }
            });

        },

        loadRate(){
            this.loading_rate = true;

            axios
                .post('people/profile/wages/getPayrollRate', {
                    date_from: this.cArgs.date_from,
                    id_cao_data_main: this.cArgs.section.value,
                    scale: this.cArgs.scale_value.name,
                    level: this.cArgs.level.name
                })
                .then((res) => {
                    this.cArgs.rate = res.data.toString().replace('.', ',');
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_rate = false;
                })
        },

        closeModal(){
            this.computedShow = false;
        }
    }

    
}
</script>
<style>
</style>