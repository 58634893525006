<template>
    <div>
        <div v-if="profile.person_data.applicant_type > 0">
            <b-row  v-if="profile.person_data.id_status_const_type > 2 && (user_role < 3 || profile.can_user_edit) && profile.person_data.id_status_const_type != 1002" class="app-component-icon-edit">
                <b-dropdown variant="link"  no-caret right>
                    <template #button-content >
                        <b-icon icon="three-dots-vertical" class="text-body"></b-icon>
                    </template>
                    <b-dropdown-item @click="change()" v-if="profile.person_data.applicant_type != 2 && user_role < 3">{{trans('change',175)}}</b-dropdown-item>
                    <b-dropdown-item @click="changeBsn()" v-if="[1, 3, 4].includes(profile.person_data.applicant_type)">{{trans('change-bsn',174)}}</b-dropdown-item>                                        
                    <b-dropdown-item @click="changeBig()" v-if="profile.person_data.big_required == 1">{{trans('change-big',8)}}</b-dropdown-item>
                    <b-dropdown-item @click="changeKVK()" v-if="profile.person_data.applicant_type === 2">{{trans('change-kvk',8)}}</b-dropdown-item>
                </b-dropdown>
            </b-row>            

                    
            <b-row class="app-card-header mb-2" v-if="[1, 3, 4].includes(profile.person_data.applicant_type)">
                <b-col start>
                    <p class="col-list-label">{{trans('id-label',184)}}</p>   
                    <p class="col-list-label">{{trans('document-number-label',184)}}</p>
                    <p class="col-list-label">{{trans('expiration-date',3)}}</p>
                    <p class="col-list-label">{{trans('bsn-number',184)}}</p>    
                    <p class="col-list-label">{{trans('nationality',184)}}</p>                
                </b-col>
                <b-col>
                    <p class="col-form-value">{{profile.person_data.document_type_name ? profile.person_data.document_type_name : '-'}}</p>
                    <p class="col-form-value">{{profile.person_data.document_number ? profile.person_data.document_number : '-'}}</p>
                    <p class="col-form-value">{{profile.person_data.document_expiry_date ? profile.person_data.document_expiry_date : '-'}}</p>
                    <p class="col-form-value" v-if="[1, 3, 4].includes(profile.person_data.applicant_type)">{{profile.person_data.bsn_number ? profile.person_data.bsn_number : '-'}}</p>
                    <p class="col-form-value">{{profile.person_data.nationality_name ? profile.person_data.nationality_name : '-'}}</p>         
                </b-col>                
            </b-row>          
            
            <b-row class="app-card-header" v-if="profile.person_data.big_required == 1">
                <b-col>
                     <div class="col-list-label"><b>{{trans('big',184)}}</b></div>
                </b-col>
            </b-row>
            <b-row  v-if="profile.person_data.big_required == 1">
                <b-col>
                    <p class="col-list-label">{{trans('registered',3)}}</p> 
                </b-col>
                <b-col>
                    <p class="col-form-value">{{isBIG}}</p>
                </b-col>  
            </b-row>
             <b-row v-if="profile.person_data.big_required == 1 && profile.person_data.is_big_number === 1">
                <b-col>
                    <p class="col-list-label">{{trans('number',182)}}</p> 
                    <p class="col-list-label">{{trans('f-gender',184)}}</p>
                    <p class="col-list-label">{{trans('full-name',3)}}</p>
                    <p class="col-list-label">{{trans('profession-description',184)}}</p>
                    <p class="col-list-label">{{trans('check-date',184)}}</p>
                </b-col>
                <b-col>
                    <p class="col-form-value">{{profile.person_data.big_number ? profile.person_data.big_number : '-'}}</p>
                    <p class="col-form-value">{{profile.person_data.big_data_main ? JSON.parse(profile.person_data.big_data_main).gender : '-'}}</p>
                    <p class="col-form-value">{{profile.person_data.big_data_main ? JSON.parse(profile.person_data.big_data_main).mailing_name : '-'}}</p>
                    <p class="col-form-value">{{profile.person_data.big_data_main ? JSON.parse(profile.person_data.big_data_main).professional_group_description : '-'}}</p>
                    <p class="col-form-value">{{profile.person_data.big_check_date ? profile.person_data.big_check_date : '-'}}</p>
                </b-col>  
            </b-row>

            <b-row class="app-card-header"  :class="{'mt-2': profile.person_data.big_required == 1}" v-if="profile.person_data.applicant_type === 2">
                 <b-col>
                     <div class="col-list-label"><b>KVK</b></div>
                </b-col>
            </b-row>

            <b-row  v-if="profile.person_data.applicant_type === 2">
                <b-col>
                    <p class="col-list-label">{{trans('number',182)}}</p>
                    <p class="col-list-label">{{trans('fp-comapny-name',184)}}</p>
                    <p class="col-list-label">{{trans('street',182)}}</p>
                    <p class="col-list-label">{{trans('residence',182)}}</p>
                    <p class="col-list-label">{{trans('check-date',184)}}</p>
                </b-col>
                <b-col>
                    <p class="col-form-value">{{profile.person_data.kvk_number ? profile.person_data.kvk_number : '-'}}</p>
                    <p class="col-form-value">{{profile.person_data.kvk_data_main ? JSON.parse(profile.person_data.kvk_data_main).trade_name : '-'}}</p>
                    <p class="col-form-value">{{profile.person_data.kvk_data_main ? JSON.parse(profile.person_data.kvk_data_main).street : '-'}}</p>
                    <p class="col-form-value">{{profile.person_data.kvk_data_main ? JSON.parse(profile.person_data.kvk_data_main).city : '-'}}</p>
                    <p class="col-form-value">{{profile.person_data.kvk_check_date ? profile.person_data.kvk_check_date : '-'}}</p>
                </b-col> 
            </b-row>
           
        </div>
        <div v-else>
             <b-row class="app-card-header">
                <b-col>
                    <p class="col-list-label">{{trans('contact-form-not-determined',184)}}</p>
                </b-col>
             </b-row>            
        </div>
        
         <people-profile-identity-change-bsn v-if="mChangeBsn.show" :show.sync="mChangeBsn.show" :result.sync="mChangeBsn.result" :args.sync="mChangeBsn.args" :return.sync="profile"/>
        <people-profile-identity-change-big v-if="mChangeBig.show" :show.sync="mChangeBig.show" :result.sync="mChangeBig.result" :args="mChangeBig.args" :return.sync="profile"/>
        <people-profile-identity-change-kvk v-if="mChangeKvk.show" :show.sync="mChangeKvk.show" :result.sync="mChangeKvk.result" :args="mChangeKvk.args" :return.sync="profile"/> 
        <people-profile-identity-change v-if="mChange.show" :show.sync="mChange.show" :result.sync="mChange.result" :args.sync="mChange.args" :return.sync="profile"/>

    </div>
</template>
<script>

import PeopleProfileIdentityChangeBig from '@app/People/Profile/Identity/PeopleProfileIdentityChangeBig.vue';
import PeopleProfileIdentityChangeBsn from "@app/People/Profile/Identity/PeopleProfileIdentityChangeBsn.vue";
import PeopleProfileIdentityChangeKvk from '@app/People/Profile/Identity/PeopleProfileIdentityChangeKvk.vue';
import PeopleProfileIdentityChange from '@app/People/Profile/Identity/PeopleProfileIdentityChange.vue';

export default {
    components:  {
        PeopleProfileIdentityChangeBig,
        PeopleProfileIdentityChangeBsn,    
        PeopleProfileIdentityChangeKvk,
        PeopleProfileIdentityChange,
            
    },

    props:["profile"],

    computed: {
        
        isBIG() {
            if(this.profile.person_data.is_big_number !== undefined && this.profile.person_data.is_big_number !== null) {
                if(this.profile.person_data.is_big_number === 1) {
                    return this.trans('yes-option',3);
                } else {
                    return this.trans('no-option',3);
                }
            } else {
                return "-";
            }
        }
    },

    data(){
            return{
                mChangeBsn:{ show: false, args: {id_person_data_main: null, is_bsn_number: null, bsn_number: null}, result: 0, return: null },
                mChangeBig: { show: false, args: {id_person_data_main: null, is_big_number: null, big_data_main: null, big_check_date: null, big_number: null, return_type: null}, result: 0, return: null },
                mChangeKvk: { show: false, args: {id_person_data_main: null, kvk_data_main: null, kvk_check_date: null, kvk_number: null, return_type: null}, result: 0, return: null },
                mChange:{ show: false, args: {id_person_data_main: null, document_number: null, document_expiry_date: null}, result: 0, return: null },
                user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
            }
        },


    methods:{
         changeBsn(){
            this.mChangeBsn.args.id_person_data_main = this.profile.id;
            this.mChangeBsn.args.bsn_number = this.profile.person_data.bsn_number;
            this.mChangeBsn.show = true;
        },

        change(){
            this.mChange.args.id_person_data_main = this.profile.id;
            this.mChange.args.document_number = this.profile.person_data.document_number;
            this.mChange.args.document_expiry_date = this.profile.person_data.document_expiry_date;
            this.mChange.show = true;
        },
        
        changeBig(){
            this.mChangeBig.args.id_person_data_main = this.profile.id;
            this.mChangeBig.args.is_big_number = this.profile.person_data.is_big_number;
            this.mChangeBig.args.big_data_main = this.profile.person_data.big_data_main;
            this.mChangeBig.args.big_check_date = this.profile.person_data.big_check_date;
            this.mChangeBig.args.big_number = this.profile.person_data.big_number;
            this.mChangeBig.args.return_type = "identity";
            this.mChangeBig.show = true;
        },

        changeKVK() {
            this.mChangeKvk.args.id_person_data_main = this.profile.id;
            this.mChangeKvk.args.kvk_number = this.profile.person_data.kvk_number;
            this.mChangeKvk.args.kvk_data_main = this.profile.person_data.kvk_data_main;
            this.mChangeKvk.args.kvk_check_date = this.profile.person_data.kvk_check_date;
            this.mChangeKvk.args.return_type = "identity";
            this.mChangeKvk.show = true;
        },

    }
 
};
 
</script>
<style scoped></style>