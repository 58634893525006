<template>
    <b-modal ref="modal-add" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('change-kvk-title',3)">
        
            <ValidationObserver ref="form" >
            <b-form @submit.prevent="onSubmit">
                <app-kvk ref="kvk_control" :kvkData.sync="cArgs" :label="trans('number',182)" :validatorRules="'required'" :checkIfExists="true"/>
            </b-form>
        </ValidationObserver>

        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="change()" :loading="loading">
                {{trans('save',175)}}
            </app-button>
        </template>
    </b-modal>
</template>

<script>
import axios from "@axios";
import AppKvk from "@core/components/AppKvk"
import { ValidationObserver } from "vee-validate";

export default {
    components: {
        ValidationObserver,
        AppKvk

    },

    props: ["show", "result", "args", "return"],

    computed: {
        computedShow: {
            get() { return this.show },
            set(value) { this.$emit('update:show', value) }, 
        },

        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },
        
        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        }
    },

    data() {
        return {
            loading: false,
        };
    },

    methods: {

   
        change(){
            
            this.$refs.form.validate().then((result) => {
                if (!result  ) { 
                    return false;
                }else{
                    if(!this.$refs.kvk_control.f_information){
                        !this.$refs.kvk_control.checkKvk();
                    }else{

                        this.loading = true;
                        axios
                            .post("people/profile/identity/changeKvk", {
                                id_person_data_main: this.cArgs.id_person_data_main,
                                kvk_number: this.cArgs.kvk_number,
                                kvk_data_main: JSON.stringify(this.cArgs.kvk_data_main),
                                return_type: this.cArgs.return_type
                            })
                            .then((res) => {
                                this.loading = 0;
                                this.$emit("update:result", 1);
                                this.$emit("update:show", false);

                                if(this.cArgs.return_type == "compliance_check"){
                                    this.cReturn.compliance_check.items = res.data.items;
                                    this.cReturn.compliance_check.correct = res.data.correct;
                                    this.cReturn.compliance_check.show = res.data.show;
                                    this.cReturn.refresh ++;
                                }else if(this.cArgs.return_type == "identity"){
                                    this.cReturn.person_data = res.data;
                                    this.cReturn.compliance_check.refresh ++;
                                }

                                this.loading = false;
                            })
                            .catch((error) => {
                                this.loading = false;
                                console.error(`error during request: ${error}`);
                            });
                    }
                }
            });
             

        },

        closeModal() {
            this.$emit("update:show", false);
        },

    },
};
</script>

<style scoped>

</style>
