<template>
    <b-modal ref="modal-add" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('personal-info-change-title',172)">
        <ValidationObserver ref="form" >
            <b-form @submit.prevent="onSubmit">

                     <app-row-left-label :label="trans('f-gender',184)">
                        <app-radio-group v-model="cArgs.sex" :options="sex" validatorName="Sex" validatorRules="required" :validatorCustomMessage="{ required: trans('gender-required',177) }" />
                    </app-row-left-label>

                    <app-row-left-label :label="trans('first-name',182)">
                        <app-input v-model="cArgs.first_name" validatorName="Voornaam" validatorRules="required" mask="lettersE1-20" />
                    </app-row-left-label>   


                     <app-row-left-label :slots="[4,8]" :label="trans('last-name',182)">
                        <template v-slot:col-1>
                            <app-input v-model="cArgs.inbetween_name" :placeholder="trans('inbetween-name',182)" mask="lettersE1-10"/>
                        </template>
                        <template v-slot:col-2>
                            <app-input v-model="cArgs.last_name" :placeholder="trans('last-name',182)" validatorName="Achternaam" validatorRules="required" mask="lettersE1-28" />
                        </template>
                    </app-row-left-label>

                    <app-row-left-label :slots="[4,8]" :label="trans('fw-maiden-name',184)" v-if="cArgs.sex == 2">
                        <template v-slot:col-1>
                            <app-input v-model="cArgs.inbetween_maiden_name" :placeholder="trans('fw-inbetween-maiden-name',184)" mask="lettersE1-10"/>
                        </template>
                        <template v-slot:col-2>
                            <app-input v-model="cArgs.maiden_name" :placeholder="trans('fw-maiden-name',184)" mask="lettersE1-28" />
                        </template>
                    </app-row-left-label>


                    <app-row-left-label :label="trans('birth-date',184)">
                        <app-date id="vi-birth_date" v-model="cArgs.birth_date" validatorRules="date-check|age-check" validatorName="Geboortedatum"/>
                    </app-row-left-label>

                    <app-row-left-label :label="trans('birth-place',184)">
                          <app-input v-model="cArgs.birth_place" id="vi-birth_place" mask="lettersE1-25" />
                    </app-row-left-label>

                    <app-row-left-label :label="trans('fp-marital-status',184)">
                        <app-select v-model="cArgs.martial_status" type="getMartialStatus" :clearable="true"/>
                    </app-row-left-label>

                    <app-row-left-label :label="trans('phone-number',182)">
                        <app-phone :prefix.sync="cArgs.phone_prefix" :phone.sync="phone_number" />
                    </app-row-left-label>

                    <app-row-left-label :label="trans('email',182)">
                            <app-email :email.sync="cArgs.email" ref="email_control" :changeMode="true" validatorName="Email" validatorRules="required" type="person" />
                    </app-row-left-label>

            </b-form>
        </ValidationObserver>
        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="change" :loading="loading">
                {{trans('save',175)}}
            </app-button>
        </template>
    </b-modal>
</template>

<script>
import axios from "@axios";
import AppPhone from '@core/components/AppPhone.vue';
import AppEmail from '@core/components/AppEmail.vue';
import { ValidationObserver } from "vee-validate";
import internationals_json from "@/assets/json/internationals.json";

export default {
    components: {
        ValidationObserver,
        AppPhone,
        AppEmail,
    },

    props: ["show", "args", "return", "result"],

    created(){
        if(this.cArgs.martial_status.value == null){
            this.cArgs.martial_status = null;
        }
    },

    computed: {
        computedShow: {
        get() { return this.show },
        set(value) { this.$emit('update:show', value) }, 
        },

        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },

        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        }
    },

    data() {
        return {
            internationals: internationals_json,
            last_email_checked:"",
            loading_email_check: 0,
            loading: false,
            sex: [
                { value: 1, text: this.trans('gender-male',184) },
                { value: 2, text: this.trans('gender-female',184) },
            ],
            phone_number: this.args.phone_number,
        };
    },

    methods: {
        async change() {  
            await this.$refs.email_control.checkEmailExist();
            this.$refs.form.validate().then((result) => {
                
                if (!result) { 
                    return false;
                }
                else {
                    if(this.cArgs.phone_prefix == "") {
                        this.cArgs.phone_prefix="+31";
                    }
                    this.loading = true;

                    
                    axios
                        .post("people/profile/personal-information/change"  , {
                            id_person_data_main: this.args.id_person_data_main,
                            sex: this.args.sex,
                            first_name: this.args.first_name,
                            inbetween_name: this.args.inbetween_name,
                            last_name: this.args.last_name,
                            inbetween_maiden_name: this.cArgs.inbetween_maiden_name,
                            maiden_name: this.cArgs.maiden_name,
                            birth_date: this.args.birth_date,
                            birth_place: this.args.birth_place,
                            martial_status: JSON.stringify(this.args.martial_status),
                            phone_prefix: this.args.phone_prefix,
                            phone_number: this.phone_number,
                            email: this.args.email,
                        })
                        .then((res) => {
                            this.$emit("update:show", false);
                            this.$emit("update:result", 1);
                            this.cReturn.person_data = res.data;
                            this.loading = false;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        });
                    }
            })
        },


        closeModal() {
            this.$emit("update:show", false);
        },




    },
};
</script>

<style scoped>

</style>
