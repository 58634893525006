<template>
    <div>
        <b-modal ref="modal-add" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('contract-type-select',8)">
            <b-card >
                <div class="app-fixed-search d-flex align-items-center">
                    <div class="d-flex align-content-center justify-content-between w-100">
                        <b-input-group class="input-group-merge" >
                        
                            <b-input-group-prepend is-text style="width:40px">
                                <b-icon icon="search" class="text-muted" v-if="!loading"></b-icon>      
                                <b-spinner class="align-middle" small v-if="loading"></b-spinner>  
                            </b-input-group-prepend >

                            <b-form-input class="pl-2 mt-1 border-0" autofocus :placeholder="trans('search',3)" v-model="parameters.search" :lazy="true"/>

                            <b-input-group-append is-text end class="app-local-dropdown">
                                <b-dropdown variant="link" no-caret right>
                                    <template #button-content >
                                            <b-icon icon="three-dots-vertical" class="text-body"></b-icon>
                                    </template>
                                        <b-dropdown-item @click="parameters.sort_desc = false"> {{trans('sort-az',260)}} </b-dropdown-item>
                                        <b-dropdown-item @click="parameters.sort_desc = true"> {{trans('sort-za',260)}} </b-dropdown-item>
                                </b-dropdown>
                            </b-input-group-append>
                        </b-input-group>
                    </div>
                </div>
                <hr class="app-search-modal-separator">
                <perfect-scrollbar>
                    <b-list-group flush >
                        <b-list-group-item class="d-flex  align-items-center p-1 app-local-pointer" action v-for="item in items" :key="item.value" tabindex="0" @click.prevent="selectItem(item)">
                            <b-form-checkbox  v-model="id_document_data_version" :value="item.id_document_data_version" unchecked-value="0" size="sm" :class="id_document_data_version == item.id_document_data_version ? 'custom-checkbox-checked' : ''">
                                <span class="app-documents-item">{{item.name}}</span>
                            </b-form-checkbox>
                        </b-list-group-item>
                    </b-list-group>
               </perfect-scrollbar>
            </b-card>
            <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="nextStep" :disabled="loading_data || checkId">{{trans('next-step',175)}}</app-button>
        </template>
        </b-modal>
    </div> 
</template>

<script>
import axios from "@axios";

export default {

    props: ["show", "result", "args", "return"],
    
    created(){
        this.getList();
    },

    watch: {
        parameters: {
            handler: function(val) {
                if (this.parameters.sort_by != "") {
                    this.loading = true;
                    this.getList();
                }
            },
            deep: true,
        },
    },

    computed: {
        computedShow: {
            get() { return this.show },
            set() { this.$emit('update:show', false) }, 
        },

        checkId: {
            get() {
                if(this.id_document_data_version === undefined) {
                    return true;
                } else if (this.id_document_data_version === false){
                    return true;
                }  else if (this.id_document_data_version === 0){
                    return true;
                } else {
                    return false;
                }

            }
        },

        cReturn: {
            get() { return this.return },
            set() { this.$emit('update:return', value) }
        }

    },
    
    data() {
        return {
            id_document_const_type: 0,
            id_document_data_version: 0,
            parameters:{                
                search:"",
                sort_by: "name",
                sort_desc: false,
                applicant_type: this.args.applicant_type,
            },
            loading: false,
            loading_data: false,
            items:[],
            type: 'getPersonContractTypes',
        };
    },

    methods: {

        selectItem(item) {          
           var index = this.items.indexOf(item);
           if (item.selected === '1') {
                item.selected = '0';
            } else {
                item.selected = '1';                
            }

            this.id_document_const_type = item.id_document_const_type;
            this.id_document_data_version = item.id_document_data_version;

            this.$set(this.items, index, item);
        },

       getList: function() {
            this.loading = true;
            axios
                .post("core/select-item/getList", {
                    type: this.type,
                    parameters: JSON.stringify(this.parameters),
                })
                .then((res) => {
                    this.items = res.data;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading = 0;
                });
        }, 
        
        nextStep() {
            this.cReturn.id_document_const_type = this.id_document_const_type;
            this.cReturn.id_document_data_version = this.id_document_data_version;
            this.$emit("update:result", 1);
            this.$emit("update:show", false);
        },

        closeModal() {
            this.$emit("update:show", false);
        },

    },
};
</script>

<style scoped>

.app-local-pointer{
    cursor:pointer !important
}
.app-local-dropdown{
    width:65px
}
.input-group-text {
    border: none;
}
.ps{
    height: 350px;
}
.card-body {
    padding-top: 0.5rem;
}
</style>
