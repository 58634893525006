<template>
    <div>
        <b-row v-if="profile.person_data.id_status_const_type > 1 && (user_role < 3 || profile.can_user_edit) && profile.person_data.id_status_const_type != 1002" class="app-component-icon-edit">
            <b-dropdown  variant="link"  no-caret right>
                <template  #button-content>
                    <b-icon icon="three-dots-vertical" class="text-body"></b-icon>
                </template>
                <b-dropdown-item @click="change">{{trans('change',175)}}</b-dropdown-item>
            </b-dropdown>
        </b-row>

        <b-row class="app-card-header">
            <b-col start>
                <p class="col-list-label">{{trans('country',182)}}</p>
                <p class="col-list-label">{{trans('postcode',182)}}</p>
                <p class="col-list-label">{{trans('house-number',182)}}</p>
                <p class="col-list-label">{{trans('addition',182)}}</p>
                <p class="col-list-label">{{trans('street',182)}}</p>
                <p class="col-list-label">{{trans('residence',182)}}</p>
            </b-col>
            <b-col>
                <p class="col-form-value">{{profile.person_data.country_name ? profile.person_data.country_name : '-'}}</p>
                <p class="col-form-value">{{profile.person_data.post_code ? profile.person_data.post_code : '-'}}</p>
                <p class="col-form-value">{{profile.person_data.house_number ? profile.person_data.house_number : '-'}}</p>
                <p class="col-form-value">{{profile.person_data.number_additional ? profile.person_data.number_additional : '-'}}</p>
                <p class="col-form-value">{{profile.person_data.street ? profile.person_data.street : '-'}}</p>
                <p class="col-form-value">{{profile.person_data.city ? profile.person_data.city : '-'}}</p>
            </b-col>
        </b-row>
        <b-row class="mt-3" v-if="isCoordinates">
            <b-col cols="12">
                <b-card class="app-local-padding">
                    <GmapMap
                        :center="center"
                        :zoom="9"
                        map-type-id="terrain"
                        style="height: 250px"
                        :options="{
                        zoomControl: false,
                        mapTypeControl: false,
                        styles: styles,
                        scaleControl: false,
                        streetViewControl: false,
                        rotateControl: false,
                        fullscreenControl: false,
                        disableDefaultUI: false,
                        scrollwheel: false,
                        disableDoubleClickZoom: true,
                        draggingCursor: false,
                        draggable: false,
                        keyboardShortcuts: false,
                        clickableIcons: false
                        }"
                    >
                        <GmapMarker
                            v-if="marker != null"
                            :position="marker"
                            :clickable="false"
                            :draggable="false"
                            :icon="{url: require('assets/images/icons/marker.png'), anchor: point}"
                        />
                    </GmapMap>
                </b-card>
            </b-col>
        </b-row>

        <b-row class="mt-3" v-if="isAddress && !isCoordinates">
            <b-col cols="12">
                <b-alert variant="warning" show class="mb-0 d-flex align-items-center justify-content-between">
                    <div class="font-weight-600 text-base">{{trans('fp-address-coordinates-missing', 172)}}</div>
                    <app-button type="change" :loading="loading" @click="getCoordinates">{{trans('download', 175)}}</app-button>
                </b-alert> 
            </b-col>
        </b-row>

        <people-profile-address-change v-if="mAddressDataChange.show" :show.sync="mAddressDataChange.show" :result.sync="mAddressDataChange.result" :args.sync="mAddressDataChange.args" :return.sync="profile"/>
    </div>
</template>
<script>

import PeopleProfileAddressChange from "./PeopleProfileAddressChange.vue";
import axios from "@axios";
export default {
    components: {
        PeopleProfileAddressChange,
    },

    created(){
        this.setMap();
        this.$gmapApiPromiseLazy().then(() => {
            this.point = new window.google.maps.Point(10,10);
        });
    },
    
    watch: {
        "mAddressDataChange.result": {
            handler: function(val) {
                this.setMap();
                this.mAddressDataChange.result = 0;
            }
        }
    },
    
    props:["profile"],

    computed: {
        isAddress: {
            get() {
                return this.profile.person_data.country_name != null 
                    && this.profile.person_data.post_code != null 
                    && this.profile.person_data.house_number != null 
                    && this.profile.person_data.street != null 
                    && this.profile.person_data.city != null
                    && this.profile.person_data.country_name != ''
                    && this.profile.person_data.post_code != '' 
                    && this.profile.person_data.house_number != '' 
                    && this.profile.person_data.street != '' 
                    && this.profile.person_data.city != '';
            }
        },

        isCoordinates: {
            get() {
                return this.profile.person_data.address_latitude != null
                    && this.profile.person_data.address_longitude != null
                    &&this.profile.person_data.address_latitude != ''
                    && this.profile.person_data.address_longitude != '';
            }
        },
        
        cProfile: {
            get() { return this.profile },
            set(value) { this.$emit('update:profile', value)}
        }
    },

    data(){
        return{
            mAddressDataChange: { show: false, 
                                  args: {
                                            address_type: null, 
                                            post_code: null,
                                            country: null,
                                            house_number: null,
                                            house_number_additional: null,
                                            street: null,
                                            city: null,
                                            latitude: null,
                                            longitude: null,
                                            rdx_coordinate: null,
                                            rdy_coordinate: null,
                                            address_type: null
                                         }, 
                                  result: 0,
                                  return: null },
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
            marker: null,
            point: null,
            center: {lat: null, lng: null},
            loading: false,

            styles: [
                        {
                            "elementType": "geometry",
                            "stylers": [
                            {
                                "color": "#f5f5f5"
                            }
                            ]
                        },
                        {
                            "elementType": "labels.text.fill",
                            "stylers": [
                            {
                                "color": "#616161"
                            }
                            ]
                        },
                        {
                            "elementType": "labels.text.stroke",
                            "stylers": [
                            {
                                "color": "#f5f5f5"
                            }
                            ]
                        },
                        {
                            "featureType": "administrative.land_parcel",
                            "elementType": "labels.text.fill",
                            "stylers": [
                            {
                                "color": "#bdbdbd"
                            }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "geometry",
                            "stylers": [
                            {
                                "color": "#eeeeee"
                            }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "labels.text.fill",
                            "stylers": [
                            {
                                "color": "#757575"
                            }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "geometry",
                            "stylers": [
                            {
                                "color": "#e5e5e5"
                            }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "labels.text.fill",
                            "stylers": [
                            {
                                "color": "#9e9e9e"
                            }
                            ]
                        },
                        {
                            "featureType": "road",
                            "elementType": "geometry",
                            "stylers": [
                            {
                                "color": "#ffffff"
                            }
                            ]
                        },
                        {
                            "featureType": "road.arterial",
                            "elementType": "labels.text.fill",
                            "stylers": [
                            {
                                "color": "#757575"
                            }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "geometry",
                            "stylers": [
                            {
                                "color": "#dadada"
                            }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "labels.text.fill",
                            "stylers": [
                            {
                                "color": "#616161"
                            }
                            ]
                        },
                        {
                            "featureType": "road.local",
                            "elementType": "labels.text.fill",
                            "stylers": [
                            {
                                "color": "#9e9e9e"
                            }
                            ]
                        },
                        {
                            "featureType": "transit.line",
                            "elementType": "geometry",
                            "stylers": [
                            {
                                "color": "#e5e5e5"
                            }
                            ]
                        },
                        {
                            "featureType": "transit.station",
                            "elementType": "geometry",
                            "stylers": [
                            {
                                "color": "#eeeeee"
                            }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "geometry",
                            "stylers": [
                            {
                                "color": "#c9c9c9"
                            }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "labels.text.fill",
                            "stylers": [
                            {
                                "color": "#9e9e9e"
                            }
                            ]
                        }
                    ]
        }
    },

    methods:{
        change(){
            
            this.mAddressDataChange.args.id_person_data_main = this.profile.id;
            this.mAddressDataChange.args.country = {name: this.profile.person_data.country_name, value: this.profile.person_data.id_country_data_main};
            this.mAddressDataChange.args.post_code = this.profile.person_data.post_code ?  this.profile.person_data.post_code : '';
            this.mAddressDataChange.args.house_number = this.profile.person_data.house_number ? this.profile.person_data.house_number : '';
            this.mAddressDataChange.args.house_number_additional = this.profile.person_data.number_additional ?  this.profile.person_data.number_additional: '';
            this.mAddressDataChange.args.street = this.profile.person_data.street ? this.profile.person_data.street : '';
            this.mAddressDataChange.args.city = this.profile.person_data.city ? this.profile.person_data.city : '';
            this.mAddressDataChange.args.latitude = this.profile.person_data.latitude ? this.profile.person_data.latitude : '';
            this.mAddressDataChange.args.longitude = this.profile.person_data.longitude ? this.profile.person_data.longitude : '';
            this.mAddressDataChange.args.rdx_coordinate = this.profile.person_data.rdx_coordinate;
            this.mAddressDataChange.args.rdy_coordinate = this.profile.person_data.rdy_coordinate;
            this.mAddressDataChange.args.type = this.profile.person_data.address_type;
            this.mAddressDataChange.show = true;

            
        },

        getCoordinates(){
            this.loading = true;
                        axios
                            .post("core/coordinates/downloadCoordinatesPerson", {
                                id_person_data_main: this.profile.person_data.id_person_data_main,
                            })
                            .then((res) => {
                                this.cProfile.person_data = res.data;
                                this.loading = false;
                                this.setMap();
                            })
                            .catch((error) => {
                                console.error(`error during request: ${error}`);
                            });
        },

        setMap(){
            if(this.profile.person_data.address_latitude != null && this.profile.person_data.address_longitude != null){
                this.marker = {lat: parseFloat(this.profile.person_data.address_latitude), lng: parseFloat(this.profile.person_data.address_longitude)};
                this.center = {lat: parseFloat(this.profile.person_data.address_latitude), lng: parseFloat(this.profile.person_data.address_longitude)};
            }
        },
    }
 
};
</script>

<style scoped>
.app-local-padding .card-body{
    padding: 6px !important;
}
</style>