import { render, staticRenderFns } from "./PeopleProfileFirstContractChange.vue?vue&type=template&id=29e60327&scoped=true&"
import script from "./PeopleProfileFirstContractChange.vue?vue&type=script&lang=js&"
export * from "./PeopleProfileFirstContractChange.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29e60327",
  null
  
)

export default component.exports