<template>
    <div>
        <b-card no-body class="app-card-profile">
            <div v-if="profile.person_data.id_status_const_type > 3 && [1,3].includes(profile.person_data.applicant_type) && profile.person_data.id_status_const_type != 1002">
                <b-row class="app-component-icon-edit">
                    <b-dropdown  variant="link" toggle-class="mr-2" no-caret right v-if="user_data.id_user_const_role == 1 || (profile.person_data.register_status == 1 && (user_data.id_user_const_role < 3 || profile.can_user_edit))">
                        <template #button-content >
                            <b-icon icon="three-dots-vertical" class="text-body"></b-icon>
                        </template>
                        <b-dropdown-item  v-if="user_data.id_user_const_role == 1 || (profile.person_data.register_status == 1 && (user_data.id_user_const_role < 3 || profile.can_user_edit))" @click="workedWeekChange">{{trans('change-weeks-number',8)}}</b-dropdown-item>
                        <b-dropdown-item  v-if="user_data.id_user_const_role == 1"  @click="firstContractChange">{{trans('change-date-inserv',8)}}</b-dropdown-item>
                    </b-dropdown>
                </b-row>
                <b-row class="app-card-header">
                    <b-col start>
                        <p class="col-list-label">{{trans('weeks-amount',3)}}</p>
                        <p class="col-list-label">{{trans('date-service',8)}}</p>
                    </b-col>
                    <b-col>
                        <p class="col-form-value"> {{profile.person_data.worked_weeks ? profile.person_data.worked_weeks : '-'}} </p>
                        <p class="col-form-value"> {{profile.person_data.first_contract_date ? profile.person_data.first_contract_date : '-'}} </p>
                    </b-col>
                </b-row>
                <hr class="app-table-hr-separator">
            </div>
            
            <people-profile-contracts-component v-if="profile.person_data.id_status_const_type > 3 && profile.person_data.id_status_const_type != 1002" :profile.sync="profile" />
            
            <hr v-if="profile.person_data.id_status_const_type > 3 && profile.person_data.id_status_const_type != 1002" class="app-table-hr-separator">

            <people-profile-wage-payroll-component :profile.sync="profile" v-if="[1, 3, 4].includes(profile.person_data.applicant_type)" />
            <people-profile-wage-independent-component :profile.sync="profile" v-if="profile.person_data.applicant_type == 2" />
        </b-card>

          <people-profile-worked-week-change v-if="mPeopleProfileWorkedWeekChange.show" :show.sync="mPeopleProfileWorkedWeekChange.show" :result.sync="mPeopleProfileWorkedWeekChange.result" :args.sync="mPeopleProfileWorkedWeekChange.args" :return.sync="profile"/>
          <people-profile-first-contract-change v-if="mPeopleProfileFirstContracktChange.show" :show.sync="mPeopleProfileFirstContracktChange.show" :result.sync="mPeopleProfileFirstContracktChange.result" :args.sync="mPeopleProfileFirstContracktChange.args" :return.sync="profile"/>
    </div>
    
</template>

<script>
import PeopleProfileContractsComponent from './Contracts/PeopleProfileContractsComponent.vue';
import PeopleProfileWorkedWeekChange from './PeopleProfileWorkedWeekChange.vue';
import PeopleProfileFirstContractChange from './PeopleProfileFirstContractChange.vue';
import PeopleProfileWagePayrollComponent from '@app/People/Profile/Wage/Payroll/PeopleProfileWagePayrollComponent.vue';
import PeopleProfileWageIndependentComponent from '@app/People/Profile/Wage/Independent/PeopleProfileWageIndependentComponent.vue';

export default {
    components: {
        PeopleProfileContractsComponent,
        PeopleProfileWorkedWeekChange,
        PeopleProfileFirstContractChange,
        PeopleProfileWagePayrollComponent,
        PeopleProfileWageIndependentComponent,
    },

    props:["profile"],

    created() {
        this.user_data = JSON.parse(localStorage.getItem('user'));
    },

    data() {
        return {
            loading: false,
            mPeopleProfileWorkedWeekChange: {show: false, args: {id_person_data_main: null, worked_weeks: null}, result: 0, return: "profile"},
            mPeopleProfileFirstContracktChange: {show: false, args: {id_person_data_main: null, first_contract_date: null}, result: 0, return: "profile"},
            user_data: null
        };
    },

    methods: {
        workedWeekChange(){
             this.mPeopleProfileWorkedWeekChange.args.id_person_data_main = this.profile.id;
             this.mPeopleProfileWorkedWeekChange.args.worked_weeks = this.profile.person_data.worked_weeks ? this.profile.person_data.worked_weeks : '';
             this.mPeopleProfileWorkedWeekChange.show = true;
        },

         firstContractChange(){
             this.mPeopleProfileFirstContracktChange.args.id_person_data_main = this.profile.id;
             this.mPeopleProfileFirstContracktChange.args.first_contract_date = this.profile.person_data.first_contract_date ? this.profile.person_data.first_contract_date : '';
             this.mPeopleProfileFirstContracktChange.show = true;
        } 
    }

};

</script>

<style scoped>

</style>
