<template>
    <div>
        <app-view-table :isTitle="false" :loading="cLoading" :isTitleTotal="false" :filters.sync="filters" :items="items" :fields="fields" :isSettings="false">
           
            <template v-slot:menu-items="cell">
                <b-dropdown-item @click="openDocument(cell.data.item)">{{trans('show-document',3)}}</b-dropdown-item>
            </template>
            
        </app-view-table>
     </div>
</template>

<script>
import axios from "@axios";
import AppAlerts from '@core/scripts/AppAlerts';
import moment from "moment";

export default {
    components: { 

    },

    props:["profile", "refresh", "loading", "year"],

    mounted() {
        this.onCreateThis();
    },

    computed: {
        cProfile: {
            get() { return this.profile },
            set() { }
        },

        cRefresh: {
            get() { return this.refresh },
            set(value) {this.$emit('update:refresh', value) }
        },

        cLoading: {
            get() { return this.loading },
            set(value) {this.$emit('update:loading', value) }
        },
    },

    data() {
        
        return {
            alert_class: new AppAlerts(),
            filters: {
                sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                },
                filtering: {},
                page: 1,
                per_page: 5,
                search: "",
                changed: 0,
                type: 2,
                year: this.year,
            },

            fields: [
                { key: "number", label: this.trans('number',182), thStyle: { "width": "100%"  },visible: true },
                { key: "week", label: this.trans('week',3), thStyle: { "min-width": "190px", "max-width": "190px" },visible: true },
                { key: "year", label: this.trans('year',182), thStyle: { "min-width": "190px", "max-width": "190px" },visible: true },
            ],
            
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
            items:[],
            /*items: {
                "list":[
                {id: 1, document_number: 22, document_week: 23, document_year: 2022},
                {id: 2, document_number: 2, document_week: 11, document_year: 2022},
                {id: 3, document_number: 24, document_week: 5, document_year: 2021}
                ],
                "total":3,
                "refresh":0
            },*/
        };
    },

    methods: {
        
        getList: function() {
            this.cLoading = true;

            axios
                .post("people/profile/documents/getList", {
                    id_person_data_main: this.profile.id,
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {
                    this.items = res.data.items;
                    this.filters.page = res.data.page;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.cLoading = false;
                });
        },

           openDocument: function(data) {
            
            this.cLoading = true;
            axios
                .post("people/profile/documents/downloadFile", {
                    id_document_data_main: data.id,
                    id_person_data_main: this.profile.id,
                    type: 2,
                }, 
                {
                    responseType: 'blob', 
                    crossDomain: true,
                })
                .then((res) => {

                    const url = window.URL.createObjectURL(new Blob([res.data], {type : 'application/pdf'}));
                    const link = document.createElement('a');
                    link.href = url;
                    link.target= "_blank";
                    link.setAttribute('open', data.document_name); 
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((error) => {
                    //console.error(error);
                    this.alert_class.openAlertWarning(this.trans('file-not-found',180)).then(res => {})
                })
                .finally(() => {
                   this.cLoading = false;
                });

        },
        
        onCreateThis() { 

            this.getList();

            this.$root.$on('locationsChanged', () => {
        
                if (!this.cLoading) {
                    this.getList();
                } 
            });

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                } 
            });
            this.$watch('refresh', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                }   
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                }   
            });

            this.$watch('filters.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                } 
            });

            this.$watch('profile.documents.items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('year', function(newVal, oldVal) {
                    this.filters.year = this.year;
                    this.getList();

            });

        },

    },
};
</script>

<style scoped>
</style>
