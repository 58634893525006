<template>
    <div>
        <b-row v-if="profile.person_data.id_status_const_type > 2 && (user_role < 3 || profile.can_user_edit) && profile.person_data.id_status_const_type != 1002" class="app-component-icon-edit">
            <b-dropdown  variant="link"  no-caret right v-if="user_role === 1 || profile.person_data.applicant_type === 2">
                <template #button-content>
                    <b-icon icon="three-dots-vertical" class="text-body"></b-icon>
                </template>
                <b-dropdown-item @click="change" v-if="user_role === 1">{{trans('fp-change-bank-det',174)}}</b-dropdown-item>
                <b-dropdown-item @click="changeInvoice()" v-if="profile.person_data.applicant_type === 2">{{trans('fp-change-billings',174)}}</b-dropdown-item>
                <b-dropdown-item @click="changeLRZA()" v-if="profile.person_data.applicant_type === 2">{{trans('fp-change-lrza',172)}}</b-dropdown-item>
            </b-dropdown>
        </b-row>

        <b-row class="app-card-header" v-if="profile.person_data.applicant_type === 2">
                <b-col>
                     <div class="col-list-label"><b>{{trans('fp-bank-details',184)}}</b></div>
                </b-col>
        </b-row>

        <b-row class="app-card-header">
            <b-col start>
                <p class="col-list-label">{{trans('fp-iban',184)}}</p>
                <p class="col-list-label" v-if="profile.person_data.iban_is_other_owner != 1">{{trans('different-beneficiary',184)}}</p>
            </b-col>
            <b-col>
                <p class="col-form-value">{{profile.person_data.iban ? profile.person_data.iban : '-'}}</p>
                <p class="col-form-value" v-if="profile.person_data.iban_is_other_owner != 1">{{trans('fp-iban-no',8)}}</p>
            </b-col>
        </b-row>

         <b-row class="app-card-header" v-if="profile.person_data.iban_is_other_owner ==  1">
            <b-col start>
                <p class="col-list-label">{{trans('fp-diff-ben-name',184)}}</p>
                <p class="col-list-label">{{trans('fp-diff-ben-iban',184)}}</p>
            </b-col>
            <b-col>
                <p class="col-form-value">{{profile.person_data.iban_other_owner_name}}</p>
                <p class="col-form-value">{{profile.person_data.iban_other}}</p>
            </b-col>
        </b-row>

        <b-row class="app-card-header mt-2" v-if="profile.person_data.applicant_type === 2">
                <b-col>
                     <div class="col-list-label"><b>{{trans('fp-billings',8)}}</b></div>
                </b-col>
        </b-row>

         <b-row class="app-card-header" v-if="profile.person_data.applicant_type === 2">
                <b-col start>
                    <p class="col-list-label">{{trans('fp-short-paym-term',8)}}</p>         
                    <p class="col-list-label">{{trans('btw-number',182)}}</p>                
                </b-col>
                <b-col>
                    <p class="col-form-value">{{isPaymentDedline}}</p>
                    <p class="col-form-value">{{profile.person_data.btw_number ? profile.person_data.btw_number : '-'}}</p> 
                </b-col>                
        </b-row>

        <b-row class="app-card-header mt-2" v-if="profile.person_data.applicant_type === 2">
                <b-col>
                     <div class="col-list-label"><b>{{trans('lrza',182)}}</b></div>
                </b-col>
        </b-row>
                <b-row class="app-card-header" v-if="profile.person_data.applicant_type === 2">
                <b-col start>
                    <p class="col-list-label">{{trans('fp-registered-in-lrza-register',172)}}</p>                                  
                </b-col>
                <b-col>
                    <p class="col-form-value">{{profile.person_data.lrza == 1 ? trans('yes-option',3) : trans('no-option',3)}}</p>     
                </b-col>                
        </b-row>

        <people-profile-bank-change v-if="mChange.show" :show.sync="mChange.show" :result.sync="mChange.result" :args.sync="mChange.args" :return.sync="profile"/>      
        <people-profile-invoice-change v-if="mChangeInvoice.show" :show.sync="mChangeInvoice.show" :result.sync="mChangeInvoice.result" :args.sync="mChangeInvoice.args" :return.sync="profile"/>            
        <people-profile-lrza-change v-if="mChangeLRZA.show" :show.sync="mChangeLRZA.show" :result.sync="mChangeLRZA.result" :args="mChangeLRZA.args" :return.sync="profile" />
</div>
</template>
<script>

import PeopleProfileBankChange from "./PeopleProfileBankChange.vue";
import PeopleProfileInvoiceChange from "./PeopleProfileInvoiceChange.vue";
import PeopleProfileLrzaChange from './PeopleProfileLrzaChange.vue'

export default {
    components: {
        PeopleProfileBankChange,
        PeopleProfileInvoiceChange,
        PeopleProfileLrzaChange
    },

    computed: {
        
        isPaymentDedline() {
            if(this.profile.person_data.zzp_payment_deadline !== undefined && this.profile.person_data.zzp_payment_deadline !== null) {
                if(this.profile.person_data.zzp_payment_deadline === 1) {
                    return this.trans('yes-option',3);
                } else {
                    return this.trans('no-option',3);
                }
            } else {
                return "-";
            }
        },

    },

    props: ["profile"],

    data(){
        return{
            mChange: { show: false, args: {id_person_data_main: null, iban:null, iban_is_other_owner:null, i_ban_other_owner_name:null, iban_other:null}, result: 0, return: null },
            mChangeInvoice: { show: false, args: {id_person_data_main: null, zzp_payment_deadline: null, btw_number: null}, result: 0, return: null },
            mChangeLRZA: { show: false, args: {id_person_data_main: null, lrza: null}, result: 0, return: null },
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
        }
    },

    methods:{
       
        change(){
             this.mChange.args.id_person_data_main = this.profile.id;
             this.mChange.args.iban = this.profile.person_data.iban;
             this.mChange.args.iban_is_other_owner = this.profile.person_data.iban_is_other_owner;
             this.mChange.args.i_ban_other_owner_name = this.profile.person_data.iban_other_owner_name;
             this.mChange.args.iban_other = this.profile.person_data.iban_other;
             this.mChange.show = true;
        },

        changeInvoice(){
            this.mChangeInvoice.args.id_person_data_main = this.profile.id;
            this.mChangeInvoice.args.zzp_payment_deadline = this.profile.person_data.zzp_payment_deadline;
            this.mChangeInvoice.args.btw_number = this.profile.person_data.btw_number;
            this.mChangeInvoice.args.return_type = "financial";
            this.mChangeInvoice.show = true;
        },

        changeLRZA(){
            this.mChangeLRZA.args.id_person_data_main = this.profile.id;
            this.mChangeLRZA.args.lrza = this.profile.person_data.lrza;
            this.mChangeLRZA.args.return_type = "financial";
            this.mChangeLRZA.show = true;

        }
    }
};
</script>

<style scoped>

</style>