<template>
  <div v-if="profile !== null" class="app-navbar-profile-content">  
    <b-navbar sticky class="app-navbar-profile">
      <div class="app-navbar-header">
        <b-row>
          <b-col cols="8" class="app-navbar-profile-margin-4"></b-col>
          <b-col end class="app-navbar-profile-margin-30">
            <div class="d-flex justify-content-end align-items-center">
                   <div class="font-weight-800 mr-4 mt-1" v-if="profile.person_data.register_status == 1"> {{trans('core-applicant-profile', 271)}} </div>
                   <div class="font-weight-800 mr-4 mt-1" v-if="profile.person_data.register_status == 2"> {{trans('core-person-profile', 271)}} </div>
                  <b-dropdown variant="link" right>
                      <template #button-content>
                          <b-icon icon="gear-fill" class="text-secondary" style="width: 17px; height: 17px; margin-right: -2px"></b-icon>
                      </template>
                      <b-dropdown-item :to="{ name: 'people-profile-history', params: { id_person_data_main: id_person_data_main }}" >{{trans('history',182)}}</b-dropdown-item>
                      <b-dropdown-divider></b-dropdown-divider>
                      <b-dropdown-item :to="{ name: 'people-profile-webforms', params: { id_person_data_main: id_person_data_main }}"  >{{trans('web-forms-option',175)}}</b-dropdown-item>
                      <b-dropdown-item @click="sendPersonToEasyFlex()" v-if="user_role < 3 || profile.can_user_edit">{{trans('easyflex-update',174)}}</b-dropdown-item>
                      <b-dropdown-item v-if="profile.person_data.id_user_portal == null && user_role < 3 && profile.person_data.register_status == 2" @click="registerUserPortal()" >{{trans('register-user-on-portal',174)}}</b-dropdown-item>
                      <b-dropdown-item @click="block()" v-if="profile.person_data.id_user_portal != null && profile.person_data.blocked == 0 && (user_role < 3 || profile.can_user_edit)" >{{trans('block',175)}}</b-dropdown-item>
                      <b-dropdown-item @click="unblock()" v-if="profile.person_data.id_user_portal != null && profile.person_data.blocked == 1 && (user_role < 3 || profile.can_user_edit)" >{{trans('unblock',175)}}</b-dropdown-item>
                      <b-dropdown-item @click="sendResetLink()" v-if="profile.person_data.id_user_portal != null && profile.person_data.blocked == 0 && (user_role < 3 || profile.can_user_edit)" >{{trans('registration-mail-resend',175)}}</b-dropdown-item>
                      <b-dropdown-item @click="sendNotification()" v-if="profile.person_data.id_user_portal != null && profile.person_data.blocked == 0 && (user_role < 3 || profile.can_user_edit)" >{{trans('fp-notify',172)}}</b-dropdown-item>
                      <b-dropdown-divider v-if="profile.person_data.id_status_const_type != 1002 && (user_role < 3 || profile.can_user_edit)"></b-dropdown-divider>
                      <b-dropdown-item @click="unsubscribePerson()"  v-if="profile.person_data.id_status_const_type != 1002 && (user_role < 3 || profile.can_user_edit)">{{trans('unsubscribe',174)}}</b-dropdown-item>
                      
                      <!--<b-dropdown-divider v-if="profile.person_data.id_status_const_type != 1002 && (user_role < 3 || profile.can_user_edit)"></b-dropdown-divider>
                      <b-dropdown-item @click="setCompliance()">Set compliance</b-dropdown-item>-->
                  </b-dropdown>
                  
                </div>
          </b-col>
          </b-row>
          <b-row class="align-items-center mb-3">
            <b-col> 
              <people-profile-status-bar :profile.sync ="profile" />
            </b-col>        
          </b-row> 
      </div>
    </b-navbar>
        
        <div>       
          <b-row>
            <b-col md="12" lg="8" class="pr-lg-2">
               <people-profile-action-points :profile.sync="profile" />
               <people-profile-compliance-check v-if="(profile.person_data.id_status_const_type === 3 || (profile.person_data.id_status_const_type > 3 && profile.compliance_check.show === 1 && profile.person_data.id_status_const_type != 1002)) && profile.person_data.applicant_type != 4" :profile.sync="profile"  @scrollToDocument="scrollToDocument" @scrollToReference="scrollToReference" @scrollToPension="scrollToPension"/>
               <people-profile-contact-component class="mb-3" :profile.sync="profile" />

                <b-tabs ref="tabs" class="mb-3" justified :nav-class="cOneTab ? 'd-none' : ''">
                  <b-tab v-if="[1,3].includes(profile.person_data.applicant_type)" :title="trans('wage-details',172)" @click="refreshTab(0)"><people-profile-loon-wages-component :profile.sync="profile"  @scrollToDocument="scrollToDocument"/> </b-tab>  
                  <b-tab :title="trans('contracts',3)" @click="refreshTab(1)"><people-profile-contracts-employment-component :profile.sync="profile"/></b-tab>
                  <b-tab :title="trans('placment',5)" @click="refreshTab(2)" v-if="first_release == 0 && profile.person_data.id_status_const_type == 100 && profile.person_data.register_status == 2"><people-profile-placements-component :profile.sync="profile" /></b-tab>
                </b-tabs>

                <app-collapse accordion type="margin">
                      <b-card no-body class="app-card-profile" >
                          <app-collapse-item style="box-shadow:none !important;" :title="trans('personal-info',172)" :id="'personal-information'">
                              <people-profile-personal-information-component :profile.sync="profile"/>
                          </app-collapse-item>
                      </b-card>

                      <b-card no-body class="app-card-profile">
                          <app-collapse-item style="box-shadow:none !important;" :title="trans('address-details',3)" :id="'address-data'">
                                <people-profile-address-component :profile.sync="profile"/>
                          </app-collapse-item>
                      </b-card>

                      <b-card no-body class="app-card-profile">
                          <app-collapse-item style="box-shadow:none !important;" :title="trans('fp-identity',172)" :id="'identity'">
                              <people-profile-identity-component :profile.sync="profile"/>
                          </app-collapse-item>
                      </b-card>

                      <b-card no-body class="app-card-profile">
                          <app-collapse-item style="box-shadow:none !important;" :title="[1,3].includes(profile.person_data.applicant_type) ? this.trans('fp-bank-details',184) : this.trans('financial-details',172)" :id="'bank-data'">
                              <people-profile-financial-component :profile.sync="profile"/>
                          </app-collapse-item>
                      </b-card>

                      <b-card no-body class="app-card-profile">
                          <app-collapse-item style="box-shadow:none !important;" :title="trans('fp-plan-preferences',172)">
                              <people-profile-plan-preferences-component :profile.sync="profile"/>
                          </app-collapse-item>
                      </b-card>

                      <b-card no-body class="app-card-profile">
                          <app-collapse-item style="box-shadow:none !important;" :title="trans('enrollment',3)" :id="'registration'">
                              <people-profile-registration-component :profile.sync="profile"/>
                          </app-collapse-item>
                      </b-card>

                      <b-card no-body class="app-card-profile">
                          <app-collapse-item style="box-shadow:none !important;" :title="trans('documents',3)" :id="'documents'" :isVisible.sync="openCollapseDocument">
                               <people-profile-documents-component :profile.sync="profile" :type.sync="document_tab_type"/>
                          </app-collapse-item>
                      </b-card>

                      <b-card no-body class="app-card-profile" v-if="[1, 2, 3].includes(profile.person_data.applicant_type)">
                          <app-collapse-item style="box-shadow:none !important;" :title="trans('fp-riskyacts',172)" :id="'competence'">
                            <person-profile-competence-component :profile.sync="profile" />
                          </app-collapse-item>
                      </b-card>
                      
                      <b-card no-body class="app-card-profile" v-if="profile.person_data.id_status_const_type >= 4 && profile.compliance_check.correct === 1 && profile.person_data.applicant_type != 4">
                          <app-collapse-item style="box-shadow:none !important;" :title="trans('fp-screening',8)" :id="'screening'">
                            <people-profile-compliance-check class="m--3" :profile.sync="profile" @scrollToDocument="scrollToDocument" @scrollToReference="scrollToReference" @scrollToPension="scrollToPension" />
                          </app-collapse-item>
                      </b-card>

                      <b-card no-body class="app-card-profile">
                          <app-collapse-item style="box-shadow:none !important;" :title="trans('fp-reference',172)" :id="'reference'" :isVisible.sync="openCollapseReference">
                            <people-profile-reference-component :profile.sync="profile" />
                          </app-collapse-item>
                      </b-card>

                      <b-card no-body class="app-card-profile" v-if="[1,3].includes(profile.person_data.applicant_type)">
                          <app-collapse-item style="box-shadow:none !important;" :title="trans('fp-pension',172)" :id="'pension'" :isVisible.sync="openCollapsePension">
                            <people-profile-pension-component :profile.sync="profile" />
                          </app-collapse-item>
                      </b-card>

                  </app-collapse>
            
              </b-col>

              <b-col md="12" lg="4" class="mt-3 mt-lg-0 pl-lg-2">
                  <planning-planner-person-profile-component class="mb-3" :profile.sync="profile" :id_person_data_main="id_person_data_main" v-if="first_release == 0 && profile.person_data.register_status == 2 && (profile.person_data.id_status_const_type == 100 || profile.person_data.id_status_const_type == 7)"/>
                  <issues-profile-component class="mb-3" :profile.sync="profile" type="person"/>
                  <notes-component :profile.sync="profile"/>
              </b-col>
          </b-row>

        </div>
        <people-profile-notification-send v-if="mNotification.show" :show.sync="mNotification.show" :result.sync="mNotification.result" :args.sync="mNotification.args" :return.sync="profile"/>
        <people-profile-subscription-change v-if="mUnsubscribe.show" :show.sync="mUnsubscribe.show" :result.sync="mUnsubscribe.result" :args.sync="mUnsubscribe.args" :return.sync="profile"/>

  </div>
</template>
<script>

import axios from "@axios";
import AppCollapse from "@core/components/AppCollapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/AppCollapse/AppCollapseItem.vue";
import PeopleProfileStatusBar from './StatusBar/PeopleProfileStatusBar.vue';
import PeopleProfileSubscriptionChange from './ActionPoints/PeopleProfileSubscriptionChange';

import IssuesProfileComponent from "@app/Issues/IssuesProfileComponent.vue";
import NotesComponent from '@app/Notes/NotesComponent.vue';
import PeopleProfileContactComponent from './ContactDetails/PeopleProfileContactComponent.vue';
import PeopleProfileActionPoints from './ActionPoints/PeopleProfileActionPoints.vue';
import PeopleProfileComplianceCheck from './ComplianceCheck/PeopleProfileComplianceCheck.vue';

//Tabs
import PeopleProfileLoonWagesComponent from './LoonWages/PeopleProfileLoonWagesComponent.vue';
import PeopleProfileContractsEmploymentComponent from './ContractsEmployment/PeopleProfileContractsEmploymentComponent.vue';
import PeopleProfilePlacementsComponent from './Placements/PeopleProfilePlacementsComponent.vue';

//Collapse
import PeopleProfileRegistrationComponent from '@app/People/Profile/Registration/PeopleProfileRegistrationComponent.vue';
import PeopleProfilePersonalInformationComponent from '@app/People/Profile/PersonalInformation/PeopleProfilePersonalInformationComponent.vue';
import PeopleProfileAddressComponent from '@app/People/Profile/Address/PeopleProfileAddressComponent.vue';
import PeopleProfileIdentityComponent from '@app/People/Profile/Identity/PeopleProfileIdentityComponent.vue';
import PeopleProfileDocumentsComponent from '@app/People/Profile/Documents/PeopleProfileDocumentsComponent.vue';
import PeopleProfileFinancialComponent from './Financial/PeopleProfileFinancialComponent.vue';
import PersonProfileCompetenceComponent from './Competence/PersonProfileCompetenceComponent.vue';
import PeopleProfilePlanPreferencesComponent from './PlanPreferences/PeopleProfilePlanPreferencesComponent.vue';
import PeopleProfileReferenceComponent from './Reference/PeopleProfileReferenceComponent.vue';
import PlanningPlannerPersonProfileComponent from '@app/Planning/Planner/PlanningPlannerPersonProfileComponent.vue';
import PeopleProfilePensionComponent from './Pension/PeopleProfilePensionComponent.vue';

import PeopleProfileNotificationSend from './Notification/PeopleProfileNotificationSend.vue';

import AppAlerts from '@core/scripts/AppAlerts';
//Scheduler

export default {
  components: {
    
    AppCollapse,
    AppCollapseItem,
    PeopleProfileStatusBar,
    PeopleProfileContactComponent,
    PeopleProfileActionPoints,
    PeopleProfileComplianceCheck,
    PeopleProfileSubscriptionChange,

    //Right sidebar
    IssuesProfileComponent,
    NotesComponent,

    //Tabs
    PeopleProfileContractsEmploymentComponent,
    PeopleProfilePlacementsComponent,

    //Collapse
    PeopleProfileRegistrationComponent,
    PeopleProfilePersonalInformationComponent,
    PeopleProfileAddressComponent,
    PeopleProfileLoonWagesComponent,
    PeopleProfileIdentityComponent,
    PeopleProfileDocumentsComponent,
    PeopleProfileFinancialComponent,
    PersonProfileCompetenceComponent,
    PeopleProfilePlanPreferencesComponent,
    PeopleProfileReferenceComponent,
    PeopleProfilePensionComponent,

    //
    PeopleProfileNotificationSend,
    PlanningPlannerPersonProfileComponent

  },

  created() {
    this.getData();
    
     

  },

   mounted() {
    
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      
      if (collapseId == 'collapse-documents' && isJustShown == true){        
          this.profile.documents.items.refresh ++;
      }

    });
    

  },

  computed : {
    cOneTab: {
      get() {
        if(![1,3].includes(this.profile.person_data.applicant_type) && this.first_release != 0) { 
          return true;
        } else {
          return false;
        }
      }
    }
  },

  watch: {
      "profile.refresh": {
            handler: function(val) {
                if (val > 0) {
                    this.getPersonData();
                }                
            },
        },

        "$route.params.id_person_data_main": {
              handler: function(val) {
                  if (val > 0) {
                      this.id_person_data_main = val;
                      this.profile = null;
                      this.getData();
                  }                
              },
        }
        
  },

  data(){
      return {
      
              id_person_data_main: this.$route.params.id_person_data_main,
              profile: null,
              activeTab: 0,
              tab_count: 0,
              openCollapseDocument: false,
              openCollapseReference: false,
              openCollapsePension: false,
              tabList: [],
              document_tab_type: 1,
              mNotification: {show: false, args: {id_user_portal: null, title: null, message: ''}, result: 0, return: null},
              mUnsubscribe: {show: false, args: {id_person_data_main: null}, result: 0, return: null},
              user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
              alert_class: new AppAlerts(),
              first_release: JSON.parse(localStorage.getItem('user')).first_release,

          }

  },

  methods: {
    
    getData(){
      axios
        .get("people/getProfile/" + this.id_person_data_main)
        .then((res) => {
            this.profile = res.data;
            this.$route.meta.person_type = this.profile.person_data.register_status;
        })
        .catch((error) => {
            console.error(`error during request: ${error}`);
        });
    },

    getPersonData(){
      
      axios
        .get("people/getPersonData/" + this.id_person_data_main)
        .then((res) => {
            if(res.data.length > 0){
              this.profile.person_data = res.data[0];  
            }            
        })
        .catch((error) => {
            console.error(`error during request: ${error}`);
        });
    },

    unsubscribePerson(){
        this.mUnsubscribe.args.id_person_data_main = this.profile.id;
        this.mUnsubscribe.show = true;
    },

    sendPersonToEasyFlex(){
      
      axios
        .post("people/sendPersonToEasyFlex", {
          id_person_data_main: this.id_person_data_main
        })
        .then((res) => {
            
        })
        .catch((error) => {
            console.error(`error during request: ${error}`);
        });
    },

    registerUserPortal(){
      //this.profile.person_data.id_user_portal = -1;
      axios
        .post("settings/users/registerUserPortal", {
          id_person_data_main: this.id_person_data_main
        })
        .then((res) => {
            if(res.data != -1){
              this.profile.person_data.id_user_portal = res.data;
            } else {
              this.alert_class.openAlertWarning(this.trans('fp-email-exists', 177)).then(() =>{});
            }
        })
        .catch((error) => {
            console.error(`error during request: ${error}`);
        });
    },

    
         block(){
                        
               this.profile.person_data.blocked = -1;

                axios
                    .post("settings/users/blockPortal", {
                        id_user_portal: this.profile.person_data.id_user_portal,
                    })
                    .then((res) => {
                        this.profile.person_data.blocked = res.data;
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    })
                    .finally(() => {
                        
                    });

        },

        unblock(){
                        
                this.profile.person_data.blocked = -1;
                axios
                    .post("settings/users/unblockPortal", {
                        id_user_portal: this.profile.person_data.id_user_portal,
                        filters: JSON.stringify(this.filters),
                        id_client_data_main: this.idClientDataMain,
                    })
                    .then((res) => {
                        this.profile.person_data.blocked = res.data;
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    })
                    .finally(() => {
                        this.loading = false;
                    });

        },

        sendResetLink(){
                        
                this.profile.person_data.blocked = -1;
                axios
                    .post("settings/users/sendResetLink", {
                        id_user_portal: this.profile.person_data.id_user_portal,
                        type: 'person',
                        register_status: -1
                    })
                    .then(() => {
                      this.profile.person_data.blocked = 0;
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    })
                    .finally(() => {
                        this.loading = false;
                    });

        },

        sendNotification(){
            this.mNotification.args.id_user_portal = this.profile.person_data.id_user_portal;
            this.mNotification.show = true;
        },

    refreshTab(e){
     
      if (this.activeTab !== e) {
        this.activeTab = e;
      
        if(e == 0){//Wage details
          this.profile.loon_wages.period.items.refresh ++;
          this.profile.loon_wages.payroll.items.refresh ++;
        }else if(e == 1){//Contracten
          this.profile.contracts_employment.contracts.items.refresh ++;
          this.profile.contracts_employment.employment.items.refresh ++;
          if([1,3].includes(this.profile.person_data.applicant_type)){
                this.profile.wages.payroll.items.refresh ++;
          }else if (this.profile.person_data.applicant_type == 2){
                this.profile.wages.independent.items.refresh ++;
          }
        }else if(e == 2){
            this.profile.placements.items.refresh ++;
        }
      }

    },

    showLoading: function() {
      this.loading = !this.loading;
    },

    scrollToDocument: function(val){
    
      if (!this.openCollapseDocument) {
        this.document_tab_type = 1;
        this.openCollapseDocument = true;
      } else {
        this.document_tab_type = 1;
        this.profile.documents.items.refresh ++;
      }

      setTimeout(() => {
          
            document.getElementById('footer').scrollIntoView({
                                                                behavior: "smooth",
                                                                block: "start"
                                                            });

                    }, 800);

    }, 


    scrollToReference: function(val){
      if (!this.openCollapseReference) {
        this.openCollapseReference = true;
      }

      setTimeout(() => {
          
            document.getElementById('footer').scrollIntoView({
                                                                behavior: "smooth",
                                                                block: "start"
                                                            });

                    }, 800);
    },

    scrollToPension: function(){

      if (!this.openCollapsePension) {
        this.openCollapsePension = true;
      }

      setTimeout(() => {
          
            document.getElementById('footer').scrollIntoView({
                                                                behavior: "smooth",
                                                                block: "start"
                                                            });

                    }, 800);

    },

    setCompliance(){
        axios
            .post("settings/easyflexImport/setComplianceCore", {
                id_person_data_main: this.id_person_data_main
            })
            .then((res) => {
              this.profile.compliance_check.refresh ++;
            })
            .catch((error) => {
                console.error(`error during request: ${error}`);
            })
            .finally(() => {
                this.loading = false;
            });

    }


  },
 
};
</script>
<style scoped>
.app-local-display-none {
  display: none;
}
</style>