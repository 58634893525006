<template>
    <b-modal ref="modal-add" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('fp-change-bsn',172)">
        <ValidationObserver ref="form" >
            <b-form @submit.prevent> 

                <app-row-left-label :label="trans('number',182)">
                    <app-input v-model="cArgs.bsn_number" id="vi-bsn-number" validatorName="Number" validatorRules="sofi-number" v-mask="'#########'"/>
                </app-row-left-label>                 
            </b-form>
        </ValidationObserver>
        
        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="change()" :loading="loading">
                {{trans('save',175)}}
            </app-button>
        </template>
    </b-modal>
</template>


<script>
import axios from "@axios";
import {ValidationObserver } from "vee-validate";

export default {
    components: {
        ValidationObserver,
    },

    props: ["show", "args", "return", "result"],

    computed: {
        computedShow: {
            get() { return this.show },
            set() { this.$emit('update:show', false) }, 
        },

        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },
        
        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        }
    },

    data() {
        return {
            loading: false,
            opt: [ { value: 1, text: this.trans('yes-option',3) }, { value: 0, text: this.trans('no-option',3) }],
        };
    },

    methods: {
        change() {
            this.$refs.form.validate().then((result) => {
                if (!result) {
                    return false;
                }else{
                    
                    this.loading = true;
                    axios
                        .post("people/profile/identity/changeBsn", {
                            id_person_data_main: this.args.id_person_data_main,
                            bsn_number: this.args.bsn_number,
                        })
                        .then((res) => {
                            this.$emit("update:show", false);
                            this.$emit("update:result", 1);
                            this.cReturn.person_data = res.data;
                            this.loading = false;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                            this.loading = false;
                        });
                }
            });
        },

        closeModal() {
            this.$emit("update:show", false);
        },

    },
};
</script>

<style scoped></style>
