<template>
    <b-modal ref="modal-add" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('fp-change-avatar',172)">
        <b-overlay :show="loading_avatar" :opacity="0.0">
            <b-col v-show="!image_loaded">
                <div class="image-upload-wrap" >
                    <input type="file" class="file-upload-input" id="image-input" accept="image/jpeg, image/png, image/jpg" v-on:change="addFile">
                    <div class="drag-text">
                        <img src="@/assets/images/icons/download-cloud.svg" height="40px" v-if="!f_uploading" />
                        <b-spinner v-if="f_uploading" class="spinner-margin" />
                        <div>
                            <small class="text-muted"> {{trans('drag-or-select-avatar-message',177)}}</small>
                        </div>
                    </div>
                </div>
            </b-col>
            
            <b-col class="text-center" v-show="image_loaded">
                <image-editor  :includeUi="false" :options="options" ref="imageEditor"></image-editor>
            </b-col>

            <b-col class="text-right" v-show="image_loaded">
                <b-link href="#"  @click="deleteFile" class="m-2">{{trans('remove',175)}}</b-link>
            </b-col>
        
        </b-overlay>
        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="fileUpload" :loading="loading">
                {{trans('save',175)}}
            </app-button>
        </template>
        
    </b-modal>
</template>

<script>
import axios from "@axios";
import { ImageEditor } from '@toast-ui/vue-image-editor';
import AppAlerts from '@core/scripts/AppAlerts';

export default {
    components: {
       ImageEditor
    },

    props: ["show", "args", "return", "result"],

    mounted(){
        this.loadFile(this.cArgs.avatar)
    },

    computed: {
        computedShow: {
            get() { return this.show },
            set(value) { this.$emit('update:show', value) }, 
        },

        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },

        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        }
    },

    data() {
        return {
            f_uploading: false,
            image_loaded: false,
            loading_avatar: false,
            options: {
                cssMaxWidth: 10000,
                cssMaxHeight: 10000,
            },
            alertClass: new AppAlerts(),  
            loading: false,

        };
    },

    methods: {

        async loadFile(dataImage){
            if(dataImage != null) {
                this.loading = true;
                this.loading_avatar = true;
                 axios
                .post(`people/profile/personal-information/getProfileImage`, {
                    id_person_data_main: this.cArgs.id_person_data_main
                })
                .then(res => {
                    if(this.$refs.imageEditor != null && res.data != ''){
                        this.$refs.imageEditor.invoke('loadImageFromURL', res.data, 'My sample image').then( () =>{
                            var canvasSize = this.$refs.imageEditor.invoke('getCanvasSize');

                            var longerAxis = canvasSize.width > canvasSize.height;
                    
                            var self= this;
                            this.$refs.imageEditor.invoke('resize',{width: longerAxis ? 400 : canvasSize.width/ (canvasSize.height /400), height: !longerAxis ? 400 : canvasSize.height/ (canvasSize.width/400) }).then(()=> {
                                document.getElementsByClassName('lower-canvas')[0].setAttribute("style","max-width:"+longerAxis ? 400 : ((canvasSize.width/ (canvasSize.height /400))) +" px !important");
                                document.getElementsByClassName('lower-canvas')[0].setAttribute("style","max-height:"+longerAxis ? 400 : ((canvasSize.height/ (canvasSize.width /400)) ) +" px !important");
                                //document.getElementsByClassName('upper-canvas')[0].setAttribute("style","max-width:"+longerAxis ? 400 : ((canvasSize.width/ (canvasSize.height /400))) +" px !important");
                                
                                self.$refs.imageEditor.invoke('startDrawingMode', 'CROPPER');
                                
                                self.$refs.imageEditor.invoke('setCropzoneRect', 1);
                                this.image_loaded = true;
                                
                            });
                        })
                    }
                }).catch((error) => {
                            console.error(`error: ${error}`);
                        })
                .finally(() => {
                    this.loading = false;
                    this.loading_avatar = false;
                }); 
            }
        },

        deleteFile(){
            this.$refs.imageEditor.invoke('stopDrawingMode');
            this.$refs.imageEditor.invoke('clearObjects');
            this.image_loaded = false;
        },
        
        async addFile(e) {


            var file = e.target.files[0];

            if(!file.type.match('image/jpeg.*') && !file.type.match('image/png.*') && !file.type.match('image/jpg.*')){
                alert(this.trans('only-doc-pdf',177));
                file = "";
                return;
            }
            this.$refs.imageEditor.invoke('loadImageFromFile', file, 'My sample image').then( (test) =>{
                var canvasSize = this.$refs.imageEditor.invoke('getCanvasSize');

                var longerAxis = canvasSize.width > canvasSize.height;
                
                
                var self= this;
                this.$refs.imageEditor.invoke('resize',{width: longerAxis ? 400 : canvasSize.width/ (canvasSize.height /400), height: !longerAxis ? 400 : canvasSize.height/ (canvasSize.width/400) }).then(()=> {
                    document.getElementsByClassName('lower-canvas')[0].setAttribute("style","max-width:"+longerAxis ? 400 : ((canvasSize.width/ (canvasSize.height /400))) +" px !important");
                    document.getElementsByClassName('lower-canvas')[0].setAttribute("style","max-height:"+longerAxis ? 400 : ((canvasSize.height/ (canvasSize.width /400)) ) +" px !important");
                    self.$refs.imageEditor.invoke('startDrawingMode', 'CROPPER');
                    self.$refs.imageEditor.invoke('setCropzoneRect', 1);
                    this.image_loaded = true;
                });
                

            });

            
            
        },

        async crop(){
            return await this.$refs.imageEditor.invoke("crop", this.$refs.imageEditor.invoke("getCropzoneRect")).then(async ()=>{
                return await this.$refs.imageEditor.invoke('resize',{width: 400 , height: 400 }).then(async ()=> {

                    this.$refs.imageEditor.invoke('stopDrawingMode', 'CROPPER');
                    var avatar = this.$refs.imageEditor.invoke('toDataURL', {format: 'png',multiplier: 0.3 })
                    
                    return avatar;
                });
            });
            
        },

        async fileUpload() {

            let data = new FormData();

            if(this.image_loaded){
                this.loading = true;
                var orginal_image = this.$refs.imageEditor.invoke('toDataURL', {format: 'png'})
                var avatar = await this.crop();
                this.$refs.imageEditor.invoke('loadImageFromURL', orginal_image, 'My sample image')
                orginal_image = orginal_image.slice(21);
                const blob = new Blob([Buffer.from(orginal_image, 'base64')], {type :'image/png'});
                

                data.append('data', blob, 'avatar.png');

                data.append('avatar', avatar);
            }else{
                data.append('data', null);

                data.append('avatar', null);
            }

            data.append('id_person_data_main', this.cArgs.id_person_data_main);
            var changeAvatar = true;
            if(this.cArgs.avatar != null && !this.image_loaded){
                await this.alertClass.openAlertConfirmation(this.trans('remove-avatar-confirmation',177)).then(res => {
                        if(res == false){
                            changeAvatar = false;
                            this.$emit("update:show", false);
                            this.$emit("update:result", 1);
                            return
                            
                        }
                });
            }
            if(changeAvatar){
                this.loading = true;
                axios
                    .post(`people/profile/personal-information/uploadAvatar`, data, {
                        headers: {
                            'Content-Type': 'image/png',
                        },
                    })
                    .then(res => {
                        this.$emit("update:show", false);
                        this.$emit("update:result", 1);
                        this.cReturn.person_data = res.data;
                        this.loading = false;
                    }); 
                }
        },


        closeModal() {
            this.$emit("update:show", false);
        },




    },
};
</script>

<style>
.tui-image-editor-canvas-container{
    max-width: 800px !important;
    max-height: 800px !important;
}

 .lower-canvas{
    max-width: 400px !important;
    max-height: 400px!important;
    
}

.upper-canvas{
    max-width: none !important;
    max-height: none !important;

    width: auto !important;
    height: auto !important;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
} 

.file-upload-input {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;
}


.image-upload-wrap {
    border: 1px dashed #BBE7FA;
    border-radius: 5px;
    position: relative;
    transition: 1s;
}

.image-dropping,
.image-upload-wrap:hover {
    background-color: #f8fcfe;
}

.drag-text {
    text-align: center;
    padding: 20px 10px;
}

.drag-text p {
    color: #000000;
    font-size: 12px;
}


</style>
