<template>
    <div>
        <b-overlay :show="loading_list" :opacity="0.0">
            <b-row class="app-card-header app-local-is-accordion" >
                <b-col  start>
                    <div>
                        <app-year v-if="cType == 2" v-model="year_dates" disableYearFuture />
                    </div>  
                </b-col>

                <b-col cols="auto" class="pl-0" end>

                    <b-button-group  size="sm">
                        <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': cType !== 1, 'app-list-button-selected':  type === 1 }" @click="setListType(1)">{{trans('fp-internal',172)}}</b-btn>
                        <b-btn class="app-input-min-height app-list-button app-local-margin" v-bind:class="{ 'btn-secondary-grey': cType !== 2, 'app-list-button-selected':  type === 2 }" @click="setListType(2)">{{trans('fp-loon',172)}}</b-btn>
                        <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': cType !== 3, 'app-list-button-selected':  type === 3 }" @click="setListType(3)">{{trans('fp-year-dig',172)}}</b-btn>
                    </b-button-group>                             
                </b-col>

                <b-col cols="auto" end class="d-flex align-items-center pl-0">
                    <app-refresh :refresh.sync="cProfile.documents.items.refresh" :loading.sync="loading_list"/>
                </b-col>
            </b-row>

            <people-profile-documents-internal-list v-if="cType == 1" :loading.sync="loading_list" :refresh.sync="refresh" :profile.sync="profile" :type="type"/>
            <people-profile-documents-loon-list v-if="cType == 2" :loading.sync="loading_list" :refresh.sync="refresh" :profile.sync="profile" :type="type" :year="year_dates"/>
            <people-profile-documents-year-dig-list v-if="cType == 3" :loading.sync="loading_list" :refresh.sync="refresh" :profile.sync="profile" :type="type"/>


            <template #overlay>
                <div></div>
            </template>
        </b-overlay>

        </div>
</template>

<script>
import axios from "@axios";
import AppAlerts from '@core/scripts/AppAlerts';
import PeopleProfileDocumentsInternalList from './PeopleProfileDocumentsInternalList';
import PeopleProfileDocumentsLoonList from './PeopleProfileDocumentsLoonList';
import PeopleProfileDocumentsYearDigList from './PeopleProfileDocumentsYearDigList';
import AppYear from '@core/components/AppYear.vue';
import moment from "moment";

export default {
    components: { 
        PeopleProfileDocumentsYearDigList,
        PeopleProfileDocumentsLoonList,
        PeopleProfileDocumentsInternalList,
        AppYear,
    },

    props:["profile", "type"],

    mounted() {
    },

    computed: {
        cProfile: {
            get() { return this.profile },
            set() { }
        },

        cType: {
            get() { return this.type },
            set(value) {this.$emit('update:type', value) }
        }
    },

    data() {
        
        return {
            alert_class: new AppAlerts(),
            loading_list: false,
            list_type_changed: false,
            refresh:0,
            year_dates: moment().format('YYYY'),
        };
    },

    methods: {
        setListType(type) {
            this.cType = type;
            this.list_type_changed = true;
        },

    },
};
</script>

<style scoped>
.app-local-margin{
    margin-right: -1px !important
}

.app-local-is-accordion{
    position: absolute;
    top: -38px;
    right: 40px;
}
</style>
