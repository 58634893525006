<template>
    <b-modal ref="modal-add" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('enrollment',3)">
        <ValidationObserver ref="form" >

            <app-row-left-label :label="trans('contract-type',182)" v-if="args.previous_applicant_type == 3">
                <app-radio-group v-model="applicant_type" :options="applicant_types" :stacked="true" validatorName="Soort contract" validatorRules="required" :validatorCustomMessage="{ required: trans('fl-type-of-contract-error',177) }" />
            </app-row-left-label>
            <app-row-left-label :label="trans('branch',182)">
                <app-select v-model="cArgs.location" :disable="false" type="getUserLocations" validatorRules="required" validatorName="Vestiging" />
            </app-row-left-label>

            <app-row-left-label :label="trans('recruiter',182)">
                <app-select v-model="cArgs.recruiter" :refreshIndex.sync="refresh_users" :args.sync="users_args" type="getUsersFromLocation" :disable="users_args.id_office_data_location < 1" validatorRules="required" validatorName="Recruiter" /> 
            </app-row-left-label>

            <app-row-left-label :label="trans('consultant',184)">
                <app-select  v-model="cArgs.consultant" :disable="false" type="getUserSelectorList" validatorRules="required" validatorName="Intercedent" />
            </app-row-left-label>

        </ValidationObserver>

        <template #modal-footer>

                <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
                <app-button type="primary" @click="change" :loading="loading">
                    {{trans('save',175)}}
                </app-button>
        </template>
    </b-modal>
</template>

<script>
import axios from "@axios";
import { ValidationObserver } from "vee-validate";
import AppRowLeftLabel from '../../../../@core/components/AppRowLeftLabel.vue';

export default {
    components: {
        ValidationObserver,
        AppRowLeftLabel
    },

    props: ["show", "args", "return", "result"],

    watch: {
        
        "args.location": {
            handler: function(val) {
                this.users_args.id_office_data_location = this.args.location.value;

                this.cArgs.recruiter.name = null;
                this.cArgs.recruiter.value = null;
                this.cArgs.recruiter = null

                this.refresh_users++;
            },
        }
    },

    created(){
        this.applicant_type = this.args.previous_applicant_type;

        if(this.args.recruiter.value == null){
            this.cArgs.recruiter = null;
        }
        if(this.args.consultant.value == null){
            this.cArgs.consultant = null
        }
        if(this.args.location.value == null){
            this.cArgs.location = null;
        }
    },

    computed: {
        computedShow: {
        get() { return this.show },
        set() { this.$emit('update:show', false) }, 
        },

        cArgs: {
            get() { return this.args },
            set() { this.$emit('update:args', false) }
        },

        cReturn: {
            get() { return this.return },
            set() { this.$emit('update:return', false) }
        }
    },

    data() {
        return {
            loading: false,
            refresh_users: 1,
            users_args: {id_office_data_location: this.args.location.value},
            applicant_type: null,
            applicant_types: [
                {value: 1, text: this.trans('fl-uzk',176)},
                {value: 2, text: this.trans('fl-zzp',176)},
                {value: 3, text: this.trans('fl-student',176)},
            ]
        };
    },

    methods: {
        change() {
   
            this.$refs.form.validate().then((result) => {
                if (!result) {
                    return false;
                }
                else{
                    this.loading = true;
                    
                    axios
                        .post("people/profile/registration/change", {
                            id_person_data_main: this.args.id_person_data_main,
                            recruiter: JSON.stringify(this.args.recruiter),
                            consultant: JSON.stringify(this.args.consultant),
                            location: JSON.stringify(this.args.location),
                            applicant_type: this.applicant_type
                        })
                        .then((res) => {
                            this.$emit("update:show", false);
                            this.$emit("update:result", 1);
                            this.cReturn.person_data = res.data;
                            this.cReturn.notes.refresh ++;
                            this.loading = false;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        });
                }
            })
            
        },

        closeModal() {
            this.$emit("update:show", false);
        },

    },
};
</script>

<style scoped>
</style>
