<template>
    <div>
        <b-overlay :show="cLoading" :opacity="0.0">
             <app-view-table :isTitle="false" :loading="cLoading" :isTitleTotal="false" v-on:onRowClicked="item=>$set(item, '_showDetails', !item._showDetails)" :filters.sync="filters" :items="cProfile.documents.items" :fields="fields" :isSettings="false">
                
                            
                    <template v-slot:menu-items="cell">
                        <b-dropdown-item @click="openDocument(cell.data.item)">{{trans('show-document',3)}}</b-dropdown-item>
                        <b-dropdown-item @click="changeDocumentType(cell.data.item)" v-if="[3, 4, 5, 6, 7, 9, 10, 35, 36, 37, 41, 48, 49].includes(cell.data.item.id_document_const_type) && (user_role < 3 || profile.can_user_edit) && profile.person_data.id_status_const_type != 1002">{{trans('type-change',174)}}</b-dropdown-item>
                        <b-dropdown-item @click="deleteDocument(cell.data.item)" v-if="profile.person_data.id_status_const_type > 2 && ![11, 12, 15, 16, 17].includes(cell.data.item.id_document_const_type) && (user_role < 3 || profile.can_user_edit) && profile.person_data.id_status_const_type != 1002 && cell.data.item.signed_loon != true">{{trans('remove',175)}}</b-dropdown-item>
                        <b-dropdown-item @click="verifyDocument(cell.data.item)" v-if="cell.data.item.id_document_const_type == 3 && profile.person_data.id_status_const_type > 2 && (user_role < 3 || profile.can_user_edit) && profile.person_data.id_status_const_type != 1002" >{{trans('check-diplom',174)}}</b-dropdown-item>
                    </template>

                    <template v-slot:row-details="row">
                        <b-row class="app-card-header">
                            <b-col start>
                                <p v-if="row.data.item.user_name != undefined" class="col-list-label">{{trans('fp-user',172)}}</p>
                                <p v-if="row.data.item.date_from != undefined" class="col-list-label">{{trans('create-date',182)}}</p>
                                <p v-if="row.data.item.date_to != undefined" class="col-list-label">{{trans('fp-exp-date',184)}}</p>
                                <p v-if="row.data.item.verify_status != undefined" class="col-list-label">{{trans('fp-validate',184)}}</p>
                            </b-col>
                            <b-col>
                                <p v-if="row.data.item.user_name != undefined" class="col-form-value"> {{row.data.item.user_name}} </p>
                                <p v-if="row.data.item.date_from != undefined" class="col-form-value"> {{row.data.item.date_from}} </p>
                                <p v-if="row.data.item.date_to != undefined" class="col-form-value"> {{row.data.item.date_to}} </p>
                                <p v-if="row.data.item.verify_status != undefined" class="col-form-value"> {{row.data.item.verify_status}} </p>
                            </b-col>                                    
                        </b-row>
                    </template>

                    <template v-slot:buttons>
                         <app-button type="primary" v-if="profile.person_data.id_status_const_type > 2 && (user_role < 3 || profile.can_user_edit) && profile.person_data.id_status_const_type != 1002" :disabled="profile.person_data.applicant_type == 0" @click="addItemStep1()">
                            <span>{{trans('add-document',175)}}</span>
                        </app-button>
                    </template>
                </app-view-table>
            <template #overlay>
                <div></div>
            </template>
        </b-overlay>

        <people-profile-documents-add-step1 v-if="mStep1.show" :show.sync="mStep1.show" :result.sync="mStep1.result" :args.sync="mStep1.args" :return.sync="mStep1.return" />
        <people-profile-documents-add-step2 v-if="mStep2.show" :show.sync="mStep2.show" :result.sync="mStep2.result" :args.sync="mStep2.args" :return.sync="profile" />
        <people-profile-documents-type-change v-if="mTypeChange.show" :show.sync="mTypeChange.show" :result.sync="mTypeChange.result" :args.sync="mTypeChange.args" :return.sync="profile" />
    </div>
</template>

<script>
import axios from "@axios";
import PeopleProfileDocumentsAddStep1 from "./PeopleProfileDocumentsAddStep1.vue";
import PeopleProfileDocumentsAddStep2 from "./PeopleProfileDocumentsAddStep2.vue";
import PeopleProfileDocumentsTypeChange from "./PeopleProfileDocumentsTypeChange.vue";
import AppAlerts from '@core/scripts/AppAlerts';

export default {
    components: { 
        PeopleProfileDocumentsAddStep1,
        PeopleProfileDocumentsAddStep2,
        PeopleProfileDocumentsTypeChange,
    },

    props:["profile", "refresh", "loading"],

    mounted() {
        this.onCreateThis();
    },

    computed: {
        cProfile: {
            get() { return this.profile },
            set() { }
        },

        cRefresh: {
            get() { return this.refresh },
            set(value) {this.$emit('update:refresh', value) }
        },

        cLoading: {
            get() { return this.loading },
            set(value) {this.$emit('update:loading', value) }
        },
    },

    data() {
        
        return {
            alert_class: new AppAlerts(),
            filters: {
                sorting: {
                        field_name: '',
                        sort_direction: 0,
                        changed: 0
                },
                filtering: {                        
                    types: {type: 'list', changed: 0, values: [], function: "getCurrentDocumentTypes", parameters: {id_person_data_main: this.profile.id}}
                },
                page: 1,
                per_page: 5,
                search: "",
                changed: 0,
                type: 1,
            },

            fields: [
                { key: "document_type_name", label: this.trans('document-type',182), thStyle: { "min-width": "250px", "max-width": "250px" },visible: true, filtering: true, filtering_name: "types" },
                { key: "document_name", label: this.trans('document-name',182), thStyle: {  "width": "100%" }, tdClass:"text-truncate", tdAttr: {  style:"min-width: 150px; max-width: 300px;" },visible: true },
                { key: "entry_date", label: this.trans('entry-date-on',182), thStyle: { "min-width": "190px", "max-width": "190px" },visible: true },
                { key: "portal_publish_text", label: this.trans('portal',182), thStyle: {  "min-width": "100px", "max-width": "100px" }, tdClass:"text-truncate",visible: true },
            ],
            
            url:"",

            mStep1: {show: false, args: {applicant_type: 0}, result: 0, return: null},
            mStep2: {show: false, args: {id_document_const_type: 0, id_person_data_main: 0 , filters: null, return_type: null}, result: 0, return: null},
            mTypeChange: {show: false, args: {
                applicant_type: null,
                id_document_data_main: null,
                filters: null,
                id_person_data_main: null,
                id_document_const_type: null,
                mine_type: null,
                }, result: 0, return: null},
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
        };
    },

    methods: {

        getList: function() {
            this.cLoading = true;

            if (this.profile.documents.filter_types != null) {
                this.filters.filtering.types.values = this.profile.documents.filter_types;
                this.cProfile.documents.filter_types = null;
            }

            axios
                .post("people/profile/documents/getList", {
                    id_person_data_main: this.profile.id,
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {
                    this.cProfile.documents.items = res.data.items;
                    this.filters.page = res.data.page;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.cLoading = false;
                });
        },
      
        addItemStep1() {
            this.mStep1.args.applicant_type =  this.profile.person_data.applicant_type;
            this.mStep1.return = 0;
            this.mStep1.show = true;
        },

        addItemStep2(id_document_const_type) {
            this.mStep2.args.id_document_const_type = id_document_const_type;
            this.mStep2.args.id_person_data_main = this.profile.id;
            this.mStep2.args.filters = this.filters; 
            this.mStep2.args.return_type = "documents";            
            this.mStep2.show = true;

        },
        
        /*publishDocument(id, portal_publish){
            this.loadingFilters = true;
            if(portal_publish == 0){
                portal_publish = 1;
            }else{
                portal_publish = 0;
            }
            axios
                .post("people/profile/documents/publish", {
                    
                    id: id,
                    portal_publish: portal_publish,
                    id_person_data_main: this.id_person_data_main,
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {
                    this.items = res.data.list;
                    this.totalRows = res.data.total;
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.loadingFilters = false;
                });
        },*/

        openDocument: function(data) {
            
            if(data.document_status == 0 || data.document_status == 1 || data.document_status == 3){//0 - zawsze wysweitlamy (dokumenty H1), 1 - zaimportowany można otworzyć

                this.cLoading = true;
                axios
                    .post("people/profile/documents/downloadFile", {
                        id_document_data_main: data.id,
                        id_person_data_main: this.profile.id,
                        type: 1,
                    }, 
                    {
                        responseType: 'blob', 
                        crossDomain: true,
                    })
                    .then((res) => {

                        const url = window.URL.createObjectURL(new Blob([res.data], {type : data.mine}));
                        const link = document.createElement('a');
                        link.href = url;
                        link.target= "_blank";
                        data.mine == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                        data.mine == 'application/msword' ||
                        data.mine == 'text/plain'
                                        ? link.setAttribute('download', data.document_name):link.setAttribute('open', data.document_name); 
                        link.setAttribute('open', data.document_name); 
                        document.body.appendChild(link);
                        link.click();
                    })
                    .catch((error) => {
                        this.alert_class.openAlertWarning(this.trans('file-not-found',180)).then(res => {})
                    })
                    .finally(() => {
                        this.cLoading = false;
                    });

            }else if(data.document_status == 2){//2 - zaimportowany typu hidden
                this.alert_class.openAlertWarning(this.trans('fp-migrated-document-cannot-be-opened',177)).then(res => {});
            }

        },

        
        verifyDocument(data){
            this.cLoading = true;
           
             axios
                .post("people/profile/documents/verify", {
                    
                    id: data.id,
                    id_person_data_main: this.profile.id,
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {
                    this.cProfile.documents.items = res.data.items;
                    this.cProfile.compliance_check.refresh ++;
                    this.filters.page = res.data.page;
                })
                .catch((error) => {
                    this.alert_class.openAlertWarning(this.trans('file-not-found',180)).then(res => {})
                })
                .finally(() => {
                   this.cLoading = false;
                });
        },

         deleteDocument(data){
            this.alert_class.openAlertConfirmation(this.trans('delete-document-confirmation',177,{document_name:data.document_name})).then(res => {
            if(res == true) {

                this.cLoading = true;
                
                axios
                    .post("people/profile/documents/removeFile", {                        
                        id_document_data_main: data.id,
                        id_person_data_main: this.profile.id,
                        filters: JSON.stringify(this.filters),
                    })

                    .then((res) => {
                        this.cProfile.compliance_check.refresh ++;
                        this.filters.page = res.data.page;
                        this.cProfile.documents.items = res.data.items;
                    })
                    .catch((error) => {
                        console.error(error);
                    })
                    .finally(() => {
                        this.cLoading = false;
                    });
                }
            })
        },

        changeDocumentType(item){
            this.mTypeChange.args.applicant_type = this.profile.person_data.applicant_type;
            this.mTypeChange.args.id_document_data_main = item.id;
            this.mTypeChange.args.id_person_data_main = this.profile.id;
            this.mTypeChange.args.id_document_const_type = item.id_document_const_type;
            this.mTypeChange.args.mine_type = item.mine_type;
            this.mTypeChange.args.filters = JSON.stringify(this.filters);
            this.mTypeChange.show = true;
        },


        
        onCreateThis() { 

            this.getList();

            this.$root.$on('locationsChanged', () => {
        
                if (!this.cLoading) {
                    this.getList();
                } 
            });
            
            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                } 
            });


            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                }   
            });

            this.$watch('filters.filtering.types.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                } 
            });

            this.$watch('filters.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                } 
            });

            this.$watch('filters.search', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.filters.page = 1;
                    this.getList();
                } 
            });

            this.$watch('profile.documents.items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('mStep1.return', function(newVal, oldVal) {
                
                if (newVal > 0) {
                    
                    //kiedy chcemy dodać id/paszport ktory jest juz zweryfikowany musimy zapytac czy chcemy kontynuowac dodawanie dokumentu
                    if(this.profile.person_data.id_status_const_type > 2 && newVal == 21 && this.profile.person_data.id_status_const_type != 1002){
                        this.cProfile.compliance_check.items.forEach(item => {
                            if(item.id_compliance_const_type == 1 && [2, 3].includes(item.verified) && this.profile.person_data.id_status_const_type != 1002){
                                this.alert_class.openAlertConfirmation(this.trans('new-id-upload-confirmation',177)).then(res => {
                                    if(res == true) {
                                        this.addItemStep2(newVal);
                                    }
                                });
                            }else if(item.id_compliance_const_type == 1 && item.verified < 2){
                                this.addItemStep2(newVal);
                            }
                        });

                    }else if(this.profile.person_data.id_status_const_type > 2 && newVal == 37){
                        this.cProfile.compliance_check.items.forEach(item => {
                            if(item.id_compliance_const_type == 12 && item.dot_status > 0){
                                this.alert_class.openAlertConfirmation(this.trans('new-bank-card-upload-confirmation',177)).then(res => {
                                    if(res == true) {
                                        this.addItemStep2(newVal);
                                    }
                                });
                            }else if(item.id_compliance_const_type == 12 && item.dot_status == 0){
                                this.addItemStep2(newVal);
                            }
                        });

                    }else{
                        this.addItemStep2(newVal);
                    }
                }  
            });
        },

    },
};
</script>

<style scoped>
</style>
