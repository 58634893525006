<template>
    <b-modal ref="modal-add" scrollable size="md" v-model="computedShow" no-close-on-backdrop :title="trans('fp-change-pay-frequency',172)">
         <ValidationObserver ref="form" >
        <b-form @submit.prevent="onSubmit">

            <app-row-left-label :label="trans('entry-date',182)">
                <app-date id="vi-date-from" v-model="date_from" validatorRules="date-check|required" validatorName="Ingangsdatum"/>
            </app-row-left-label>
            <app-row-left-label :label="trans('cp-applying-frequency',184)">
                <app-radio-group v-model="period_value" :options="opt" validatorName="Loonheffingskorting toepassen" validatorRules="required" :validatorCustomMessage="{ required: trans('field-required-error',180) }" />
            </app-row-left-label>
            
        </b-form>
         </ValidationObserver>
        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="addPeriod"  :loading="loading">
                {{trans('add',175)}}
            </app-button>
        </template>
    </b-modal>
</template>

<script>

import axios from "@axios";
import { ValidationObserver } from "vee-validate";

export default {
    components: {
        ValidationObserver,
    },

    props: ["show", "result", "args", "return"],

    computed: {
        computedShow: {
            get() { return this.show },
            set() { this.$emit('update:show', false) }, 
        },

        cReturn: {
            get() { return this.return },
            set() { this.$emit('update:return', false) }
        }
    },
    
    data() {
        return {
            loading: false,
            status: 0,
            
            opt:  [
                { value: 1, text: this.trans('week',3) },
                { value: 2, text: this.trans('4-weeks',3) },
                { value: 3, text: this.trans('month',3) },
            ],
            
            date_from: null,
            period_value: null         
        };
    },


    methods: {
        addPeriod() {
            this.$refs.form.validate().then((result) => {
                if (!result) { 
                    return false;
                }else{
                    this.loading = true;
                    axios
                        .post("people/profile/wage-details/addPeriod", {
                            id_person_data_main: this.args.id_person_data_main,
                            date_from: this.date_from,
                            period: this.period_value,
                            filters: JSON.stringify(this.args.filters),
                        })
                        .then((res) => {
                            
                            this.$emit("update:show", false);
                            this.$emit("update:result", 1);
                            
                            this.cReturn.loon_wages.period.items = res.data.loon_wages.items;
                            this.cReturn.person_data = res.data.person_data;

                            this.loading = false;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        });
                    }
            });
        },
        closeModal() {
            this.$emit("update:show", false);
        },
    },
};
</script>

<style>
</style>
