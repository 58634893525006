<template>
    <div>
        <b-row v-if="profile.person_data.id_status_const_type > 1 && (user_role < 3 || profile.can_user_edit) && profile.person_data.id_status_const_type != 1002" class="app-component-icon-edit">
            <b-dropdown variant="link"  no-caret right>
                <template  #button-content>
                    <b-icon icon="three-dots-vertical" class="text-body"></b-icon>
                </template>
                <b-dropdown-item @click="change()">{{trans('change',175)}}</b-dropdown-item>
                <b-dropdown-item @click="changeAvatar()">{{trans('avatar-change',174)}}</b-dropdown-item>
            </b-dropdown>
        </b-row>

        <b-row class="app-card-header">
            <b-col start>
                <p class="col-list-label">{{trans('f-gender',184)}}</p>
                <p class="col-list-label">{{trans('first-name',182)}}</p>
                <p class="col-list-label">{{trans('inbetween-name',182)}}</p>
                <p class="col-list-label">{{trans('last-name',182)}}</p>
                <p class="col-list-label" v-if="profile.person_data.sex == 2">{{trans('fw-inbetween-maiden-name',184)}}</p>
                <p class="col-list-label" v-if="profile.person_data.sex == 2">{{trans('fw-maiden-name',184)}}</p>
                <p class="col-list-label">{{trans('birth-date',184)}}</p>
                <p class="col-list-label">{{trans('birth-place',184)}}</p>
                <p class="col-list-label">{{trans('fp-marital-status',184)}}</p>
                <p class="col-list-label">{{trans('phone-number',182)}}</p>
                <p class="col-list-label">{{trans('email',182)}}</p>
            </b-col>
            <b-col>
                <p class="col-form-value">{{profile.person_data.sex > 0 ? (profile.person_data.sex === 1 ? trans('gender-male',184) : trans('gender-female',184)) : '-'}}</p>
                <p class="col-form-value">{{profile.person_data.first_name ? profile.person_data.first_name : '-'}}</p>
                <p class="col-form-value">{{profile.person_data.inbetween_name ? profile.person_data.inbetween_name : '-'}}</p>
                <p class="col-form-value">{{profile.person_data.last_name ? profile.person_data.last_name : '-'}}</p>
                <p class="col-form-value" v-if="profile.person_data.sex == 2">{{profile.person_data.inbetween_maiden_name ? profile.person_data.inbetween_maiden_name : '-'}}</p>
                <p class="col-form-value" v-if="profile.person_data.sex == 2">{{ (profile.person_data.maiden_name ? profile.person_data.maiden_name : '-')}}</p>
                <p class="col-form-value">{{profile.person_data.birth_date ? profile.person_data.birth_date : '-'}}</p>
                <p class="col-form-value">{{profile.person_data.birth_place ? profile.person_data.birth_place : '-'}}</p>
                <p class="col-form-value">{{profile.person_data.martial_name ? profile.person_data.martial_name : '-'}}</p>
                <p class="col-form-value">
                    <span v-if="profile.person_data.phone_number">{{phone}}</span>
                    <span v-if="!profile.person_data.phone_number">-</span>
                </p>
                <p class="col-form-value">
                    <span v-if="profile.person_data.email">{{profile.person_data.email}}</span>
                    <span v-if="!profile.person_data.email">-</span>
                </p>
            </b-col>
        </b-row>

        <b-row class="app-card-header mt-2">
                <b-col>
                     <div class="col-list-label"><b>{{trans('fp-mobile-informations',172)}}</b></div>
                </b-col>
        </b-row>
        <b-row class="app-card-header">
            <b-col start>
                <p class="col-list-label">{{trans('fp-last-activity',172)}}</p>
                <p class="col-list-label">{{trans('fp-push-shifts',172)}}</p>
                <p class="col-list-label">{{trans('fp-push-hours',172)}}</p>
            </b-col>
            <b-col>
                <p class="col-form-value">{{profile.person_data.mobile_last_activity ? profile.person_data.mobile_last_activity : '-'}}</p>
                <p class="col-form-value">{{profile.person_data.mobile_notifications != null && JSON.parse(profile.person_data.mobile_notifications).ids_notificationsettings_const_type.includes(1) ? trans('yes-option', 3) : trans('no-option', 3)}}</p>
                <p class="col-form-value">{{profile.person_data.mobile_notifications != null && JSON.parse(profile.person_data.mobile_notifications).ids_notificationsettings_const_type.includes(2) ? trans('yes-option', 3) : trans('no-option', 3)}}</p>
            </b-col>
        </b-row>
          
        <people-profile-personal-information-change v-if="mPersonalInformationChange.show" :show.sync="mPersonalInformationChange.show" :result.sync="mPersonalInformationChange.result" :args.sync="mPersonalInformationChange.args" :return.sync="profile"/> 
        <people-profile-personal-avatar-change   v-if="mPersonalAvatarChange.show" :show.sync="mPersonalAvatarChange.show" :result.sync="mPersonalAvatarChange.result" :args.sync="mPersonalAvatarChange.args" :return.sync="profile" />
    </div>
</template>
<script>

import PeopleProfilePersonalInformationChange from "./PeopleProfilePersonalInformationChange.vue";
import PeopleProfilePersonalAvatarChange from "./PeopleProfilePersonalAvatarChange.vue";

export default {
    components: {
        PeopleProfilePersonalInformationChange,
        PeopleProfilePersonalAvatarChange
    },

    props:["profile"],

    computed: {
        phone: function () {
            if (this.profile.person_data.phone_number !== null && this.profile.person_data.phone_prefix == '+31') {
                return 0 + this.profile.person_data.phone_number.replace(/^(.{1})(.{3})(.{3})(.*)$/, "$1 $2 $3 $4");
                //return this.profile.person_data.phone_prefix + " " + this.profile.person_data.phone_number;
            } else {
                return this.profile.person_data.phone_prefix + " " + this.profile.person_data.phone_number;
            }
        }
    },

    data(){
        return{
            mPersonalInformationChange: { show: false, 
                                          args: { 
                                               id_person_data_main: null,
                                               sex: null,
                                               first_name: '',
                                               inbetween_name: '',
                                               last_name: '',
                                               maiden_name: null,
                                               inbetween_maiden_name: null,
                                               birth_date: null,
                                               birth_place: null,
                                               martial_status: null, 
                                               phone_prefix: null,
                                               applicant_type: null,
                                               email:null

            }, result: 0, return: null },

            mPersonalAvatarChange: { show: false, 
                                          args: {id_person_data_main: null, avatar: null }, result: 0, return: null },
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
        }
    },

    methods:{       
        change(){
            this.mPersonalInformationChange.args.id_person_data_main = this.profile.id;
            this.mPersonalInformationChange.args.sex = this.profile.person_data.sex;
            this.mPersonalInformationChange.args.first_name = this.profile.person_data.first_name;
            this.mPersonalInformationChange.args.inbetween_name = this.profile.person_data.inbetween_name;
            this.mPersonalInformationChange.args.inbetween_maiden_name = this.profile.person_data.inbetween_maiden_name;
            this.mPersonalInformationChange.args.maiden_name = this.profile.person_data.maiden_name;
            this.mPersonalInformationChange.args.last_name = this.profile.person_data.last_name;
            this.mPersonalInformationChange.args.birth_date = this.profile.person_data.birth_date;
            this.mPersonalInformationChange.args.birth_place = this.profile.person_data.birth_place;
            this.mPersonalInformationChange.args.applicant_type = this.profile.person_data.applicant_type;
            this.mPersonalInformationChange.args.martial_status = {name: this.profile.person_data.martial_name, value: this.profile.person_data.id_martial_const_type};
            this.mPersonalInformationChange.args.phone_prefix = this.profile.person_data.phone_prefix == null ? '+31' : this.profile.person_data.phone_prefix;
            this.mPersonalInformationChange.args.phone_number = this.profile.person_data.phone_number;
            this.mPersonalInformationChange.args.email = this.profile.person_data.email;
            this.mPersonalInformationChange.show = true;
        },

        changeAvatar(){
            this.mPersonalAvatarChange.args.id_person_data_main = this.profile.id;
            this.mPersonalAvatarChange.args.avatar =  this.profile.person_data.avatar
            this.mPersonalAvatarChange.show = true;
        }
    }
 
};
</script>

<style scoped>

</style>