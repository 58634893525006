<template>
    <div>
        <b-overlay :show="loading_list" :opacity="0.0">
            <b-overlay :show="loading_list" :opacity="0.0">
           
                <app-view-table :title="trans('wage-statement',172)" :loading="loading_list" :isTitleTotal="false" :filters.sync="filters" :items="cProfile.loon_wages.payroll.items" :isDropdownMenu="profile.person_data.id_status_const_type != 1002" :isMenuColumn="user_role < 3 || profile.can_user_edit" :fields="fields">
                
                            
                    <template v-slot:menu-items="cell">
                        <b-dropdown-item @click="resendLink(cell.data.item)" v-if="cell.data.item.status == 1 || cell.data.item.status == 4">{{trans('resend-form',175)}}</b-dropdown-item>
                        <b-dropdown-item @click="deleteItem(cell.data.item)" v-if="cell.data.item.status != 2 ">{{trans('remove',175)}}</b-dropdown-item>
                        <b-dropdown-item @click="scrollToDocument(cell.data.item)" v-if="cell.data.item.status == 2">{{trans('show-document', 3)}}</b-dropdown-item>
                    </template>

                    <template v-slot:buttons>
                        <app-button type="secondary" v-if="[1,3].includes(profile.person_data.applicant_type) && 
                                                     profile.person_data.id_status_const_type == 100 && 
                                                     profile.person_data.is_contract == 1 && 
                                                     profile.person_data.is_payroll_tax == 0 && (user_role < 3 || profile.can_user_edit)" @click="addItem" size="sm">
                            <span>Stuur loonheffing formulier</span>
                        </app-button>

                        <app-button type="secondary" v-if="[1,3].includes(profile.person_data.applicant_type) && 
                                                     profile.person_data.id_status_const_type == 100 && 
                                                     profile.person_data.is_contract == 1 && 
                                                     profile.person_data.is_payroll_tax == 1 && 
                                                     (profile.person_data.payroll_tax_status == 2) && (user_role < 3 || profile.can_user_edit)" @click="addItem" size="sm">
                            <span>{{trans('cp-change-payroll-taxes',192)}}</span>
                        </app-button>
                    </template>
                </app-view-table>
            <template #overlay>
                <div></div>
            </template>
        </b-overlay>
            <template #overlay>
                <div></div>
            </template>
        </b-overlay>
        <people-profile-loon-wages-payroll-add v-if="mAdd.show" :show.sync="mAdd.show" :result.sync="mAdd.result" :args.sync="mAdd.args" :return.sync="profile"/>

    </div>
</template>

<script>

import AppAlerts from '@core/scripts/AppAlerts';
import axios from "@axios";
import PeopleProfileLoonWagesPayrollAdd from './PeopleProfileLoonWagesPayrollAdd.vue';

export default {
    components: { 
        PeopleProfileLoonWagesPayrollAdd
    },

    props:["profile"],

    mounted() {
        this.onCreateThis();
    },

    watch:{
        "profile.loon_wages.payroll.items.refresh": {
            handler: function(val) {
                if (val > 0) {
                    this.getList();
                }                
            },
        }
    },

    computed: {
        cProfile: {
            get() { return this.profile },
            set(value) { this.$emit('update:profile', value) }
        }
    },

    data() {
            return {
                    alert_class: new AppAlerts(),
                    loading_list: false,
                    filters: {
                                page: 1,
                                per_page: 3,
                                changed:0,
                                sorting: {
                                        field_name: null,
                                        sort_direction: 0,
                                        changed: 0
                                },
                    },
                    fields: [
                                { key: "date_from", label: this.trans('entry-date',182), thStyle: {"min-width": "150px", "max-width": "200px"}, visible: true},
                                { key: "payroll", label: this.trans('fp-apply-payroll',184), thStyle: {  "min-width": "200px", width: "100%" }, visible: true },
                                { key: "payroll_status", label: this.trans('status',3), thStyle: {"min-width": "200px", "max-width": "300px"}, visible: true },
                    ],
                    mAdd: { show: false, args: {id_person_data_main: null, filters: null, return_type: null}, result: 0, return: "profile" },
                    user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,

        
            }
    },


    methods: {

        getList: function() {
            this.loading_list = true;
            axios
                .post("people/profile/wage-details/getPayroll", {
                    id_person_data_main: this.profile.id,
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {
                    this.filters.page = res.data.page;
                    this.cProfile.loon_wages.payroll.items = res.data.items;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_list = false;
                });; 
        },

        deleteItem(data){
            this.alert_class.openAlertConfirmation(this.trans('delete-line-confirm',177)).then(res => {
                if(res == true){
                    this.loading_list = true;
                    axios
                        .post("people/profile/wage-details/deletePayroll", {
                            id_wage_data_main: data.id,
                            id_person_data_main: this.profile.id,
                            filters: JSON.stringify(this.filters),
                        })
                        .then((res) => {
                            this.filters.page = res.data.page;
                            this.cProfile.loon_wages.payroll.items = res.data.items;
                            this.cProfile.refresh ++;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            this.loading_list = false;
                        });; 
                }     
            }); 
        },

        resendLink(data){

            this.alert_class.openAlertConfirmation(this.trans('resend-form-confirmation',180)).then(res => {
                if(res == true){
                    this.loading_list = true;
                    axios
                        .post("people/profile/wage-details/resendLink", {
                            id_wage_data_main: data.id,
                            id_person_data_main: this.profile.id,
                            filters: JSON.stringify(this.filters)
                        })
                         .then((res) => {
                            this.filters.page = res.data.page;
                            this.cProfile.loon_wages.payroll.items = res.data.items;
                            this.cProfile.refresh ++;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            this.loading_list = false;
                        });; 
                }     
            });

        },
        
        addItem() {
            this.mAdd.args.id_person_data_main = this.profile.id;
            this.mAdd.args.filters = this.filters;
            this.mAdd.args.return_type = "wages";
            this.mAdd.show = true;
        },

        scrollToDocument(documentType){
            this.cProfile.documents.filter_types = [25];
            this.$emit('scrollToDocument', documentType);
        },

        onCreateThis() { 
            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });
            this.$watch('profile.loon_wages.payroll.items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('filters.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }  
            });
            

        }

        
    }

};
</script>

<style scoped>

</style>
