import { render, staticRenderFns } from "./PersonProfileCompetenceComponent.vue?vue&type=template&id=20b4a166&scoped=true&"
import script from "./PersonProfileCompetenceComponent.vue?vue&type=script&lang=js&"
export * from "./PersonProfileCompetenceComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20b4a166",
  null
  
)

export default component.exports