<template>
    <b-modal ref="modal-add" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('change-standard-rate',172)">
        <ValidationObserver ref="form" >
            <b-form @submit.prevent="onSubmit">

                <app-row-left-label :label="trans('date-from',182)">
                    <app-date v-model="cArgs.date_from" :validatorName="trans('date-from',182)" validatorRules="required|date-check" />
                </app-row-left-label>

                <app-row-left-label :label="trans('function',3)">
                    <app-select v-model="cArgs.function" type="getFunctions" :validatorName="trans('function',3)" validatorRules="required"/>
                </app-row-left-label>

                <app-row-left-label :label="trans('pl-day-hourly-rate',223)">
                    <app-input type="decimal_02" v-model="cArgs.rates_options.rate_day" />
                </app-row-left-label>
                 <app-row-left-label :label="trans('pl-evening-rate',223)">
                    <app-input type="decimal_02" v-model="cArgs.rates_options.rate_evening" />
                </app-row-left-label>
                 <app-row-left-label :label="trans('pl-night-rate',223)">
                    <app-input type="decimal_02" v-model="cArgs.rates_options.rate_night" />
                </app-row-left-label>
                 <app-row-left-label :label="trans('pl-weekend-rate',223)">
                    <app-input type="decimal_02" v-model="cArgs.rates_options.rate_weekend" />
                </app-row-left-label>
                 <app-row-left-label :label="trans('pl-saturday-rate',223)">
                    <app-input type="decimal_02" v-model="cArgs.rates_options.rate_saturday" />
                </app-row-left-label>
                 <app-row-left-label :label="trans('pl-sunday-rate',223)">
                    <app-input type="decimal_02" v-model="cArgs.rates_options.rate_sunday" />
                </app-row-left-label>
                 <app-row-left-label :label="trans('pl-sleeper-service-rate',223)">
                    <app-input type="decimal_02" v-model="cArgs.rates_options.rate_sleep_service" />
                </app-row-left-label>
                 <app-row-left-label :label="trans('pl-full-day-rate',223)">
                    <app-input type="decimal_02" v-model="cArgs.rates_options.rate_24_hours" />
                </app-row-left-label>
                 <app-row-left-label :label="trans('pl-holidays-rate',223)">
                    <app-input type="decimal_02" v-model="cArgs.rates_options.rate_holidays" />
                </app-row-left-label>

                <small v-if="rates_error == true" class="text-danger" style="float:right">{{trans('minimum-one-rate-required',180)}}</small> 

                <b-alert variant="warning" v-if="is_exist == true" show class="mb-0">
                    <div class="text-md-start"> {{trans('fp-rates-with-given-parameters-already-exist',177)}}</div> 
                </b-alert> 

            </b-form>
        </ValidationObserver>

        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="change" :loading="loading">
                {{trans('save',175)}}
            </app-button>
        </template>
    </b-modal>
</template>
<script>

import axios from "@axios";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {

    components:{
        ValidationObserver
    },

    props: ["show", "result", "args", "return"],

    computed: {
        computedShow: {
            get() { return this.show },
            set(value) { this.$emit('update:show', value) }
        },

        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },

        cReturn: {
        get() { return this.return },
        set() { this.$emit('update:return', false) }
        }
    },

    watch: {
        "cArgs.rates_options": {
            handler: function(val) {
                this.rates_error = false;              
            },
            deep: true,
          },
    },

    data(){
        return{
            loading: false,
            rates_error: false,
            is_exist: false,
        }
    },

    methods: {

        change(){
            this.$refs.form.validate().then((result) => {
                if (!result) {
                    return false;
                }else{
                    if( !this.cArgs.rates_options.rate_day && !this.cArgs.rates_options.rate_evening && !this.cArgs.rates_options.rate_night && !this.cArgs.rates_options.rate_weekend && 
                        !this.cArgs.rates_options.rate_saturday && !this.cArgs.rates_options.rate_sunday && !this.cArgs.rates_options.rate_sleep_service && !this.cArgs.rates_options.rate_24_hours 
                        && !this.cArgs.rates_options.rate_holidays){
                        return this.rates_error = true;
                    }
                    this.loading = true;

                    axios
                        .post('people/profile/wages/changeIndependent', {
                            id_wage_data_independent: this.args.id_wage_data_independent,
                            id_person_data_main: this.args.id_person_data_main,
                            selected_function: this.cArgs.function.value,
                            date_from: this.cArgs.date_from,
                            filters: JSON.stringify(this.args.filters),
                            rates_options: JSON.stringify(this.cArgs.rates_options),
                        })
                        .then((res) => {
                            if(res.data === -1){
                                this.is_exist = true;
                            }else{
                                this.cReturn.wages.independent.items = res.data.items;
                            this.$emit("update:result", 1);
                                this.computedShow = false;
                            }
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            this.loading = false;
                        })

                }
            });
        },

        closeModal(){
            this.computedShow = false;
        }
    }
    
}
</script>
<style>

</style>