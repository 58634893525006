<template>
    <b-modal ref="modal-change" size="xl" v-model="computedShow" no-close-on-backdrop :title="trans('fp-open-shifts', 172)">

        <b-card>
            <app-view-table title="" :isTitle="true" :fields="fields" :items="items" :filters="filters" :loading="loading_list" :topFiltersCount="1">

                <template v-slot:menu-items="cell">
                    <b-dropdown-item @click="openShift(cell.data.item)">{{trans('to-service',200)}}</b-dropdown-item>
                </template>

                <template v-slot:view-title>
                    <div class="d-flex flex-wrap" v-if="!loading_list">
                        <b-badge pill variant="secondary" class="mr-2 mb-1" v-if="clients">{{trans('organization', 169)}}: {{clients}}</b-badge>
                        <b-badge pill variant="secondary" class="mr-2 mb-1" v-if="args.tod">{{trans('day-part',172)}}: {{args.tod}}</b-badge>
                        <b-badge pill variant="secondary" class="mr-2 mb-1" v-if="args.sectors">{{trans('fp-sector',172)}}: {{args.sectors}}</b-badge>
                        <b-badge pill variant="secondary" class="mr-2 mb-1" >{{args.plan_preferences.working_area.location_name}} ({{args.plan_preferences.working_area.lat}}, {{args.plan_preferences.working_area.lng}})</b-badge>
                        <b-badge pill variant="secondary" class="mr-2 mb-1">{{trans('radius',184)}}: {{args.plan_preferences.working_area.radius}}km</b-badge>
                        <b-badge pill variant="secondary" class="mr-2 mb-1">{{trans('date',182)}}: {{parseDate(filters.period.date_from)}} - {{parseDate(filters.period.date_to)}}</b-badge>
                    </div>
                </template>

            </app-view-table>

        </b-card>

        <template #modal-footer>

                <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
        </template>
    </b-modal>
</template>
<script>
import axios from "@axios";
import moment from "moment";


export default {

    components: {

    },

    created(){
        this.setPeriod();
    },

    mounted(){
        this.getList();
    },

    props: ["show", "args", "return", "result"],

    computed: {
        computedShow: {
        get() { return this.show },
        set() { this.$emit('update:show', false) }, 
        },

        cArgs: {
            get() { return this.args },
            set() { this.$emit('update:args', false) }
        },

        cReturn: {
            get() { return this.return },
            set() { this.$emit('update:return', false) }
        },
    },

    watch: {
        "items.refresh": {
            handler: function(val){
                if(!this.loading_list){
                    this.getList();
                }
            }
        },

        "filters.per_page": {
            handler: function(val){
                if(!this.loading_list){
                    this.filters.page = 1;
                    this.getList();
                }
            }
        },

        "filters.page": {
            handler: function(val){
                if(!this.loading_list){
                    this.getList();
                }
            }
        },

        "filters.changed": {
            handler: function(val){
                if(!this.loading_list){
                    this.filters.page = 1;
                    this.getList();
                }
            }
        },

        "filters.sorting.changed":{
            handler: function(val){
                if(!this.loading_list){
                    this.getList();
                }
            }
        },

        "filters.filtering.client_names.changed": {
            handler: function(val){
                if(!this.loading_list){
                    this.getList();
                }
            }
        },

        "filters.filtering.client_locations.changed": {
            handler: function(val){
                if(!this.loading_list){
                    this.getList();
                }
            }
        },

        "filters.filtering.client_departments.changed": {
            handler: function(val){
                if(!this.loading_list){
                    this.getList();
                }
            }
        },

        "filters.filtering.functions.changed": {
            handler: function(val){
                if(!this.loading_list){
                    this.getList();
                }
            }
        },

        "filters.filtering.dates.changed": {
            handler: function(val){
                if(!this.loading_list){
                    this.getList();
                }
            }
        }
        
    },  

    data(){
        return{
            loading: false,
            loading_list: false,
            items: {list: [], total: null, refresh :0},
            filters: {

                filtering:{
                    client_names: { type: 'list', changed: 0, values: [], function: "getClients", parameters: null, widthClass:'app-width-min-350'},           
                    client_locations: { type: 'list', changed: 0, values: [], function: "getClientLocations", parameters: null, widthClass:'app-width-min-350'},
                    client_departments: { type: 'list', changed: 0, values: [], function: "getClientDepartments", parameters: null, widthClass:'app-width-min-350'},
                    functions: { type: 'list', changed: 0, values: [], function: "getFunctions", parameters: null, widthClass:'app-width-min-350'},
                    dates: { type: 'dates', changed: 0, values: [],  function: "", parameters: {type: null}, widthClass:'app-width-min-350'},
                },
                sorting: {
                        field_name: '',
                        sort_direction: 0,
                        changed: 0
                },
                search: "",
                page: 1,
                changed: 0,
                per_page: 10,
                total: 0,
                period: {
                    date_from: null,
                    date_to: null
                }

            },
            fields:[
                { key: "client_name", label: this.trans('organization',169), thStyle: { "min-width": "150px", "max-width": "150px" }, visible: true, filtering: true, filtering_name: "client_names"},
                { key: "location_name", label: this.trans('location',3), thStyle: { "min-width": "150px", "max-width": "150px" }, visible: true, filtering: true, filtering_name: "client_locations"},
                { key: "department_name", label: this.trans('department',182), thStyle: { "width": "100%" }, visible: true, filtering: true, filtering_name: "client_departments"},
                { key: "function_name", label: this.trans('function',3), thStyle: { "min-width": "150px", "max-width": "150px" }, visible: true, filtering: true, filtering_name: "functions"},
                { key: "shift_date", label: this.trans('date',182), thStyle: { "min-width": "100px", "max-width": "100px" }, visible: true, filtering: true, filtering_name: "dates"},
                { key: "time_from", label: this.trans('planning-begin',209), thStyle: { "min-width": "80px", "max-width": "80px" }, visible: true, filtering: false},
                { key: "time_to", label: this.trans('planning-end',209), thStyle: { "min-width": "80px", "max-width": "80px" }, visible: true, filtering: false},
            ],
            clients: null
        }
    },

    methods: {

        getList(){
            this.loading_list = true;

                axios
                    .post('people/profile/plan-preferences/getOpenShifts', {
                        id_person_data_main: this.cArgs.id_person_data_main,
                        filters: JSON.stringify(this.filters)
                    })
                    .then((res) => {
                        this.items.list = res.data.items.items;
                        this.items.total = res.data.items.total;
                        if('clients' in res.data){
                            this.clients = res.data.clients;
                        }
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    })
                    .finally(() => {
                        this.loading_list = false;
                    });
               
            
        },

        setPeriod(){
            if( 'period' in this.args.plan_preferences){
                this.filters.period = this.args.plan_preferences.period;
            } else {
                var now = moment();
                this.filters.period.date_from = now.format('YYYY-MM-DD');
                this.filters.period.date_to = now.add(15, 'd').format('YYYY-MM-DD');

            }
        },

        parseDate(date){
            return moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY');
        },

        openShift(item){
            this.$router.push({name: "planning-shifts-profile", params: { id_shift_data_main: item.id_shift_data_main } });
        },

        closeModal() {
            this.$emit("update:show", false);
        },

    }
    
}
</script>
<style scoped>

</style>