<template>
    <b-modal ref="modal-change" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('fp-change-pension',172)">
        <b-row>
            <b-col cols="12">
                <ValidationObserver ref="form" >
                    <app-row-left-label :label="trans('fp-pension-type',172)">
                        <app-radio-group v-model="cArgs.pension_type" :options="pension_types" :validatorCustomMessage="{ required: trans('field-required-error', 180) }" validatorRules="required" :disabled="cArgs.pension_free_weeks == 8"/>
                    </app-row-left-label>

                    <app-row-left-label :label="trans('pw-number-of-weeks-accrued',196)" v-if="cArgs.pension_type == 1">
                        <app-input v-model="cArgs.previous_worked_weeks" validatorRules="required|correct-pension-count" :validatorName="trans('pw-number-of-weeks-accrued',196)" mask="##" :disabled="cArgs.pension_free_weeks == 8"/>
                    </app-row-left-label>

                    <app-row-left-label :label="trans('fp-number-of-pension-free-weeks',172)" v-if="cArgs.pension_type != 2">
                        <app-input v-model="cArgs.pension_free_weeks" mask="#" :disabled="true"/>
                    </app-row-left-label>

                    <app-row-left-label :label="trans('fp-number-of-weeks-in-basic',172)" v-if="cArgs.pension_type != 2">
                        <app-input v-model="cArgs.pension_basic_weeks" mask="##" :disabled="true"/>
                    </app-row-left-label>
                </ValidationObserver>
            </b-col>

        </b-row>

        <template #modal-footer>
                <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
                <app-button type="primary" @click="change" :loading="loading">
                    {{trans('save',175)}}
                </app-button>
        </template>
    </b-modal>
</template>
<script>
import axios from "@axios";
import { ValidationObserver } from "vee-validate";

export default {

    components: {
        ValidationObserver,
    },

    props: ["show", "args", "return", "result"],

    computed: {
        computedShow: {
        get() { return this.show },
        set() { this.$emit('update:show', false) }, 
        },

        cArgs: {
            get() { return this.args },
            set() { this.$emit('update:args', false) }
        },

        cReturn: {
            get() { return this.return },
            set() { this.$emit('update:return', false) }
        },
    },

    data(){
        return{
            loading: false,
            pension_types: [
                { value: 0, text: this.trans('fp-no-retirement',172) },
                { value: 1, text: this.trans('pw-basic-scheme',196) },
                { value: 2, text: this.trans('pw-plus-scheme',196)  },
            ],
        }
    },

    methods: {

        change(){
            this.$refs.form.validate().then((result) => {
                if (!result) {
                    return false;
                } else {

                this.loading = true;
                
                axios
                    .post('people/profile/pension/change', {
                        id_person_data_main: this.cArgs.id_person_data_main,
                        pension_type: this.cArgs.pension_type,
                        previous_worked_weeks: this.cArgs.previous_worked_weeks,
                    })
                    .then((res) => {
                        this.cReturn.person_data = res.data;
                        this.$emit('update:result', 1);
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    })
                    .finally(() => {
                        this.loading = false;
                        this.computedShow = false;
                    });
                }
            });
        },

        closeModal() {
            this.$emit("update:show", false);
        },

    }
    
}
</script>
<style scoped>

</style>