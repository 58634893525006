<template>
        <ValidationObserver ref="observer" >
            <b-form @submit.prevent="onSubmit">

                <b-row>
                    <b-col cols="12">
                        <b-form-group :label="trans('number',182)" label-for="vi-mobile" label-cols-md="4" class="app-input-label">
                            <validation-provider v-slot="{ errors }" rules="required" name="Number">
                                <b-input-group  class=" input-group-mergeapp-input-group app-local-pointer" 
                                                :class="{'app-input-group-big': true, 
                                                'app-input-group-error': (errors.length > 0 && errors[0] != '') || !checked_big_key }">                            
                                    <input type="integer" id="vi-big-number" class="form-control form-control-sm app-local-big"
                                        :class="{
                                        'app-big-filled': cBigNumber != null && cBigNumber.length > 0, 
                                        'app-big-error': (errors.length > 0 && errors[0] != '') || !checked_big_key, 
                                        'app-input-big': true,
                                        }"
                                        v-mask="'###########'" v-model="cBigNumber" v-on:blur="checkBig" @change="changingBig" @keypress="preventLetterInput">
                                    <b-input-group-append class="app-big-append" :class="{'app-big-icon-filled': cBigNumber != null && cBigNumber.length > 0, 'app-big-icon-error': (errors.length > 0 && errors[0] != '') || !checked_big_key }">
                                        <b-button size="sm" text="Button" variant="transparent" @click="checkBig" class="app-input-button-group app-big-button">
                                            <b-icon class="app-big-icon" icon="arrow-clockwise" v-if="!loading_check" :class="{'app-big-button-error': (errors.length > 0 && errors[0] != '') || !checked_big_key }"/>
                                            <b-spinner small class="align-middle" v-if="loading_check"></b-spinner>
                                        </b-button>
                                    </b-input-group-append>                                
                                </b-input-group>
                                <small class="text-error">{{ errors[0] }}</small>
                            </validation-provider>
                             <app-input class='app-big-error-info' type="hidden"  v-model="checked_big_key" name="email" validatorRules="required" :validatorCustomMessage="{ required: trans('invalid-number',199) }"/>
                        </b-form-group>
                    </b-col>
                 </b-row>
                 <b-row>
                    <b-col cols="12" class="min-height-form" v-if="fInformation">
                        <b-form-group label="Informatie"  label-for="vi-mobile" label-cols-md="4" class="app-input-label align-items-center">

                            
                            <b-col class="p-0">
                                <b-card class="app-card-info">
                                    <div class="font-weight-bolder big-data-inf-large">{{JSON.parse(cBigData.big_data_main).gender + " " + JSON.parse(cBigData.big_data_main).mailing_name }}</div>
                                    <div class="big-data-inf-large">{{JSON.parse(cBigData.big_data_main).professional_group_description}}</div>
                                    <div class="big-data-inf-small">{{cBigData.big_check_date}}</div>
                                </b-card>
                            </b-col>
                        </b-form-group>
                    </b-col>
                 </b-row>

               
            </b-form>
        </ValidationObserver>
</template>

<script>
import axios from "@axios";
import moment from "moment";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
    },

    props: {
        bigData: {type: Object},
        name: { type: String },
        placeholder: { type: String },
        disabled: { type: Boolean, default: false },
        validatorName: { type: String },
        validatorRules: { type: String },
        validatorCustomMessage: { type: Object },
    },

    created(){
       if (this.cBigData.big_number !== undefined && this.cBigData.big_number !== null && this.cBigData.big_data_main !== null) {
           this.fInformation = true;
           this.checked_big_number = this.cBigData.big_number;
       } 
    },

    computed: {
        computedShow: {
            get() { return this.show },
            set(value) { this.$emit('update:show', value) }, 
        },

        cBigData: {
            get() { return this.bigData },
            set(value) { this.$emit('update:args', value) }
        },
        
        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        },
        cBigNumber:{
            get() {return this.cBigData.big_number},
            set(value) { this.clearError(value),  this.cBigData.big_number = value}
        }
    },

    data() {
        return {
            loading: false,
            fInformation: false,
            fIncorrectNumber: false,
            checked_big_key: "big",
            checked_big_number: "",
            loading_check: false,
            opt: [ { value: 1, text: this.trans('yes-option',3) }, { value: 0, text: this.trans('no-option',3) }],
        };
    },

    methods: {
   
      changingBig() {
           this.fIncorrectNumber = false;
           this.cBigData.big_data_main = null;
           this.fInformation = false;
           this.checked_big_number = "";
       },


       checkBig() {
            if (this.cBigData.big_number === undefined || this.cBigData.big_number === null || (this.cBigData.big_number !== null && this.cBigData.big_number.toString().length !== 11) || (this.checked_big_number !== null && this.cBigData.big_number !== null && this.checked_big_number.toString() === this.cBigData.big_number.toString())) {
                return;
            }   
                this.loading_check = true;
                this.fInformation = false;
                this.cBigData.big_data_main = null;

                axios
                    .get("core/big-number/check/" + this.cBigData.big_number)
                    .then((res) => {
                        if(res.data !== 0) {
                            this.cBigData.big_data_main = JSON.stringify(res.data);
                            this.cBigData.big_check_date = moment().format('DD-MM-YYYY HH:mm:ss');
                            this.fInformation = true;
                            this.fIncorrectNumber = false; 
                            this.checked_big_key = "big";                           
                            
                        } else {
                            this.fInformation = false;
                            this.checked_big_key = "";
                            this.fIncorrectNumber = true;
                        }

                        this.checked_big_number = this.cBigData.big_number;
                        this.loading_check = false;
                        
                    })
                    .catch((error) => {
                        this.loading_check = false;
                        console.error(`error during request: ${error}`);
                    });  
                    
                this.$refs.observer.validate();
        },

        preventLetterInput($event){
            if ($event.keyCode < 48 || $event.keyCode > 57 ) {
                $event.preventDefault();
            }
         },

         clearError(big){
            this.checked_big_key = 'big';
            if(this.checked_big_number == big && !this.fIncorrectNumber){
                this.fInformation = true;
            }

            if(big.toString().length != 11){
                this.fInformation = false;
            }
            
            if((big !== null 
                    && big.toString().length > 0 
                    && big.toString().length < 11) 
                    || (this.fIncorrectNumber && big !== null 
                        && big.toString().length === 11)){
                             this.fInformation = false;
                             this.checked_big_key = '';
                        }
            if(this.$refs.observer != undefined){
                this.$refs.observer.validate();
            }
        },
    },
};
</script>

<style scoped>
.app-local-pointer{
    pointer-events: auto !important
}

.big-data-inf-large{
    font-size:14px
}

.big-data-inf-small{
    font-size:12px
}
.min-height-form{
    min-height:75px
}
</style>
