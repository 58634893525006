<template>
    <b-modal ref="modal-add" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('change-btw',3)">
        <ValidationObserver ref="form" >
            <b-form @submit.prevent> 

                <app-row-left-label :label="trans('btw-number',182)">
                    <app-input v-model="cArgs.btw_number" id="vi-btw-number" validatorName="BTW nummer" validatorRules="btw-number"  mask="BTW" :uppercase="true"/>
                </app-row-left-label>                

            </b-form>
        </ValidationObserver>
        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="change" :loading="loading">
                {{trans('save',175)}}
            </app-button>
        </template>
    </b-modal>
</template>

<script>
import axios from "@axios";
import {  ValidationObserver } from "vee-validate";

export default {
    components: {
        ValidationObserver,
    },

    props: ["show", "args", "return", "result"],

     computed: {
        computedShow: {
            get() { return this.show },
            set() { this.$emit('update:show', false) }, 
        },

        cArgs: {
            get() { return this.args },
            set() { this.$emit('update:args', false) }
        },
        
        cReturn: {
            get() { return this.return },
            set() { this.$emit('update:return', false) }
        }
    },
    
    data() {
        return {

            loading: false,

        };
    },

    methods: {
        change() {
            this.$refs.form.validate().then((result) => {
                if (!result) { 
                    return false;
                }else{
                    this.loading = true;
                    axios
                        .post("people/profile/financial/changeBtw", {
                            id_person_data_main: this.args.id_person_data_main,
                            btw_number: this.cArgs.btw_number,
                            return_type: this.args.return_type
                        })
                        .then((res) => {
                            this.$emit("update:show", false);
                            this.$emit("update:result", 1);

                            if(this.args.return_type == "compliance_check"){
                                this.cReturn.compliance_check.items = res.data.items;
                                this.cReturn.compliance_check.correct = res.data.correct;
                                this.cReturn.compliance_check.show = res.data.show;
                                this.cReturn.refresh ++;
                            }

                            this.loading = false;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        });
                }
            })                
        },

        closeModal() {
            this.$emit("update:show", false);
        },

    },
};
</script>

<style scoped>

</style>
